import AsyncStorage from "@react-native-async-storage/async-storage";

import moment from "moment";
import axios from "axios";
import * as Crypto from "expo-crypto";
import * as FileSystem from "expo-file-system";

import { Platform } from "react-native";

const useAuth = () => {
	const forceQuery = async (uri: string, js: any) => {
		return axios
			.get(uri, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then(async (response) => {
				if (response?.data?.status === "0") {
					const cacheKey = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, uri);
					// Todo OK, guardamos el json en cache y devolvemos la respuesta de la llamada
					Platform.OS !== "web"
						? FileSystem.writeAsStringAsync(FileSystem.documentDirectory + `${cacheKey}.json`, JSON.stringify(response?.data)).catch((e) => console.log(e))
						: AsyncStorage.setItem(`${cacheKey}data`, JSON.stringify(response?.data));
					AsyncStorage.setItem(
						cacheKey,
						moment()
							.add(!response?.data?.ttl ? 6000000 : response?.data?.ttl, "ms")
							.format()
					);
					return response?.data;
				} else if (response?.data?.Resultado) {
					var object = Object.assign({}, ...response?.data.Resultado);
					const cacheKey = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, uri);
					// Todo OK, guardamos el json en cache y devolvemos la respuesta de la llamada
					Platform.OS !== "web"
						? FileSystem.writeAsStringAsync(FileSystem.documentDirectory + `${cacheKey}.json`, JSON.stringify(object)).catch((e) => console.log(e))
						: AsyncStorage.setItem(`${cacheKey}data`, JSON.stringify(object));
					AsyncStorage.setItem(
						cacheKey,
						moment()
							.add(!response?.data?.ttl ? 6000000 : response?.data?.ttl, "ms")
							.format()
					);
					return object;
				} else if (response?.data?.sesion_ok === "0") {
					return;
				} else {
					return null;
				}
			});
	};

	const query = async (uri: string, js = null) => {
		const cacheKey = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, uri);
		const jsessionid = js;
		if (cacheKey && jsessionid) {
			// Miramos si existe el JSON con el nombre de la uri codificado
			const cache =
				Platform.OS === "web"
					? await AsyncStorage.getItem(`${cacheKey}data`)
					: await FileSystem.readAsStringAsync(FileSystem.documentDirectory + `${cacheKey}.json`).catch((e) => null);
			// Miramos el tiempo de caducidad de esta llamada y así sabremos si hay que volver a hacer la llamada para actualizar la cache
			const ttl = await AsyncStorage.getItem(cacheKey);

			const refreshCache = ttl ? moment().diff(moment(ttl)) : -1;

			if (cache && refreshCache <= 0) {
				// Hemos encontrado el archivo en cache, y lo devolvemos como JSON
				return JSON.parse(cache);
			} else {
				return await forceQuery(uri, js);
			}
		}
	};

	return {
		query,
		forceQuery,
	};
};

export default useAuth;
