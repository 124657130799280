import * as Network from "expo-network";
import { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Image, Pressable, ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native";
import Alert from "../components/Alert";
import { BoldText, DefaultText, SemiBoldText } from "../components/StyledText";

import { View } from "../components/Themed";

import { windowHeight, windowWidth } from "../config/config";
import useAuth from "../hooks/useAuth";
import { RootStackScreenProps } from "../types";

import { FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import axios from "axios";
import * as Linking from "expo-linking";
import BookReaderTools from "../components/BookReaderTools";
import BookRecommendationModal from "../components/BookRecommendationModal";
import CitaModal from "../components/CitaModal";
import NoteModal from "../components/NoteModal";
import PageRenderer from "../components/PageRenderer";
import Painter from "../components/Painter";
import VideoModal from "../components/VideoModal";
import WelcomeModal from "../components/WelcomeModal";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Toaster, toast } from "react-hot-toast";

const isIOS = function () {
	var iosQuirkPresent = function () {
		var audio = new Audio();

		audio.volume = 0.5;
		return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
	};

	var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
	var isAppleDevice = navigator.userAgent.includes("Macintosh");
	var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

	return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
};

export default function BookReaderScreen({ navigation }: RootStackScreenProps<"NotFound">) {
	// usePreventScreenCapture();

	var ios = isIOS();
	var ua = navigator.userAgent.toLowerCase();
	var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");

	const insets = useSafeAreaInsets();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const handle = useFullScreenHandle();

	const id = params.get("id");
	const jsessionid = params.get("jsessionid");
	const initialPage = params.get("ultpag");
	const invitado = params.get("invitado");
	const viewerRef = useRef();
	const pageSelectedRef = useRef();
	const headerRef = useRef();
	const pagesRef = useRef(1);
	const w = useWindowDimensions();
	const { forceQuery } = useAuth();

	const [data, setData] = useState<any>({});
	const [networkState, setNetworkState] = useState<boolean>(false);
	const [tab, setTab] = useState<number>(0);
	const [mainMenu, setMainMenu] = useState(false);
	const [paintOpen, setPaintOpen] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);

	const [page, setPage] = useState(parseInt(initialPage || "1"));
	const [zoomScale, setZoomScale] = useState(1);
	const [zoom, setZoom] = useState(1);
	const [jsession, setJsession] = useState<string>("");
	const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
	const [pageRatio, setPageRatio] = useState(null);
	const [pageSelected, setPageSelected] = useState(null);
	const [bookmarks, setBookmarks] = useState<any[]>([]);
	const [viewNotes, setViewNotes] = useState(true);
	const [ready, setReady] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [denuncias, setDenuncias] = useState([]);
	const [notas, setNotas] = useState([]);
	const [notasSort, setNotasSort] = useState("0");
	const [noteModal, setNoteModal] = useState(false);
	const [citaModal, setCitaModal] = useState(false);

	const [topLeftMenu, setTopLeftMenu] = useState(false);
	const [sideMenu, setSideMenu] = useState(false);
	const [horizontal, setHorizontal] = useState(true);
	const [viewType, setViewType] = useState("fluid"); // fluid | singlePage | dualPage

	const [videoModal, setVideoModal] = useState(false);
	const [standModal, setStandModal] = useState(false);
	const [adding, setAdding] = useState(false);
	const [stands, setStands] = useState({});
	const [noteDetails, setNoteDetails] = useState(false);

	const [orders, setOrders] = useState([]);
	const [viewTypeModal, setViewTypeModal] = useState(false);

	const [itemLayout, setItemLayout] = useState(w.width / 3);
	const [bookRecommendationModal, setBookRecommendationModal] = useState(false);

	useEffect(() => {
		id && fetchBook(id);
		fetchNotes();
		getNetWork();
		// getBookself();
	}, []);

	function clamp(value, min, max) {
		return Math.min(Math.max(value, min), max);
	}

	function checkKey(e) {
		e = e || window.event;
		if (e.keyCode == "37" || e.keyCode == "38") {
			e.preventDefault();
			if (page > 1) {
				goToPage(clamp(page - (viewType === "dualPage" ? 2 : 1), 1, pagesRef.current), itemLayout, true);
			}
		} else if (e.keyCode == "39" || e.keyCode == "40") {
			e.preventDefault();
			if (page < pagesRef.current) {
				goToPage(clamp(page + (viewType === "dualPage" ? 2 : 1), 1, pagesRef.current), itemLayout, true);
			}
		}
	}

	const getNetWork = async () => {
		const p = await Network.getNetworkStateAsync();
		p.isConnected ? setNetworkState(true) : setNetworkState(false);
	};

	const toogleFavorites = async () => {
		const fav = data.fav;

		const o = await axios
			.get(`https://api.ingebook.com/ib/NPcd/IB_APP_Favorito;jsessionid=${jsessionid}?cod_primaria=1000187&codigo_libro=${data.id}&favorito=${fav === "0" ? 1 : 0}`, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then((res) => {
				return res.data.status;
			});

		setData({ ...data, fav: fav === "0" ? "1" : "0" });
		fav === "0" ? toast.success(`Libro añadido a favoritos correctamente`) : toast.success(`Libro eliminado de favoritos correctamente`);
	};

	const addToShelf = async (ids) => {
		setAdding(true);

		const url =
			ids === data.ids
				? `https://api.ingebook.com/ib/NPcd/IB_EstantesLibros;jsessionid=${jsessionid}?grabar=1&codigo_libro=${data.id}&cod_estante=0&app=1&cod_primaria=1000193`
				: `https://api.ingebook.com/ib/NPcd/IB_EstantesLibros;jsessionid=${jsessionid}?grabar=1&codigo_libro=${data.id}&cod_estante=${ids}&app=1&cod_primaria=1000193`;

		const o = await axios
			.get(url, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then((res) => res.data);
		ids === data.ids ? toast.success(`Libro eliminado del estante con éxito`) : toast.success(`Libro añadido el libro al estante con éxito`);
		setData({ ...data, ids: ids === data.ids ? "" : ids });
		setStandModal(false);
		setAdding(false);
	};

	const fetchBook = async (id: number) => {
		const uri2 = `https://api.ingebook.com/ib/NPcd/IB_APP_BooksVis;jsessionid=${jsessionid}?codigo_libro=${id}&cod_primaria=1000187`;
		const edata = await forceQuery(uri2, jsessionid);
		const d = edata?.fbook || {};

		setData(d);

		pagesRef.current = parseInt(d.pag || 1);
		fetchBookMarks(jsessionid);
		fetchStands(jsessionid);
		setJsession(jsessionid);
	};

	const fetchBookMarks = async (j) => {
		const o = await axios
			.get(`https://api.ingebook.com/ib/NPcd/IB_APP_Marcadores;jsessionid=${jsessionid}?cod_primaria=1000193&accion=1&codigo_libro=${id}&app=1`, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then((res) => (res?.data?.Resultado ? res?.data?.Resultado[1] : []));

		setBookmarks(o?.schmarcador?.res ? o?.schmarcador?.res : []);
	};

	const fetchStands = async (j) => {
		const o = await axios
			.get(`https://api.ingebook.com/ib/NPcd/IB_EstantesLibros;jsessionid=${jsessionid}?app=1&cod_primaria=1000193`, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then((res) => (res?.data?.Resultado ? res?.data?.Resultado[1] : []));
		setStands(o?.stands ? o?.stands : []);
	};

	const fetchNotes = async (order = "1", fav = "0", publicas = "0", text = "") => {
		const params = new URLSearchParams<any>({
			cod_primaria: "1000193",
			accion: "1",
			codigo_libro: id,
			app: "1",
			ordenar: order,
			favoritos: fav,
			publicas: publicas,
			texto_nota: text,
		}).toString();

		// cod_primaria=#1000193&accion=1&codigo_libro=${id}&app=1&ordenar=1&favoritos=0&publicas=1&texto_nota=123
		const uri = `https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}`;
		const url = new URL(uri);
		url.search = params;
		// console.log(url.toString());

		const o = await axios
			.get(
				// `https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=1&codigo_libro=${id}&app=1`,
				url.toString(),
				{
					headers: {
						pragma: "no-cache",
						"cache-control": "no-cache",
					},
				}
			)
			.then((res) => {
				return res?.data?.Resultado;
			});
		const p = o[1]?.schanotacion;
		setOrders(p?.ordenaciones || []);
		setNotas(p?.res || []);
		setDenuncias(p?.denuncias || []);
	};

	const addBookmark = async (page: string) => {
		const t = JSON.stringify(page);
		const isFav = bookmarks.map((el) => el.pag).includes(t);

		if (isFav) {
			setBookmarks([...bookmarks.filter((el) => el.pag !== t)]);
		} else {
			setBookmarks([...bookmarks, { pag: t }]);
		}

		const o = await axios
			.get(`https://api.ingebook.com/ib/NPcd/IB_APP_Marcadores;jsessionid=${jsessionid}?cod_primaria=1000193&accion=${isFav ? "3" : "2"}&codigo_libro=${id}&app=1&pagina=${page}`, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then((res) => res.data);
	};

	useEffect(() => {
		if (pageRatio) {
			const p =
				viewType === "fluid" ? (horizontal ? containerDimensions.height / pageRatio : containerDimensions.width * pageRatio * zoomScale) : containerDimensions.height / pageRatio;
			setItemLayout(p);
			goToPage(page, p);
		}
	}, [w.width, pageRatio, horizontal, viewType, containerDimensions, zoomScale, zoom]);

	useEffect(() => {
		if (itemLayout !== 0 && viewerRef.current && !ready && pageRatio) {
			// WHEN PAGE LAYOUT AND PAGE RATIO IS SET ALSO SCROLLVIEW REF IS READY THEN SET READY TO TRUE
			setReady(true);
		}
	}, [itemLayout, viewerRef.current]);

	const goToPage = (p, itemLayout, animated = false) => {
		if (viewType !== "fluid") {
			setPage(p);
			setPageSelected(null);
		} else {
			const to = (p - 1) * itemLayout * zoomScale * zoom;
			viewType === "fluid" && viewerRef.current.scrollTo({ [horizontal ? "x" : "y"]: to, animated });
		}
	};

	useEffect(() => {
		changePage();
		document.addEventListener("keydown", checkKey);
		return () => document.removeEventListener("keydown", checkKey);
	}, [page, viewType]);

	const changePage = async () => {
		if (page > 0) {
			const uri = `https://api.ingebook.com/ib/NPcd/IB_APP_UltPaginaLeida;jsessionid=${jsessionid}?ultimapagina=${page}&codigo_libro=${id}&key=${jsession}`;
			axios
				.post(uri, {
					headers: {
						pragma: "no-cache",
						"cache-control": "no-cache",
					},
				})
				.then((res) => res)
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const switchPageToPaint = (ev) => {
		// setPage(ev);
		setPageSelected(ev);
		goToPage(ev, itemLayout);
		setTimeout(() => {
			// const p = pageSelectedRef?.current?.getUri();
			setPaintOpen(true);
		}, 300);
	};

	if (isAndroid) {
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center", paddingHorizontal: 20 }}>
				<DefaultText style={{ fontSize: 20, textAlign: "center", maxWidth: 500 }}>
					Para acceder al visor de Ingebook desde tu Android descarga la aplicación para tener la mejor experiencia posible.
				</DefaultText>
				<TouchableOpacity
					onPressIn={() => window.parent.postMessage("https://play.google.com/store/apps/details?id=com.ingebook.black", "*")}
					style={{ backgroundColor: "red", padding: 10, borderRadius: 5, marginTop: 20, alignSelf: "center" }}
				>
					<DefaultText style={{ color: "white" }}>Ver en Google Play</DefaultText>
				</TouchableOpacity>
			</View>
		);
	}
	if (ios) {
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center", paddingHorizontal: 20 }}>
				<DefaultText style={{ fontSize: 20, textAlign: "center", maxWidth: 500 }}>
					Para acceder al visor de Ingebook desde tu iPhone o iPad descarga la aplicación para tener la mejor experiencia posible.
				</DefaultText>
				<TouchableOpacity
					onPressIn={() => window.parent.postMessage("https://apps.apple.com/es/app/ingebook-libros-en-streaming/id1636904099", "*")}
					style={{ backgroundColor: "red", padding: 10, borderRadius: 5, marginTop: 20, alignSelf: "center" }}
				>
					<DefaultText style={{ color: "white" }}>Ver en el App Store</DefaultText>
				</TouchableOpacity>
			</View>
		);
	}
	return (
		<>
			<WelcomeModal />
			<View style={{ flex: 1 }}>
				<FullScreen handle={handle}>
					<Toaster />
					<View style={{ flex: 1, backgroundColor: "white", height: "100%", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
						<View style={styles.container}>
							{/* MENU SUPERIOR */}
							<View style={{ flexDirection: "row", height: 40, alignItems: "center", width: "100%" }}>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<TouchableOpacity
										style={{ width: 40, height: "100%", alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											let goBack = confirm("¿Estas seguro que deseas volver?");
											if (goBack) {
												// window.location.replace("https://www.ingebook.com/ib/NPcd/IB_Escritorio?cod_primaria=1000193");
												history.back();
											}
										}}
									>
										<Ionicons name={"chevron-back"} size={20} color="black" />
									</TouchableOpacity>
									<TouchableOpacity
										style={{ flexDirection: "row", height: 40, alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											let goBack = confirm("¿Estas seguro que deaseas volver a la biblioteca?");
											if (goBack) {
												window.parent.postMessage("https://www.ingebook.com/ib/NPcd/IB_Escritorio?cod_primaria=1000193", "*");
												// window.location.replace("https://www.ingebook.com/ib/NPcd/IB_Escritorio?cod_primaria=1000193");
												// history.back()
											}
										}}
									>
										<Image source={require("../assets/images/icon.png")} style={{ marginRight: 15, width: 25, height: 25, resizeMode: "contain" }} />
										<DefaultText>Ir a mi Biblioteca</DefaultText>
									</TouchableOpacity>
								</View>

								<View style={{ paddingHorizontal: 10, flex: 1 }}>
									<DefaultText numberOfLines={1} style={{ textAlign: "center" }}>
										{data.tit}
									</DefaultText>
								</View>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<div title="Pantalla Completa">
										<TouchableOpacity
											style={{ width: 40, height: "100%", alignItems: "center", justifyContent: "center" }}
											onPress={() => {
												!fullScreen
													? window.parent.postMessage("enter", "*")
													: // handle.enter
													window.parent.postMessage("exit", "*");
												// handle.exit
												setFullScreen(!fullScreen);
											}}
										>
											<FontAwesome name={!fullScreen ? "expand" : "compress"} size={20} color="black" />
										</TouchableOpacity>
									</div>
									<View style={{ height: 30, width: 3, borderRadius: 3, backgroundColor: "gainsboro", marginHorizontal: 15 }} />
									<div title="Zoom">
										<select
											// disabled={viewType !== "fluid"}
											// style={{ opacity: viewType !== "fluid" ? 0.5 : 1 }}
											placeholder="Zoom"
											value={zoom}
											onChange={(ev) => setZoom(ev.target.value)}
										>
											<option value={2}>200%</option>
											<option value={1.5}>150%</option>
											<option value={1}>100%</option>
											<option value={0.75}>75%</option>
											{viewType === "fluid" && !horizontal && <option value={0.5}>50%</option>}
											{viewType === "fluid" && !horizontal && <option value={0.25}>25%</option>}
										</select>
									</div>
									<TouchableOpacity
										style={{ flexDirection: "row", paddingHorizontal: 10, height: "100%", alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											setViewTypeModal(!viewTypeModal);
											setTopLeftMenu(false);
										}}
									>
										<Image
											style={{ width: 35, height: 35, resizeMode: "contain" }}
											source={require(`../assets/icons/${viewType === "singlePage" ? "single.png" : viewType === "dualPage" ? "dual.png" : "fluid.png"}`)}
										/>

										<DefaultText>{viewType === "fluid" ? `Vista fluida` : viewType === "singlePage" ? "Vista una página" : "Vista dos páginas"}</DefaultText>
									</TouchableOpacity>
									<View style={{ height: 30, width: 3, borderRadius: 3, backgroundColor: "gainsboro", marginHorizontal: 15 }} />
									<div title={horizontal ? "Lectura Vertical" : "Lectura Horizontal"}>
										<TouchableOpacity
											disabled={viewType !== "fluid"}
											onPress={() => {
												setZoom(horizontal ? 0.5 : 1);
												setHorizontal(!horizontal);
											}}
											style={{ width: 50, alignItems: "center", opacity: viewType !== "fluid" ? 0.4 : 1 }}
										>
											<View style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 25 }}>
												<Ionicons name={horizontal ? "swap-horizontal" : "swap-vertical"} size={20} color={"black"} />
											</View>
										</TouchableOpacity>
									</div>
									<div title={viewNotes ? "Ocultar las anotaciones" : "Mostrar las anotaciones"}>
										<TouchableOpacity
											style={{ width: 40, height: "100%", alignItems: "center", justifyContent: "center" }}
											onPress={() => {
												setViewNotes(!viewNotes);
												toast(
													viewNotes
														? "Has deshabilitado la visualización de tus anotaciones: subrayado, dibujo, imágenes, texto..."
														: "Has habilitado la visualización de anotaciones: subrayado, dibujo, imágenes, texto..."
												);
											}}
										>
											<Ionicons name={viewNotes ? "eye-off-outline" : "eye-outline"} size={20} color="black" />
										</TouchableOpacity>
									</div>
									<div title="Menu">
										<TouchableOpacity
											style={{ width: 40, height: "100%", alignItems: "center", justifyContent: "center" }}
											onPress={() => {
												setTopLeftMenu(!topLeftMenu);
												setViewTypeModal(false);
											}}
										>
											<Ionicons name={topLeftMenu ? "close" : "ellipsis-vertical"} size={20} color="black" />
										</TouchableOpacity>
									</div>
								</View>
							</View>
							{/* BARRA DE PROGRESO */}
							<TouchableOpacity
								onPress={() =>
									Alert.prompt(
										"Numero de Página",
										"Escribe el número de la página a la que quieres navegar",
										(value) => {
											if (value) {
												if (viewType === "fluid") {
													viewerRef.current.scrollTo({ [horizontal ? "x" : "y"]: (parseInt(value || "0") - 1) * itemLayout * zoomScale, animated: false });
												} else if (viewType === "singlePage") {
													const t = parseInt(value || "1");
													setPage(t);
												} else {
													const val = parseInt(value || "1");
													const t = val % 2 !== 0 ? val : val - 1;
													setPage(t);
												}
											}
										},
										"plain-text",
										"",
										"number-pad"
									)
								}
								style={{ flexDirection: "row", width: "100%", position: "absolute", top: 40, zIndex: 10 }}
							>
								<View style={{ backgroundColor: "red", paddingHorizontal: 5, paddingVertical: 2 }}>
									<BoldText style={{ fontSize: 10, color: "white" }}>{page}</BoldText>
								</View>
								<View style={{ backgroundColor: "gainsboro", height: 16, flex: 1 }}>
									<View style={{ width: `${(page / parseInt(data.pag)) * 100}%`, backgroundColor: "red", height: "100%" }} />
								</View>
								<View style={{ backgroundColor: parseInt(page) === parseInt(data.pag) ? "red" : "gainsboro", paddingHorizontal: 5, paddingVertical: 2 }}>
									<DefaultText style={{ color: parseInt(page) === parseInt(data.pag) ? "white" : null, fontSize: 10 }}>{data.pag}</DefaultText>
								</View>
							</TouchableOpacity>

							{viewTypeModal && (
								<View
									style={[
										styles.sideShadow,
										{
											position: "absolute",
											top: 60,
											right: 10,
											zIndex: 10,
											backgroundColor: "white",
											borderRadius: 10,
											padding: 10,
											paddingBottom: 20,
											paddingHorizontal: 10,
										},
									]}
								>
									<View style={{ flexDirection: "row" }}>
										<DefaultText style={{ fontSize: 16 }}>Vista de Página</DefaultText>
										<TouchableOpacity
											style={{ position: "absolute", right: 0 }}
											onPress={() => {
												setViewTypeModal(false);
											}}
										>
											<Ionicons name={"close"} size={20} color="black" />
										</TouchableOpacity>
									</View>
									<View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 10 }}>
										<TouchableOpacity
											style={{
												padding: 10,
												width: 100,
												borderWidth: 2,
												borderColor: viewType === "fluid" ? "#ff0000" : "#f3f3f3",
												marginRight: 5,
												borderRadius: 10,
												justifyContent: "center",
												alignItems: "center",
											}}
											onPress={() => {
												setViewType("fluid");
												setZoom(!horizontal ? 0.5 : 1);
												// setViewTypeModal(false);
											}}
										>
											<Image source={require("../assets/icons/fluid.png")} style={{ marginBottom: 5, width: 40, height: 40 }} />
											<DefaultText style={{ fontSize: 12 }}>Fluida</DefaultText>
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												padding: 10,
												width: 100,
												borderWidth: 2,
												borderColor: viewType === "singlePage" ? "#ff0000" : "#f3f3f3",
												marginRight: 5,
												borderRadius: 10,
												justifyContent: "center",
												alignItems: "center",
											}}
											onPress={() => {
												setViewType("singlePage");
												setZoom(1);
												// setViewTypeModal(false);
											}}
										>
											<Image source={require("../assets/icons/single.png")} style={{ marginBottom: 5, width: 40, height: 40 }} />
											<DefaultText style={{ fontSize: 12 }}>Una Página</DefaultText>
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												padding: 10,
												width: 100,
												borderWidth: 2,
												borderColor: viewType === "dualPage" ? "#ff0000" : "#f3f3f3",
												borderRadius: 10,
												justifyContent: "center",
												alignItems: "center",
											}}
											onPress={() => {
												setViewType("dualPage");
												setZoom(1);
												// setViewTypeModal(false);

												const val = page;
												const t = val % 2 !== 0 ? val : val - 1;
												setPage(t);
											}}
										>
											<Image source={require("../assets/icons/dual.png")} style={{ marginBottom: 5, width: 40, height: 40 }} />
											<DefaultText style={{ fontSize: 12 }}>Dos Páginas</DefaultText>
										</TouchableOpacity>
									</View>
								</View>
							)}

							{/* CONTENEDOR PRINCIPAL */}
							<View style={{ flex: 1, position: "absolute", height: w.height - 55, top: 55, left: 0, bottom: 0, right: 0, flexDirection: "row" }}>
								<View
									style={{
										position: "absolute",
										left: 0,
										top: 0,
										bottom: 0,
										height: "100%",
										width: 70,
										borderRightWidth: 3,
										borderRightColor: "gainsboro",
										backgroundColor: "white",
									}}
								>
									<SideButton value={0} title={"Búsqueda"} icon={"lupa"} setTab={setTab} tab={tab} headerRef={headerRef} setSideMenu={setSideMenu} sideMenu={sideMenu} />
									<SideButton
										value={1}
										title={"Tabla de Contenidos"}
										icon={"cuadricula"}
										setTab={setTab}
										tab={tab}
										headerRef={headerRef}
										setSideMenu={setSideMenu}
										sideMenu={sideMenu}
									/>
									<SideButton value={2} title={"Miniaturas"} icon={"indice"} setTab={setTab} tab={tab} headerRef={headerRef} setSideMenu={setSideMenu} sideMenu={sideMenu} />

									<SideButton
										value={3}
										title={"Notas Privadas"}
										icon={"notaprivada"}
										setTab={setTab}
										tab={tab}
										headerRef={headerRef}
										setSideMenu={setSideMenu}
										sideMenu={sideMenu}
									/>

									<SideButton
										value={4}
										title={"Notas Compartidas"}
										icon={"notacompartida"}
										setTab={setTab}
										tab={tab}
										headerRef={headerRef}
										setSideMenu={setSideMenu}
										sideMenu={sideMenu}
									/>
									{data?.videos?.length > 0 && (
										<SideButton value={5} title={"Videoaulas"} icon={"video"} setTab={setTab} tab={tab} headerRef={headerRef} setSideMenu={setSideMenu} sideMenu={sideMenu} />
									)}
									{data?.contenidodes?.length > 0 && (
										<SideButton
											value={6}
											title={"Contenido Extra"}
											icon={"descarga"}
											setTab={setTab}
											tab={tab}
											headerRef={headerRef}
											setSideMenu={setSideMenu}
											sideMenu={sideMenu}
										/>
									)}
								</View>

								<View
									style={[
										styles.sideShadow,
										{
											position: "absolute",
											height: w.height - 55,
											top: 0,
											left: 70,
											bottom: 0,
											right: 0,
											backgroundColor: "transparent",
											opacity: sideMenu ? 1 : 0,
											zIndex: sideMenu ? 2 : -1,
										},
									]}
								>
									<Pressable
										onPress={() => setSideMenu(false)}
										style={{ position: "absolute", zIndex: 1, left: 0, right: 0, bottom: 0, top: 0, backgroundColor: "rgba(0,0,0,.7)" }}
									/>
									<View style={[styles.sideShadow, { zIndex: 2, position: "absolute", height: "100%", top: 0, left: 0, bottom: 0, width: 400, backgroundColor: "white" }]}>
										<BookReaderTools
											id={id}
											page={page}
											invitado={invitado}
											newNoteCallback={fetchNotes}
											pags={parseInt(data.pag || 0)}
											orders={orders}
											pageRatio={pageRatio}
											setMainMenu={setMainMenu}
											pageCallback={(p) => {
												goToPage(p, itemLayout);
											}}
											jsession={jsession}
											tab={tab}
											data={data}
											bookmarks={bookmarks}
											setBookmarks={setBookmarks}
											removeFavoriteCallback={(ev) => addBookmark(parseInt(ev.pag))}
											openVideoCallback={(item) => setVideoModal(item)}
											notas={notas}
											refetchNotesCallback={fetchNotes}
											goToPage={goToPage}
											itemLayout={itemLayout}
											setNoteDetails={setNoteDetails}
											setNoteModal={setNoteModal}
											zoom={zoom}
										/>
									</View>
								</View>

								{topLeftMenu ? (
									<View style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}>
										<View style={[styles.shadow, { backgroundColor: "#f4f4f4", borderRadius: 7, padding: 20, paddingBottom: insets.bottom + 20 }]}>
											<View style={{ flexDirection: "row", maxWidth: 600, width: "100%", alignSelf: "center" }}>
												<View style={{ flex: 1, opacity: invitado === "1" ? 0.3 : 1 }}>
													<TouchableOpacity
														disabled={invitado === "1"}
														onPress={() => {
															!pageSelected && setPageSelected(page);
															setTimeout(() => {
																setNoteModal(true);
															}, 150);
														}}
														style={[styles.mainButton, { marginRight: 10 }]}
													>
														<Image source={require("../assets/icons/clip.png")} style={{ width: 20, height: 20 }} />
														<DefaultText style={{ marginTop: 5, fontSize: 8, color: "#eac33d" }}>Nueva</DefaultText>
														<BoldText style={{ fontSize: 14, color: "#eac33d" }}>Nota</BoldText>
													</TouchableOpacity>
												</View>
												<View style={{ flex: 1 }}>
													<TouchableOpacity
														onPress={() => {
															setCitaModal(true);
														}}
														style={[styles.mainButton, { marginRight: 10 }]}
													>
														<Image source={require("../assets/icons/citas.png")} style={{ width: 20, height: 20 }} />
														<DefaultText style={{ marginTop: 5, fontSize: 8, color: "#a23aa0" }}>Crear</DefaultText>
														<BoldText style={{ fontSize: 14, color: "#a23aa0" }}>Cita</BoldText>
													</TouchableOpacity>
												</View>
												<View style={{ flex: 1, opacity: invitado === "1" ? 0.3 : 1 }}>
													<TouchableOpacity
														disabled={invitado === "1"}
														onPress={() => {
															// setHorizontal(!horizontal);
															setPageSelected(pageSelected ? pageSelected : page);
															setTimeout(() => {
																pageSelectedRef?.current?.paint();
															}, 150);
														}}
														style={[styles.mainButton, { marginRight: 10 }]}
													>
														<Image source={require("../assets/icons/editar.png")} style={{ width: 20, height: 20 }} />
														<DefaultText style={{ marginTop: 5, fontSize: 8, color: "#3ccfa5" }}>Editar</DefaultText>
														<BoldText style={{ fontSize: 14, color: "#3ccfa5" }}>Página</BoldText>
													</TouchableOpacity>
												</View>
												<View style={{ flex: 1, opacity: invitado === "1" ? 0.3 : 1 }}>
													<TouchableOpacity
														disabled={invitado === "1"}
														onPress={() => {
															setPageSelected(pageSelected ? pageSelected : page);
															setTimeout(() => {
																pageSelectedRef?.current?.bookmark();
															}, 150);
														}}
														style={[styles.mainButton]}
													>
														<Image source={require("../assets/icons/marcador.png")} style={{ width: 20, height: 20 }} />
														<DefaultText style={{ marginTop: 5, fontSize: 8, color: "red" }}>Marcar</DefaultText>
														<BoldText style={{ fontSize: 14, color: "red" }}>Página</BoldText>
													</TouchableOpacity>
												</View>
											</View>

											<View
												style={{ flexDirection: "row", maxWidth: 400, width: "100%", alignSelf: "center", alignItems: "center", justifyContent: "space-between", marginTop: 20 }}
											>
												<View style={{ flex: 1, alignItems: "center" }}>
													<TouchableOpacity onPress={() => setBookRecommendationModal(!bookRecommendationModal)} style={{ flex: 1, maxWidth: 60, alignItems: "center" }}>
														<View style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 25 }}>
															<Ionicons name={"heart-outline"} size={20} color={"gray"} />
														</View>
														<DefaultText style={{ marginTop: 5, fontSize: 9, color: "gray", textAlign: "center" }}>Recomendar Libro</DefaultText>
													</TouchableOpacity>
												</View>
												<View style={{ flex: 1, alignItems: "center", opacity: invitado === "1" ? 0.3 : 1 }}>
													<TouchableOpacity
														disabled={invitado === "1"}
														onPress={() => {
															setStandModal(!standModal);
														}}
														style={{ width: 50, alignItems: "center" }}
													>
														<View style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 25 }}>
															<MaterialCommunityIcons name={"bookshelf"} size={20} color={data?.ids ? "red" : "gray"} />
														</View>
														<DefaultText style={{ marginTop: 5, fontSize: 10, color: "gray", textAlign: "center" }}>Añadir a Estante</DefaultText>
													</TouchableOpacity>
												</View>
												<View style={{ flex: 1, alignItems: "center", opacity: invitado === "1" ? 0.3 : 1 }}>
													<TouchableOpacity
														disabled={invitado === "1"}
														onPress={() => {
															toogleFavorites();
														}}
														style={{ width: 50, alignItems: "center" }}
													>
														<View style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 25 }}>
															<Ionicons name={data.fav === "1" ? "star" : "star-outline"} size={20} color={data.fav === "1" ? "orange" : "gray"} />
														</View>
														<DefaultText style={{ marginTop: 5, fontSize: 9, color: "gray", textAlign: "center" }}>Añadir a Favoritos</DefaultText>
													</TouchableOpacity>
												</View>
												<View style={{ flex: 1, alignItems: "center" }}>
													<TouchableOpacity onPress={() => Linking.openURL(`mailto:info@ingebook.com?subject=Error en el libro ${id}`)} style={{ width: 50, alignItems: "center" }}>
														<View style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: 25 }}>
															<Ionicons name={"warning-outline"} size={20} color={"gray"} />
														</View>
														<DefaultText style={{ marginTop: 5, fontSize: 9, color: "gray", textAlign: "center" }}>Reportar Fallo</DefaultText>
													</TouchableOpacity>
												</View>
											</View>

											{invitado === "1" && (
												<View style={{ maxWidth: 400, borderRadius: 7, alignItems: "center", justifyContent: "center", marginTop: 20 }}>
													<DefaultText style={{ fontSize: 14 }}>
														Estas accediendo como usuario invitado, para crear y visualizar notas y otras acciones es necesario iniciar sesión en Ingebook
													</DefaultText>

													<View style={{ width: "100%", marginTop: 20, alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
														<TouchableOpacity
															onPress={() => {
																window.parent.postMessage(`https://www.ingebook.com/ib/NPcd/IB_Browser_Pre?codigo_libro=${id}`, "*");
															}}
															style={{
																backgroundColor: "gainsboro",
																alignItems: "center",
																justifyContent: "center",
																paddingHorizontal: 20,
																paddingVertical: 10,
																borderRadius: 6,
																flex: 1,
																height: "100%",
																marginRight: 15,
															}}
														>
															<SemiBoldText style={{ fontSize: 14, color: "black" }}>Acceder</SemiBoldText>
														</TouchableOpacity>
														<TouchableOpacity
															onPress={() => {
																window.parent.postMessage("https://www.ingebook.com/ib/NPcd/IB_Registro", "*");
															}}
															style={{
																backgroundColor: "red",
																alignItems: "center",
																justifyContent: "center",
																paddingHorizontal: 20,
																paddingVertical: 10,
																borderRadius: 6,
																flex: 1,
																height: "100%",
															}}
														>
															<SemiBoldText style={{ fontSize: 14, color: "white" }}>Registrarme</SemiBoldText>
														</TouchableOpacity>
													</View>
												</View>
											)}
										</View>
									</View>
								) : null}

								<View style={{ flex: 1, position: "absolute", backgroundColor: "white", height: w.height - 55, top: 0, left: 70, zIndex: 1, bottom: 0, right: 0 }}>
									{/* FLECHA ATRAS */}
									{page > 1 && (horizontal || viewType !== "fluid") && (
										<TouchableOpacity
											onPress={() => goToPage(viewType === "dualPage" ? page - 2 : page - 1, itemLayout, true)}
											style={[
												styles.shadow,
												{
													borderWidth: 2,
													borderColor: "gainsboro",
													position: "absolute",
													alignItems: "center",
													justifyContent: "center",
													zIndex: 20,
													top: "45%",
													left: 10,
													width: 40,
													height: 40,
													borderRadius: 25,
													backgroundColor: "white",
												},
											]}
										>
											<Ionicons name="chevron-back" size={20} />
										</TouchableOpacity>
									)}
									{/* FLECHA ALANTE */}
									{(horizontal || viewType !== "fluid") && (
										<TouchableOpacity
											onPress={() => goToPage(viewType === "dualPage" ? page + 2 : page + 1, itemLayout, true)}
											style={[
												styles.shadow,
												{
													borderWidth: 2,
													borderColor: "gainsboro",
													position: "absolute",
													alignItems: "center",
													justifyContent: "center",
													zIndex: 20,
													top: "45%",
													right: 10,
													width: 40,
													height: 40,
													borderRadius: 25,
													backgroundColor: "white",
												},
											]}
										>
											<Ionicons name="chevron-forward" size={20} />
										</TouchableOpacity>
									)}
									{/* CONTENEDOR PAGINAS FLUIDAS */}
									{viewType === "fluid" && data.pag && (
										<ScrollView
											ref={viewerRef}
											style={{ flex: 1, width: "100%", overflow: "scroll" }}
											key={horizontal ? "horizontal" : "vertical"}
											horizontal={horizontal}
											minimumZoomScale={1}
											maximumZoomScale={2}
											decelerationRate="fast"
											showsVerticalScrollIndicator={true}
											showsHorizontalScrollIndicator={false}
											scrollEventThrottle={500}
											scrollEnabled={ready}
											onLayout={(ev) => setContainerDimensions(ev.nativeEvent.layout)}
											onScroll={(e) => {
												// SET THE ZOOM SCALE AND CALCULATE LAST PAGE BASED ON THE SCROLL POSITION
												const offset = horizontal ? e.nativeEvent.contentOffset.x : e.nativeEvent.contentOffset.y;
												const p = Math.abs(Math.round(offset / (itemLayout * (e.nativeEvent.zoomScale || 1) * zoom)));

												setZoomScale(e.nativeEvent.zoomScale || 1);
												setPage(p + 1);
											}}
											contentContainerStyle={{
												// alignItems: horizontal ? "flex-start" : "center",
												// justifyContent: horizontal ? "center" : "flex-start",
												alignItems: zoom > 1 ? "flex-start" : "center",
												justifyContent: horizontal ? "center" : "flex-start",
											}}
										>
											<View
												onLayout={(e) => {
													// IF IT IS A LAST PAGE FROM NAVIGATION PROPS NAVIGATE TO IT ON THE FIRST LOAD ONLY
													if (ready && firstLoad && initialPage) {
														goToPage(parseInt(page), itemLayout);
														setFirstLoad(false);
													}
												}}
												style={{
													flexDirection: horizontal ? "row" : "column",
													width: horizontal ? parseInt(data.pag || "0") * (itemLayout * zoom) : `${100 * zoom}%`,
													height: horizontal ? `${100 * zoom}%` : parseInt(data.pag || "0") * (itemLayout * pageRatio * zoom),
												}}
											>
												{[...Array(parseInt(data.pag || "0")).keys()].map((p, index) => {
													const hasPrivateNote = notas.filter((el) => parseInt(el.pag) === index + 1 && el.nota_propia === "1").length > 0;
													const hasPublicNote = notas.filter((el) => parseInt(el.pag) === index + 1 && el.publica === "1").length > 0;
													return (
														<View
															key={index + zoom}
															style={{
																flex: 1,
																position: "absolute",
																left: horizontal ? index * itemLayout * zoom : 0,
																top: horizontal ? 0 : index * itemLayout * zoom,
																width: horizontal ? itemLayout * zoom : `${100}%`,
																height: horizontal ? `${100}%` : itemLayout * zoom,
															}}
														>
															{index + 1 > page - 5 && index + 1 < page + 5 && (
																<PageRenderer
																	page={index + 1}
																	invitado={invitado}
																	horizontal={horizontal}
																	jsessionid={jsessionid}
																	viewNotes={viewNotes}
																	id={id}
																	jsession={jsession}
																	selected={pageSelected === index + 1}
																	single={false}
																	ref={pageSelected === index + 1 ? pageSelectedRef : null}
																	showBookmarkButton={true}
																	showPrivateNotesButton={hasPrivateNote}
																	showPublicNotesButton={hasPublicNote}
																	pageSizeCallback={(ev) => {
																		!ready && setPageRatio(ev.ratio);
																	}}
																	dir={data?.dir}
																	bookmarked={bookmarks.map((el) => parseInt(el.pag)).includes(index + 1)}
																	bookmarkCallback={() => addBookmark(index + 1)}
																	hasVideo={data?.videos?.some((el) => parseInt(el.pagina_desde) <= index + 1 && parseInt(el.pagina_hasta) >= index + 1)}
																	videoCallback={() => setVideoModal(data?.videos?.find((el) => parseInt(el.pagina_desde) <= index + 1 && parseInt(el.pagina_hasta) >= index + 1))}
																	noteCallback={() => {
																		setPage(index + 1);
																		setTab(3);
																		setSideMenu(true);
																		setTimeout(() => {
																			headerRef?.current?.scrollToIndex({ index: 3, animated: true });
																		}, 300);
																	}}
																	privateNoteCallback={() => {
																		setPage(index + 1);
																		setTab(4);
																		setSideMenu(true);
																		setTimeout(() => {
																			headerRef?.current?.scrollToIndex({ index: 4, animated: true });
																		}, 300);
																	}}
																	onPress={() => {
																		setPageSelected(index + 1 === pageSelected ? null : index + 1);
																	}}
																	newNoteCallback={() => setNoteModal(true)}
																	paintCallback={() => setPaintOpen(true)}
																/>
															)}
														</View>
													);
												})}
											</View>
										</ScrollView>
									)}
									{/* CONTENEDOR SINGLE PAGE */}
									{viewType === "singlePage" && (
										<ScrollView
											key={page}
											onLayout={(e) => {
												setContainerDimensions(e.nativeEvent.layout);
												// IF IT IS A LAST PAGE FROM NAVIGATION PROPS NAVIGATE TO IT ON THE FIRST LOAD ONLY
												if (ready && firstLoad && initialPage) {
													goToPage(parseInt(page), itemLayout);
													setFirstLoad(false);
												}
											}}
											style={{
												flex: 1,
												width: "100%",
											}}
											contentContainerStyle={{
												alignItems: "center",
												justifyContent: "flex-start",
											}}
										>
											<View
												style={{
													backgroundColor: "orange",
													position: "absolute",
													top: 0,
													height: itemLayout > containerDimensions.width ? containerDimensions.width * pageRatio * zoom : `${100 * zoom}%`,
													width: itemLayout > containerDimensions.width ? containerDimensions.width * zoom : itemLayout * zoom,
												}}
											>
												<PageRenderer
													style={{ backgroundColor: "white", height: "100%" }}
													page={page}
													invitado={invitado}
													horizontal={true}
													jsessionid={jsessionid}
													viewNotes={viewNotes}
													id={id}
													jsession={jsession}
													selected={pageSelected === page}
													single={false}
													dir={data?.dir}
													ref={pageSelected === page ? pageSelectedRef : null}
													showBookmarkButton={true}
													showPrivateNotesButton={notas.filter((el) => parseInt(el.pag) === page && el.nota_propia === "1").length > 0}
													showPublicNotesButton={notas.filter((el) => parseInt(el.pag) === page && el.publica === "1").length > 0}
													pageSizeCallback={(ev) => !ready && setPageRatio(ev.ratio)}
													bookmarked={bookmarks.map((el) => parseInt(el.pag)).includes(page)}
													bookmarkCallback={() => addBookmark(page)}
													hasVideo={data?.videos?.some((el) => parseInt(el.pagina_desde) <= page && parseInt(el.pagina_hasta) >= page)}
													videoCallback={() => setVideoModal(data?.videos?.find((el) => parseInt(el.pagina_desde) <= page && parseInt(el.pagina_hasta) >= page))}
													noteCallback={() => {
														setMainMenu(true);
														setTab(3);
														setTimeout(() => {
															headerRef?.current?.scrollToIndex({ index: 3, animated: true });
														}, 300);
													}}
													privateNoteCallback={() => {
														setMainMenu(true);
														setTab(4);
														setTimeout(() => {
															headerRef?.current?.scrollToIndex({ index: 4, animated: true });
														}, 300);
													}}
													onPress={() => {
														setPageSelected(page === pageSelected ? null : page);
													}}
													newNoteCallback={() => setNoteModal(true)}
													paintCallback={() => setPaintOpen(true)}
												/>
											</View>
										</ScrollView>
									)}
									{/* CONTENEDOR DUAL PAGE */}
									{viewType === "dualPage" && (
										<ScrollView
											onLayout={(e) => {
												setContainerDimensions(e.nativeEvent.layout);
												// IF IT IS A LAST PAGE FROM NAVIGATION PROPS NAVIGATE TO IT ON THE FIRST LOAD ONLY
												if (ready && firstLoad && initialPage) {
													goToPage(parseInt(page), itemLayout);
													setFirstLoad(false);
												}
											}}
											style={{
												flex: 1,
												width: "100%",
												overflow: "scroll"
											}}
											contentContainerStyle={{
												justifyContent: zoom > 1 ? "flex-start" : "center",
												alignItems: zoom > 1 ? "flex-start" : "center",
											}}
										>
											<View style={{
												position: "absolute",
												top: 0,
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "row",
												width: itemLayout * 2 * zoom,
											}}>
												<View
													style={{
														height: itemLayout > containerDimensions.width / 2 ? (containerDimensions.width * pageRatio * zoom) / 2 : `${100 * zoom}%`,
														width: itemLayout > containerDimensions.width / 2 ? (containerDimensions.width * zoom) / 2 : itemLayout * zoom,
													}}
												>
													<PageRenderer
														style={{ backgroundColor: "white" }}
														invitado={invitado}
														page={page}
														key={page}
														horizontal={true}
														jsessionid={jsessionid}
														viewNotes={viewNotes}
														id={id}
														jsession={jsession}
														selected={pageSelected === page}
														single={false}
														dir={data?.dir}
														ref={pageSelected === page ? pageSelectedRef : null}
														showBookmarkButton={true}
														showPrivateNotesButton={notas.filter((el) => parseInt(el.pag) === page && el.nota_propia === "1").length > 0}
														showPublicNotesButton={notas.filter((el) => parseInt(el.pag) === page && el.publica === "1").length > 0}
														pageSizeCallback={(ev) => !ready && setPageRatio(ev.ratio)}
														bookmarked={bookmarks.map((el) => parseInt(el.pag)).includes(page)}
														bookmarkCallback={() => addBookmark(page)}
														hasVideo={data?.videos?.some((el) => parseInt(el.pagina_desde) <= page && parseInt(el.pagina_hasta) >= page)}
														videoCallback={() => setVideoModal(data?.videos?.find((el) => parseInt(el.pagina_desde) <= page && parseInt(el.pagina_hasta) >= page))}
														noteCallback={() => {
															setMainMenu(true);
															setTab(3);
															setTimeout(() => {
																headerRef?.current?.scrollToIndex({ index: 3, animated: true });
															}, 300);
														}}
														privateNoteCallback={() => {
															setMainMenu(true);
															setTab(4);
															setTimeout(() => {
																headerRef?.current?.scrollToIndex({ index: 4, animated: true });
															}, 300);
														}}
														onPress={() => {
															setPageSelected(page === pageSelected ? null : page);
														}}
														newNoteCallback={() => setNoteModal(true)}
														paintCallback={() => setPaintOpen(true)}
													/>
												</View>
												<View
													style={{

														height: itemLayout > containerDimensions.width / 2 ? (containerDimensions.width * pageRatio * zoom) / 2 : `${100 * zoom}%`,
														width: itemLayout > containerDimensions.width / 2 ? (containerDimensions.width * zoom) / 2 : itemLayout * zoom,
													}}
												>
													<PageRenderer
														style={{ backgroundColor: "white" }}
														invitado={invitado}
														key={page + 1}
														page={page + 1}
														horizontal={horizontal}
														jsessionid={jsessionid}
														viewNotes={viewNotes}
														id={id}
														dir={data?.dir}
														jsession={jsession}
														selected={pageSelected === page + 1}
														single={false}
														ref={pageSelected === page + 1 ? pageSelectedRef : null}
														showBookmarkButton={true}
														showPrivateNotesButton={notas.filter((el) => parseInt(el.pag) === page + 1 && el.nota_propia === "1").length > 0}
														showPublicNotesButton={notas.filter((el) => parseInt(el.pag) === page + 1 && el.publica === "1").length > 0}
														pageSizeCallback={(ev) => !ready && setPageRatio(ev.ratio)}
														bookmarked={bookmarks.map((el) => parseInt(el.pag)).includes(page + 1)}
														bookmarkCallback={() => addBookmark(page + 1)}
														hasVideo={data?.videos?.some((el) => parseInt(el.pagina_desde) <= page + 1 && parseInt(el.pagina_hasta) >= page + 1)}
														videoCallback={() => setVideoModal(data?.videos?.find((el) => parseInt(el.pagina_desde) <= page + 1 && parseInt(el.pagina_hasta) >= page + 1))}
														noteCallback={() => {
															setMainMenu(true);
															setTab(3);
															setTimeout(() => {
																headerRef?.current?.scrollToIndex({ index: 3, animated: true });
															}, 300);
														}}
														privateNoteCallback={() => {
															setMainMenu(true);
															setTab(4);
															setTimeout(() => {
																headerRef?.current?.scrollToIndex({ index: 4, animated: true });
															}, 300);
														}}
														onPress={() => {
															setPageSelected(page + 1 === pageSelected ? null : page + 1);
														}}
														newNoteCallback={() => setNoteModal(true)}
														paintCallback={() => setPaintOpen(true)}
													/>
												</View>

											</View>
										</ScrollView>
									)}
								</View>
							</View>
						</View>

						{paintOpen ? (
							<Painter
								uri={paintOpen}
								id={id}
								page={pageSelected}
								jsessionid={jsessionid}
								closeCallback={() => setPaintOpen(false)}
								changePageCallback={(ev) => switchPageToPaint(ev)}
								refetchAnotationsCallback={() => pageSelectedRef.current.downloadAnotations()}
							/>
						) : null}

						{standModal && (
							<View
								style={{
									position: "absolute",
									zIndex: 1000,
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: "rgba(0,0,0,.5)",
								}}
							>
								<View style={{ padding: 10, borderRadius: 5, maxWidth: 350, backgroundColor: "white", width: "90%" }}>
									<View style={{ flexDirection: "row", alignItems: "center" }}>
										<View style={{ flex: 1 }}>
											<SemiBoldText>Añadir a Estante</SemiBoldText>
										</View>
									</View>
									{adding ? (
										<View style={{ height: 200, alignItems: "center", justifyContent: "center" }}>
											<ActivityIndicator size="large" color="red" />
										</View>
									) : (
										<>
											<View style={{ marginVertical: 20 }}>
												{stands?.map((el, i) => {
													return (
														<TouchableOpacity
															onPress={() => addToShelf(el.ids)}
															style={{
																backgroundColor: "#f6f6f6",
																alignItems: "center",
																flexDirection: "row",
																paddingHorizontal: 15,
																paddingVertical: 10,
																borderRadius: 7,
																marginBottom: 5,
															}}
															key={i}
														>
															{el.ids === data?.ids && <Ionicons name="library-outline" size={20} color="black" style={{ marginRight: 10 }} />}
															<DefaultText>{el.nom}</DefaultText>
														</TouchableOpacity>
													);
												})}
											</View>

											<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
												<TouchableOpacity
													onPress={() => {
														setStandModal(false);
													}}
													style={{ backgroundColor: "gainsboro", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 5 }}
												>
													<SemiBoldText>Cancelar</SemiBoldText>
												</TouchableOpacity>
												{data.ids !== "" && (
													<TouchableOpacity onPress={() => addToShelf(data.ids)} style={{ backgroundColor: "red", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 5 }}>
														<SemiBoldText style={{ color: "white" }}>Eliminar de Estante</SemiBoldText>
													</TouchableOpacity>
												)}
											</View>
										</>
									)}
								</View>
							</View>
						)}

						<CitaModal item={data} visible={citaModal} closeCallback={() => setCitaModal(false)} />

						<BookRecommendationModal bookId={id as string} jsessionid={jsession} closeCallback={() => setBookRecommendationModal(false)} visible={bookRecommendationModal} />

						{/* <Portal> */}
						<NoteModal
							visible={noteModal}
							id={id}
							jsessionid={jsessionid}
							page={pageSelected}
							item={noteDetails}
							closeCallback={() => {
								setNoteModal(false);
								setNoteDetails(false);
							}}
							refetchCallback={fetchNotes}
							editable={!noteDetails ? true : noteDetails?.nota_propia === "1"}
						/>

						{/* </Portal> */}

						{videoModal ? (
							<VideoModal
								fullScreenCallback={(ev) => {
									window.parent.postMessage(ev, "*");
								}}
								visible={videoModal !== false}
								closeCallback={() => setVideoModal(false)}
								item={videoModal}
								jsessionid={jsessionid}
							/>
						) : null}
					</View>
				</FullScreen>
			</View>
		</>
	);
}

// const LoginComponent = ({}) => {
// 	const [email, setEmail] = useState("");
// 	const [password, setPassword] = useState("");
// 	const [loading, setLoading] = useState(false);
// 	const [passwordVisible, setPasswordVisible] = useState(false);

// 	const onChangeEmail = (e: string) => {
// 		setEmail(e);
// 	};
// 	const onChangePassword = (e: string) => {
// 		setPassword(e);
// 	};

// 	const onPressLogin = async () => {
// 		setLoading(true);
// 		if (!email) {
// 			Alert.alert("Introduce tu usuario", "", [], {});
// 			setLoading(false);
// 			return;
// 		}
// 		if (!password) {
// 			Alert.alert("Introduce tu contraseña", "", [], {});
// 			setLoading(false);
// 			return;
// 		}

// 		setLoading(false);
// 	};

// 	return (
// 		<View>
// 			<View style={styles.inputContainer}>
// 				<View style={{ width: 40, backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}>
// 					<Ionicons name="ios-mail" size={24} color="gainsboro" />
// 				</View>
// 				<TextInput
// 					style={{ flex: 1, fontSize: 16, backgroundColor: "transparent" }}
// 					keyboardType="email-address"
// 					value={email}
// 					autoCapitalize="none"
// 					onChangeText={onChangeEmail}
// 					placeholder="Email..."
// 					placeholderTextColor="rgba(126, 131, 146, 0.5)"
// 				/>
// 			</View>

// 			<View style={styles.inputContainer}>
// 				<View style={{ width: 40, backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}>
// 					<Ionicons name="ios-lock-closed" size={24} color="gainsboro" />
// 				</View>
// 				<TextInput
// 					style={{ flex: 1, fontSize: 16, backgroundColor: "transparent" }}
// 					keyboardType={passwordVisible ? "visible-password" : "default"}
// 					value={password}
// 					autoCapitalize="none"
// 					onChangeText={onChangePassword}
// 					placeholder="Contraseña..."
// 					placeholderTextColor="rgba(126, 131, 146, 0.5)"
// 					secureTextEntry={passwordVisible ? false : true}
// 				/>
// 				<TouchableOpacity onPress={() => setPasswordVisible(!passwordVisible)} style={styles.revealButton}>
// 					<Ionicons size={24} color={"gray"} name={passwordVisible ? "eye-off-outline" : "eye-outline"} />
// 				</TouchableOpacity>
// 			</View>

// 			<TouchableOpacity disabled={loading} style={styles.button} onPress={ onPressLogin}>
// 				<Text
// 					style={{
// 						fontSize: 16,
// 						color: "#FFF",
// 						textAlign: "center",
// 					}}
// 				>
// 					{loading ? <ActivityIndicator color="white" /> : "Iniciar Sesión"}
// 				</Text>
// 			</TouchableOpacity>
// 		</View>
// 	);
// };

const SideButton = ({ title, icon, sideMenu, tab, setSideMenu, setTab, headerRef, value }) => {
	return (
		<TouchableOpacity
			onPress={() => {
				if (sideMenu && value === tab) {
					setSideMenu(false);
				} else {
					setSideMenu(true);
					setTab(value);
					setTimeout(() => {
						headerRef?.current?.scrollToIndex({ index: 0, animated: true });
					}, 300);
				}
			}}
			style={{ paddingVertical: 15, alignItems: "center", backgroundColor: sideMenu && tab === value ? "gray" : "white" }}
		>
			{/* <Ionicons name={icon} size={30} color={sideMenu && tab === value ? "white" : "black"} /> */}
			<Image source={require(`../assets/icons/${icon}.png`)} style={{ width: 25, height: 25, marginBottom: 5, tintColor: sideMenu && tab === value ? "white" : "black" }} />
			<DefaultText style={{ fontSize: 10, textAlign: "center", color: sideMenu && tab === value ? "white" : "black" }}>{title}</DefaultText>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: "100%",
		flexDirection: "row",
		overflow: "hidden",
	},
	modalView: {
		backgroundColor: "white",
		paddingVertical: 10,
		borderRadius: 4,
		minHeight: 150,
		maxWidth: "90%",
		minWidth: 200,
	},
	contentContainer: {
		paddingHorizontal: 10,
		marginBottom: 20,
	},
	toolButton: {
		height: 45,
		borderBottomWidth: 1,
		borderBottomColor: "gainsboro",
		flexDirection: "row",
		alignItems: "center",
	},
	mainButton: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "white",
		borderRadius: 7,
		paddingVertical: 10,
		paddingHorizontal: 10,
	},
	rightBar: {
		zIndex: 22,
		width: 50,
		padding: 10,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
		height: "100%",
	},
	paintManu: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 200,
		width: 200,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	button: {
		height: 40,
		flexDirection: "row",
		alignItems: "center",
		paddingHorizontal: 10,
		justifyContent: "space-between",
		// justifyContent: "center",
	},
	menu_img: {
		width: 20,
		height: 20,
		marginTop: 35,
		justifyContent: "center",
		alignItems: "center",
	},
	book_img: {
		width: 165,
		height: 225,
		zIndex: 10,
		resizeMode: "cover",
	},
	bookback_img: {
		position: "absolute",
		width: 230,
		top: 40,
		height: 270,
		zIndex: 0,
	},
	bookCard: {
		width: windowWidth / 2.2,
		paddingHorizontal: 10,
	},
	wrapper: {
		height: windowHeight / 3,
		padding: 10,
	},
	slide: {
		height: 300,
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	subtitle: {
		marginVertical: 5,
		fontSize: 16,
		fontWeight: "bold",
		opacity: 0.6,
	},
	description: {
		marginVertical: 5,
		fontSize: 14,
		opacity: 0.6,
		borderBottomWidth: 1,
		borderBottomColor: "black",
	},
	category: {
		marginTop: 10,
		fontSize: 16,
		fontWeight: "bold",
		opacity: 0.4,
	},
	author: {
		fontSize: 16,
		marginTop: 10,
	},
	bookCover: {
		height: windowWidth / 1.8,
		width: "100%",
		marginBottom: 7,
		backgroundColor: "#f2f2f2",
	},
	bookTitle: {
		fontSize: 14,
	},
	separator: {
		marginVertical: 30,
		height: 1,
		width: "80%",
	},
	headerSection: {
		flexDirection: "row",
		paddingHorizontal: 10,
		paddingVertical: 5,
		justifyContent: "space-between",
		alignItems: "center",
	},
	sectionHeaderButton: {
		height: 40,
		paddingHorizontal: 10,
		alignItems: "center",
		justifyContent: "center",
	},
	headerContainer: {
		width: "100%",
		paddingHorizontal: 10,
		borderBottomWidth: 2,
		borderBottomColor: "#e6e6e6",
	},
	buttonsRow: {
		flexDirection: "row",
	},
	buttonsTextContainer: {
		paddingHorizontal: 10,
		paddingVertical: 15,
	},
	buttonsText: {
		fontSize: 16,
	},
	genericBackground: {
		padding: 10,
		backgroundColor: "#fafafa",
		borderRadius: 5,
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
	},
	sideShadow: {
		shadowColor: "rgba(0, 0, 0, 0.5)",
		shadowOffset: { width: 5, height: 0 },
		shadowOpacity: 0.5,
		shadowRadius: 15,
		elevation: 10,
	},
	input: {
		borderWidth: 1,
		fontSize: 16,
		height: 45,
		width: "100%",
		color: "black",
		paddingHorizontal: 10,
		borderColor: "#7E8392",
		borderRadius: 7,
		marginTop: 25,
	},
	inputContainer: {
		borderWidth: 1,
		fontSize: 16,
		height: 45,
		width: "100%",
		color: "black",
		borderColor: "#7E8392",
		borderRadius: 7,
		marginTop: 5,
		flexDirection: "row",
		backgroundColor: "transparent",
	},

	revealButton: {
		width: 50,
		height: 45,
		alignItems: "center",
		justifyContent: "center",
	},

	formContainer: {
		width: "100%",
		paddingHorizontal: 20,
		backgroundColor: "transparent",
	},
});
