import { FontAwesome, Ionicons } from "@expo/vector-icons";
import React, { useRef, useState } from "react";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";

const SearchBar = (props: {
	search: string;
	setSearch: any;
	filterSelected: boolean;
	pressButton: any;
	onFocus: any | null;
	onBlur: any | null;
	placeholder: string;
	sortOptions?: boolean;
	sortBy?: number;
	sortCallback?: any;
	top?: number;
	openCallback?: any;
	data?: any;
	filters?: any;
	setFilters?: any;
	withFilters: boolean | true;
	returnKeyType: string | "done";
	onSubmitEditing?: any;
	disabled?: boolean;
}) => {
	const filterRef = useRef();
	const [open, setOpen] = useState(false);
	return (
		<>
			<View style={styles.container}>
				<View style={styles.inputContainer}>
					<TouchableOpacity onPress={() => (props.search || props.filterSelected ? props.pressButton(false) : props.pressButton(true))} style={styles.button}>
						<FontAwesome name={props.search || props.filterSelected ? "remove" : "search"} color="gray" size={24} />
					</TouchableOpacity>
					<TextInput
						onFocus={props.onFocus}
						onBlur={props.onBlur}
						onChangeText={props.setSearch}
						value={props.search}
						style={styles.input}
						placeholder={props.placeholder || "Buscar..."}
						returnKeyType={props.returnKeyType}
						onSubmitEditing={props.onSubmitEditing}
					/>
				</View>
				{props.sortOptions && (
					<TouchableOpacity
						disabled={props.disabled}
						onPress={() => filterRef.current.open()}
						style={{ opacity: props.disabled ? 0.5 : 1, width: 40, height: 40, alignItems: "center", justifyContent: "center" }}
					>
						<Ionicons name={open ? "close" : "options"} color="gray" size={24} />
						{props?.filters?.length > 0 && <View style={{ position: "absolute", bottom: 5, right: 0, height: 7, width: 7, backgroundColor: "red", borderRadius: 3.5 }} />}
					</TouchableOpacity>
				)}
			</View>
		</>
	);
};

export default SearchBar;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		// margin: 10,
	},
	inputContainer: {
		flex: 1,
		height: 40,
		flexDirection: "row",
		borderRadius: 10,
		backgroundColor: "#eee",
	},
	options: {
		position: "absolute",
		right: 20,
		borderRadius: 5,
		padding: 10,
		paddingHorizontal: 15,
		zIndex: 10,
		backgroundColor: "white",
	},
	option: {
		flexDirection: "row",
		alignItems: "center",
		paddingVertical: 5,
	},
	optionText: {
		marginLeft: 5,
		fontSize: 12,
	},
	input: {
		flex: 1,
		borderColor: "gray",
		paddingRight: 10,
	},
	button: {
		width: 50,
		height: 40,
		alignItems: "center",
		justifyContent: "center",
	},

	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
		borderRadius: 15,
	},
});
