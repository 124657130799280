import { Alert, Platform } from "react-native";

const Alert3 = {
	alert: (title = "", message = "", buttons, options) => {
		if (Platform.OS === "web") {
			if (Array.isArray(buttons) && buttons.length > 1) {
				const fn = buttons.find((btn) => btn.style !== "cancel")?.onPress;
				if (confirm(title + (message && `\n${message}`))) {
					fn();
				} else {
					buttons[1]?.onPress();
				}
			} else if (Array.isArray(buttons) && buttons.length === 1) {
				alert(title + (message && `\n${message}`));
				const fn = buttons[0]?.onPress;
				if (typeof fn === "function") {
					fn();
				}
			}
		} else Alert.alert(title, message, buttons, options);
	},
	prompt: (title = "", message = "", callback, options, type, keyboard) => {
		if (Platform.OS === "web") {
			let res = prompt(title);

			if (res != null) {
				callback(res);
			}
		} else Alert.prompt(title, message, callback, options, type, keyboard);
	},
};

export default Alert3;
