import * as React from "react";
import { ColorSchemeName, View } from "react-native";
import BookReaderScreen from "../screens/BookReaderScreen";
import axios from "axios";
import { Host } from "react-native-portalize";
axios.defaults.withCredentials = true;

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
	return (
		<View style={{ flex: 1 }}>
			<Host>
				<BookReaderScreen />
			</Host>
		</View>
	);
}
