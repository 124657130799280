import { FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { ActivityIndicator, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native";

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, { runOnJS, useAnimatedStyle, useSharedValue } from "react-native-reanimated";
import { DefaultText } from "../components/StyledText";
import { View } from "../components/Themed";

export default function VideoModal({ item, visible, closeCallback, jsessionid, fullScreenCallback }) {
	const w = useWindowDimensions();
	const handle = useFullScreenHandle();
	const playerRef = useRef(null);
	const canvasRef = useRef(null);
	const playingRef = useRef(false);
	const [loading, setLoading] = useState(true);
	const [playing, setPlaying] = useState(false);
	const [time, setTime] = useState(0);
	const ASPECT = 16 / 9;
	const [fullScreen, setFullScreen] = useState(false);

	const den = useRef({
		width: 0,
		height: 0,
	});
	const dimensions = useRef({
		width: 0,
		height: 0,
	});

	const x = useSharedValue(w.width - (290 * ASPECT - 160));
	const y = useSharedValue(w.height - 290);
	const height = useSharedValue(290);
	const width = useSharedValue(290 * ASPECT - 160);
	const resize = useSharedValue(false);
	const lastPoint = useSharedValue({
		x: 0,
		y: 0,
	});

	const animatedStyle = useAnimatedStyle(() => {
		return {
			transform: [{ translateX: x.value }, { translateY: y.value }],
			height: height.value,
			width: width.value,
		};
	});

	function clamp(value, min, max) {
		return Math.min(Math.max(value, min), max);
	}

	const panGesture = Gesture.Pan()
		.onBegin((e) => {
			lastPoint.value = {
				x: e.absoluteX,
				y: e.absoluteY,
			};
		})
		.onUpdate((e) => {
			const distX = e.absoluteX - lastPoint.value.x;
			const distY = e.absoluteY - lastPoint.value.y;

			if (resize.value) {
				const h = clamp(height.value - distY, 290, clamp((w.width + 90) / ASPECT, 290, w.height - 60));
				const ww = clamp(width.value - distX, 290 * ASPECT - 160, clamp(w.width - 70, 290 * ASPECT - 160, w.width - 60));

				height.value = h;
				width.value = h * ASPECT - 160;

				y.value = clamp(y.value + distY, 60, w.height - h);
				x.value = clamp(x.value + distX, 70, w.width - ww);
				runOnJS(onResize)();
			} else {
				const xx = x.value + distX;
				const yy = y.value + distY;
				x.value = clamp(xx, 70, w.width - width.value);
				y.value = clamp(yy, 60, w.height - height.value);
			}

			lastPoint.value = { x: e.absoluteX, y: e.absoluteY };
		})
		.onEnd(() => {
			resize.value = false;
		});

	useEffect(() => {
		document.addEventListener("keydown", function (event) {
			const key = event.key; // const {key} = event; in ES6+
			if (key === "Escape") {
				height.value = 290;
				width.value = 290 * ASPECT - 160;
				x.value = 80;
				y.value = 80;
				setFullScreen(false);
			}
		});
		return () => {
			document.removeEventListener("keydown", () => null);
			x.value = 70;
			y.value = 60;
			height.value = 200;
			width.value = 300;
			setLoading(true);
		};
	}, []);

	useEffect(() => {
		return () => {
			if (!visible) {
				x.value = 70;
				y.value = 60;
				height.value = 200;
				width.value = 300;
			}
		};
	}, [visible]);

	useEffect(() => {
		playerRef.current && (playing ? playerRef.current?.play() : playerRef.current?.pause());
		if (playerRef.current && playing) {
			updater();
		}
	}, [playing, item]);

	const onResize = () => {
		const ASPECT = 16 / 9;
		let HEIGHT = 1080;
		let WIDTH = ASPECT * HEIGHT;

		const video = document.getElementById("video");
		const broadcast = document.getElementById("broadcast");
		broadcast.width = video.width = WIDTH;
		broadcast.height = video.height = HEIGHT;

		const zoom = "zoom: " + 100 * (den.current.width > den.current.height * ASPECT ? (den.current.height * ASPECT) / WIDTH : den.current.width / WIDTH) + "%";

		video.setAttribute("style", zoom);
		video.setAttribute("style", "display: none");
		broadcast.setAttribute("style", zoom);

		dimensions.current = {
			width: WIDTH,
			height: HEIGHT,
		};

		updaterPaused();
	};

	const drawText = (ctx, text, cw, ch) => {
		// ctx.save();

		ctx.font = "55px verdana";
		var textWidth = ctx.measureText(text).width;
		ctx.fillStyle = "rgba(255, 255, 255, 0.15)";
		ctx.textAlign = "center";

		ctx.save();
		ctx.translate(cw / 2, ch / 2);
		ctx.rotate(Math.PI / 4);
		ctx.textAlign = "center";
		// ctx.fillText(text, 0, 0 - ch / 2);
		// ctx.fillText(text, 350 - textWidth / 2, ch * 0.25 - ch / 2);
		// ctx.fillText(text, 350 - textWidth / 2, ch * 0.5 - ch / 2);
		// ctx.fillText(text, 350 - textWidth / 2, ch * 0.75 - ch / 2);
		ctx.fillText(text, 350 - textWidth / 2, ch - ch / 2);
		ctx.restore();
	};

	const updater = () => {
		if (playerRef.current && playingRef.current) {
			const ctx = canvasRef.current.getContext("2d");
			ctx.clearRect(0, 0, dimensions.current.width, dimensions.current.height);
			ctx.drawImage(playerRef.current, 0, 0, dimensions.current.width, dimensions.current.height);
			drawText(ctx, jsessionid, dimensions.current.width, dimensions.current.height);
			requestAnimationFrame(() => updater());
		}
	};

	const updaterPaused = () => {
		if (playerRef.current) {
			const ctx = canvasRef.current.getContext("2d");
			ctx.clearRect(0, 0, dimensions.current.width, dimensions.current.height);
			ctx.drawImage(playerRef.current, 0, 0, dimensions.current.width, dimensions.current.height);
			drawText(ctx, jsessionid, dimensions.current.width, dimensions.current.height);
		}
	};

	const endCallback = () => {
		setPlaying(false);
		playerRef.current.currentTime = 0;
		updaterPaused();
	};

	const loadCallback = (ev) => {
		setPlaying(true);
		playingRef.current = true;
		setLoading(false);
		onResize();
	};

	const moveTime = (value) => {
		if (playerRef.current) {
			playerRef.current.currentTime = playerRef.current.currentTime + value < 0 ? 0 : playerRef.current.currentTime + value;
		}
	};

	const videoFullScreen = () => {
		!fullScreen ? fullScreenCallback("enter") : fullScreenCallback("exit");
		setFullScreen(!fullScreen);
		setTimeout(() => {
			if (!fullScreen) {
				var w = window.innerWidth;
				var h = window.innerHeight;
				height.value = w / ASPECT;
				width.value = w;
				x.value = 0;
				y.value = 0;
			} else {
				var w = window.innerWidth;
				height.value = 290;
				width.value = 290 * ASPECT - 160;
				x.value = 80;
				y.value = 80;
			}
		}, 500);
	};

	const timeUpdateCallback = (ev) => {
		setTime(playerRef.current.currentTime);
	};

	if (!visible) return null;

	return (
		<>
			<FullScreen handle={handle}>
				<GestureDetector gesture={panGesture}>
					<Animated.View
						style={[
							{
								position: "absolute",
								zIndex: 20000,
								top: 0,
								left: 0,
							},
							animatedStyle,
						]}
					>
						<View
							style={{
								borderRadius: 15,
								overflow: "hidden",
								flex: 1,
							}}
						>
							<View style={{ width: "100%", flexDirection: "row", alignItems: "center", backgroundColor: "black", borderTopEndRadius: 15, borderTopStartRadius: 15 }}>
								<View style={{ alignItems: "center", width: "100%", flexDirection: "row" }}>
									{!fullScreen && (
										<TouchableOpacity
											onPressIn={() => {
												resize.value = true;
											}}
											style={{ width: 50, height: 40, alignItems: "center", justifyContent: "center" }}
										>
											<Ionicons
												name="md-resize"
												style={{
													transform: [{ rotate: "90deg" }],
												}}
												size={20}
												color="white"
											/>
										</TouchableOpacity>
									)}
									<View style={{ flex: 1, paddingHorizontal: !fullScreen ? 15 : 0, height: 40, justifyContent: "center" }}>
										<DefaultText numberOfLines={1} style={{ fontSize: 14, color: "white" }}>
											{item?.id ? item.desc.slice(0, item.desc.length - 4) : "Video relacionado"}
										</DefaultText>
									</View>

									{!fullScreen && (
										<TouchableOpacity onPress={fullScreen ? videoFullScreen : closeCallback} style={{ width: 50, height: 40, alignItems: "center", justifyContent: "center" }}>
											<Ionicons name="close" size={30} color="white" />
										</TouchableOpacity>
									)}
								</View>
							</View>
							{item?.id && (
								<View style={{ flex: 1, backgroundColor: "black" }}>
									<View style={{ width: "100%", height: "100%" }}>
										{loading ? (
											<View style={{ flex: 1, alignItems: "center", justifyContent: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2 }}>
												<ActivityIndicator />
											</View>
										) : null}
										<View
											style={{
												backgroundColor: "rgba(0,0,0,.5)",
												zIndex: 100,
												position: "absolute",
												flexDirection: "row",
												width: "100%",
												bottom: 0,
												left: 0,
												right: 0,
												height: 50,
												alignItems: "center",
											}}
										>
											<TouchableOpacity
												onPress={() => {
													setPlaying(!playing);
													playingRef.current = !playingRef.current;
												}}
												style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}
											>
												<Ionicons name={playing ? "pause" : "play"} size={25} color="white" />
											</TouchableOpacity>
											<TouchableOpacity
												onPress={() => {
													moveTime(-10);
												}}
												style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}
											>
												<MaterialCommunityIcons name="rewind-10" size={25} color="white" />
												{/* <FontAwesome name="step-backward" size={20} color="white" /> */}
												{/* <Ionicons name="play-back-circle" size={25} color="white" /> */}
											</TouchableOpacity>
											<TouchableOpacity
												onPress={() => {
													moveTime(10);
												}}
												style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}
											>
												<MaterialCommunityIcons name="fast-forward-10" size={25} color="white" />
												{/* <FontAwesome name="step-forward" size={20} color="white" /> */}
												{/* <Ionicons name="play-forward-circle" size={25} color="white" /> */}
											</TouchableOpacity>
											<View style={{ flex: 1 }} />
											<View style={{ height: "100%", alignItems: "center", justifyContent: "center", paddingHorizontal: 15 }}>
												<DefaultText numberOfLines={1} style={{ fontSize: 14, color: "white" }}>
													{moment.utc(time * 1000).format(time < 3600 ? "mm:ss" : "HH:mm:ss")} {" / "}{" "}
													{moment.utc((playerRef?.current?.duration || 0) * 1000).format((playerRef?.current?.duration || 0) < 3600 ? "mm:ss" : "HH:mm:ss")}
												</DefaultText>
											</View>
											<TouchableOpacity onPress={videoFullScreen} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
												<FontAwesome5 name={fullScreen ? "compress" : "expand"} size={25} color="white" />
											</TouchableOpacity>
										</View>
										<View style={{ flex: 1, zIndex: 1 }}>
											<canvas
												id="broadcast"
												style={{
													zIndex: 30,
													flex: 1,
													height: "100%",
													width: "100%",
												}}
												ref={canvasRef}
											/>

											{/* <video
										style={{ width:"100%", height:"100%", zIndex: 1, objectFit: "contain" }}
										src={item.descarga.split("accvideo")[0] + `accvideo;jsessionid=${jsessionid}` + item.descarga.split("accvideo")[1]}
										controlsList="nodownload"
										controls
										onLoadStart={() => setLoading(false)}
										onLoad={() => setLoading(false)}
									/> */}
										</View>
									</View>
								</View>
							)}
						</View>
					</Animated.View>
				</GestureDetector>
			</FullScreen>
			<video
				id={"video"}
				ref={playerRef}
				playsInline={true}
				// onCanPlay={canPlay}
				// onPlay={updater}
				onLoadStart={loadCallback}
				// onError={errorCallback}
				onTimeUpdate={timeUpdateCallback}
				controls={false}
				autoPlay={false}
				style={{
					display: "none",
				}}
				onEnded={endCallback}
				src={item.descarga.split("accvideo")[0] + `accvideo;jsessionid=${jsessionid}` + item.descarga.split("accvideo")[1]}
			/>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "white",
		flex: 1,
	},
	headerContainer: {
		width: "100%",
		height: 40,
		maxHeight: 40,
		minHeight: 40,
		borderBottomWidth: 2,
		borderBottomColor: "#e6e6e6",
	},
	toolButton: {
		height: 45,
		borderBottomWidth: 1,
		borderBottomColor: "gainsboro",
		flexDirection: "row",
		alignItems: "center",
	},
	optionsMenu: {
		position: "absolute",
		right: 5,
		zIndex: 200,
		width: 250,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	buttonsRow: {
		flexDirection: "row",
	},
	buttonsTextContainer: {
		paddingHorizontal: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonsText: {
		fontSize: 14,
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
	},
	menuButton: {
		width: 45,
		height: 35,
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: 5,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	separator: {
		marginVertical: 30,
		height: 1,
		width: "80%",
	},
	description: {
		marginVertical: 5,
		fontSize: 14,
		opacity: 0.6,
		borderBottomWidth: 1,
		borderBottomColor: "black",
	},
});

// import { Ionicons } from "@expo/vector-icons";
// import { StyleSheet, TouchableOpacity, useWindowDimensions, ActivityIndicator, Modal, Pressable } from "react-native";
// import { useSafeAreaInsets } from "react-native-safe-area-context";

// import { DefaultText } from "../components/StyledText";
// import { View } from "../components/Themed";
// import { useEffect, useState } from "react";
// import { Video } from "expo-av";

// export default function VideoModal({ item, visible, closeCallback }) {
// 	const insets = useSafeAreaInsets();
// 	const w = useWindowDimensions();
// 	const [loading, setLoading] = useState(true);

// 	useEffect(() => {
// 		return () => setLoading(true);
// 	}, []);

// 	return (
// 		<Modal visible={visible} animationType="slide" transparent={true}>
// 			<Pressable onPress={closeCallback} style={{ backgroundColor: "rgba(0,0,0,.5)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />
// 			<View
// 				style={{
// 					flex: 1,
// 					alignItems: "center",
// 					justifyContent: "center",
// 				}}
// 			>
// 				<View
// 					style={{
// 						backgroundColor: "white",
// 						borderRadius: 15,
// 						marginHorizontal: 10,
// 						maxHeight: 500,
// 						minWidth: w.width - 20,
// 						overflow: "hidden",
// 					}}
// 				>
// 					<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "black", borderTopEndRadius: 15, borderTopStartRadius: 15 }}>
// 						<View style={{ alignItems: "center", paddingLeft: 20, flexDirection: "row" }}>
// 							<View style={{ flex: 1 }}>
// 								<DefaultText numberOfLines={1} style={{ fontSize: 16, color: "white" }}>
// 									{item?.id ? item.desc.slice(0, item.desc.length - 4) : "Video relacionado"}
// 								</DefaultText>
// 							</View>
// 							<TouchableOpacity onPress={closeCallback} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
// 								<Ionicons name="close" size={30} color="white" />
// 							</TouchableOpacity>
// 						</View>
// 					</View>
// 					{item?.id && (
// 						<View>
// 							<View style={{ width: "100%", height: 300, backgroundColor: "black" }}>
// 								{loading ? (
// 									<View style={{ alignItems: "center", justifyContent: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2 }}>
// 										<ActivityIndicator />
// 									</View>
// 								) : null}
// 								<Video
// 									style={{ flex: 1, zIndex: 1 }}
// 									source={{
// 										uri: item.descarga,
// 									}}
// 									useNativeControls
// 									resizeMode="contain"
// 									onLoad={() => setLoading(false)}
// 									// isLooping
// 								/>
// 							</View>
// 						</View>
// 					)}
// 				</View>
// 			</View>
// 		</Modal>
// 	);
// }

// const styles = StyleSheet.create({
// 	container: {
// 		backgroundColor: "white",
// 		flex: 1,
// 	},
// 	headerContainer: {
// 		width: "100%",
// 		height: 40,
// 		maxHeight: 40,
// 		minHeight: 40,
// 		borderBottomWidth: 2,
// 		borderBottomColor: "#e6e6e6",
// 	},
// 	toolButton: {
// 		height: 45,
// 		borderBottomWidth: 1,
// 		borderBottomColor: "gainsboro",
// 		flexDirection: "row",
// 		alignItems: "center",
// 	},
// 	optionsMenu: {
// 		position: "absolute",
// 		right: 5,
// 		zIndex: 200,
// 		width: 250,
// 		padding: 10,
// 		backgroundColor: "white",
// 		shadowColor: "#000",
// 		shadowOffset: {
// 			width: 0,
// 			height: 2,
// 		},
// 		shadowOpacity: 0.25,
// 		shadowRadius: 3.84,

// 		elevation: 5,
// 	},
// 	buttonsRow: {
// 		flexDirection: "row",
// 	},
// 	buttonsTextContainer: {
// 		paddingHorizontal: 20,
// 		alignItems: "center",
// 		justifyContent: "center",
// 	},
// 	buttonsText: {
// 		fontSize: 14,
// 	},
// 	shadow: {
// 		shadowColor: "rgba(0, 0, 0, 0.3)",
// 		shadowOffset: { width: 0, height: 2 },
// 		shadowOpacity: 0.4,
// 		shadowRadius: 3,
// 		elevation: 10,
// 		backgroundColor: "white",
// 	},
// 	menuButton: {
// 		width: 45,
// 		height: 35,
// 		alignItems: "center",
// 		justifyContent: "center",
// 		paddingBottom: 5,
// 	},
// 	title: {
// 		fontSize: 20,
// 		fontWeight: "bold",
// 	},
// 	separator: {
// 		marginVertical: 30,
// 		height: 1,
// 		width: "80%",
// 	},
// 	description: {
// 		marginVertical: 5,
// 		fontSize: 14,
// 		opacity: 0.6,
// 		borderBottomWidth: 1,
// 		borderBottomColor: "black",
// 	},
// });

// import { Ionicons } from "@expo/vector-icons";
// import { StyleSheet, TouchableOpacity, useWindowDimensions, ActivityIndicator, Modal, Pressable } from "react-native";
// import { useSafeAreaInsets } from "react-native-safe-area-context";

// import { DefaultText } from "../components/StyledText";
// import { View } from "../components/Themed";
// import { useEffect, useState } from "react";
// import { Video } from "expo-av";

// export default function VideoModal({ item, visible, closeCallback, jsessionid }) {
// 	const insets = useSafeAreaInsets();
// 	const w = useWindowDimensions();
// 	const [loading, setLoading] = useState(true);
// 	const [side, setSide] = useState("bottomRight"); // bottomRight | bottomLeft | topRight | topLeft

// 	useEffect(() => {
// 		return () => setLoading(true);
// 	}, []);

// 	return (
// 		<View
// 			style={{
// 				position: "absolute",
// 				zIndex: 20000,
// 				flex: 1,
// 				alignItems: "center",
// 				justifyContent: "center",
// 				// bottom: side.includes("bottom") ? 10 : null,
// 				top: side === "topRight" || side === "topLeft" ? 10 : null,
// 				bottom: side === "bottomRight" || side === "bottomLeft" ? 10 : null,
// 				right: side === "bottomRight" || side === "topRight" ? 10 : null,
// 				left: side === "bottomLeft" || side === "topLeft" ? 10 : null,
// 			}}
// 		>
// 			<View
// 				style={{
// 					backgroundColor: "white",
// 					borderRadius: 15,
// 					marginHorizontal: 10,
// 					// maxHeight: 500,
// 					maxWidth: w.width / 3.5,
// 					minWidth: "100%",
// 					overflow: "hidden",
// 				}}
// 			>
// 				<View style={{ width: "100%", flexDirection: "row", alignItems: "center", backgroundColor: "black", borderTopEndRadius: 15, borderTopStartRadius: 15 }}>
// 					<View style={{ alignItems: "center", width: "100%", flexDirection: "row" }}>
// 						<TouchableOpacity
// 							onPress={() => {
// 								const t = side === "bottomRight" ? "bottomLeft" : side === "bottomLeft" ? "topLeft" : side === "topLeft" ? "topRight" : "bottomRight";
// 								setSide(t);
// 							}}
// 							style={{ width: 50, height: 40, alignItems: "center", justifyContent: "center" }}
// 						>
// 							<Ionicons name="move" size={20} color="white" />
// 						</TouchableOpacity>
// 						<View style={{ flex: 1 }}>
// 							<DefaultText numberOfLines={1} style={{ fontSize: 14, color: "white" }}>
// 								{item?.id ? item.desc.slice(0, item.desc.length - 4) : "Video relacionado"}
// 							</DefaultText>
// 						</View>
// 						<TouchableOpacity onPress={closeCallback} style={{ width: 50, height: 40, alignItems: "center", justifyContent: "center" }}>
// 							<Ionicons name="close" size={30} color="white" />
// 						</TouchableOpacity>
// 					</View>
// 				</View>
// 				{item?.id && (
// 					<View>
// 						<View style={{ width: "100%",  backgroundColor: "black" }}>
// 							{loading ? (
// 								<View style={{ alignItems: "center", justifyContent: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2 }}>
// 									<ActivityIndicator />
// 								</View>
// 							) : null}
// 							<video
// 								style={{ flex: 1, zIndex: 1 }}
// 								// source={{
// 								// 	uri: ,
// 								// }}
// 								src={item.descarga.split("accvideo")[0] + `accvideo;jsessionid=${jsessionid}` + item.descarga.split("accvideo")[1]}
// 								controlsList="nodownload"
// 								controls
// 								// useNativeControls
// 								// resizeMode="contain"
// 								onLoadStart={() => setLoading(false)}
// 								onLoad={() => setLoading(false)}

// 								// isLooping
// 							/>
// 						</View>
// 					</View>
// 				)}
// 			</View>
// 		</View>
// 	);
// }

// const styles = StyleSheet.create({
// 	container: {
// 		backgroundColor: "white",
// 		flex: 1,
// 	},
// 	headerContainer: {
// 		width: "100%",
// 		height: 40,
// 		maxHeight: 40,
// 		minHeight: 40,
// 		borderBottomWidth: 2,
// 		borderBottomColor: "#e6e6e6",
// 	},
// 	toolButton: {
// 		height: 45,
// 		borderBottomWidth: 1,
// 		borderBottomColor: "gainsboro",
// 		flexDirection: "row",
// 		alignItems: "center",
// 	},
// 	optionsMenu: {
// 		position: "absolute",
// 		right: 5,
// 		zIndex: 200,
// 		width: 250,
// 		padding: 10,
// 		backgroundColor: "white",
// 		shadowColor: "#000",
// 		shadowOffset: {
// 			width: 0,
// 			height: 2,
// 		},
// 		shadowOpacity: 0.25,
// 		shadowRadius: 3.84,

// 		elevation: 5,
// 	},
// 	buttonsRow: {
// 		flexDirection: "row",
// 	},
// 	buttonsTextContainer: {
// 		paddingHorizontal: 20,
// 		alignItems: "center",
// 		justifyContent: "center",
// 	},
// 	buttonsText: {
// 		fontSize: 14,
// 	},
// 	shadow: {
// 		shadowColor: "rgba(0, 0, 0, 0.3)",
// 		shadowOffset: { width: 0, height: 2 },
// 		shadowOpacity: 0.4,
// 		shadowRadius: 3,
// 		elevation: 10,
// 		backgroundColor: "white",
// 	},
// 	menuButton: {
// 		width: 45,
// 		height: 35,
// 		alignItems: "center",
// 		justifyContent: "center",
// 		paddingBottom: 5,
// 	},
// 	title: {
// 		fontSize: 20,
// 		fontWeight: "bold",
// 	},
// 	separator: {
// 		marginVertical: 30,
// 		height: 1,
// 		width: "80%",
// 	},
// 	description: {
// 		marginVertical: 5,
// 		fontSize: 14,
// 		opacity: 0.6,
// 		borderBottomWidth: 1,
// 		borderBottomColor: "black",
// 	},
// });
