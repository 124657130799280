import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ActivityIndicator, Image, Pressable, TouchableOpacity, View } from "react-native";
import { Document, Page, pdfjs } from "react-pdf";
import { SemiBoldText } from "./StyledText";

const options = {
	cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

const PageRenderer = forwardRef(
	(
		{
			id,
			page,
			selected = false,
			viewNotes = false,
			bookmarked,
			showBookmarkButton,
			showPublicNotesButton,
			showPrivateNotesButton,
			bookmarkCallback,
			noteCallback,
			privateNoteCallback,
			pageSizeCallback,
			newNoteCallback,
			hasVideo = false,
			videoCallback = () => null,
			onPress = () => null,
			paintCallback = () => null,
			jsessionid,
			invitado,
			style = {},
			dir = "",
		},
		ref
	) => {
		const webViewRef = useRef(null);
		const [loading, setLoading] = useState(true);
		const [uri, setUri] = useState<any>(null);
		const [overlay, setOverlay] = useState(null);
		const [pageRatio, setPageRatio] = useState(1);
		const [pageLoading, setPageLoading] = useState(true);
		const [error, setError] = useState(false);
		const pageRef = useRef();

		const [dim, setDim] = useState({ width: 0, height: 0 });

		const downloadAnotations = async () => {
			const p = await fetch(`https://api.ingebook.com/ib/IB_Browser;jsessionid=${jsessionid}?libro=${id}&pagina=${page}&extras=2`, {
				method: "POST",
			})
				.then((res) => res.json())
				.catch((err) => {
					console.log(err);
				});
			if (!p?.status && p?.dataUri) {
				setOverlay(p?.dataUri);
			} else {
				// console.log(p)
			}

			setLoading(false);
		};

		useImperativeHandle(ref, () => ({
			getWebView: () => webViewRef.current,
			redraw: (w, h) => {
				// const ref = webViewRef.current;
				// const message = { type: "redraw", data: { width: w, height: h } };
				// ref.postMessage(JSON.stringify(message), "*");
			},
			getUri: () => uri,
			resize: () => null,
			downloadAnotations: downloadAnotations,
			paint: () => paintCallback(uri),
			bookmark: () => bookmarkCallback(),
			isBookmarked: () => bookmarked,
		}));

		useEffect(() => {
			page >= 0 && downloadAnotations();
		}, []);

		const handleLoadSuccess = async (pdfObject) => {
			setError(false);
			pdfObject.getPage(1).then((page) => {
				const { width, height } = page.getViewport({ scale: 1 });
				pageSizeCallback({ width: width, height: height, ratio: height / width });
				setPageRatio(height / width);
			});
			setPageLoading(false);
		};

		const getDataUri = () => {
			const d = document
				.getElementById("page" + page)
				?.querySelectorAll("div")[1]
				.childNodes.item(0);

			if (d && d?.toDataURL) {
				setUri(d?.toDataURL("image/jpg"));
				setLoading(false);
			} else {
				setTimeout(() => {
					getDataUri();
				}, 300);
			}
		};

		return (
			<Pressable
				onLayout={(ev) => {
					(pageLoading || ev.nativeEvent.layout.width !== dim.width) && setDim(ev.nativeEvent.layout);
				}}
				style={{ ...style, flex: 1, borderWidth: selected ? 3 : 0, height: "100%", width: "100%", borderColor: "red" }}
				onPress={onPress}
			>
				<View
					id={"page" + page}
					style={{ flex: 1, zIndex: 2 }}>
					<View pointerEvents="none" style={{ userSelect: !error ? "none" : null, flex: 1 }}>
						<Document
							options={options}
							ref={pageRef}
							file={`https://api.ingebook.com/ib/IB_Browser;jsessionid=${jsessionid}?libro=${id}&pagina=${page}&extras=0`}
							onLoadSuccess={handleLoadSuccess}
							loading={
								<View style={{ flex: 1, height: dim.height - 6, alignItems: "center", justifyContent: "center" }}>
									<ActivityIndicator size="large" color="red" />
								</View>
							}
							error={
								<View style={{ flex: 1, height: dim.height - 6, alignItems: "center", justifyContent: "center" }}>
									<SemiBoldText>Opsss no se ha podido cargar la página</SemiBoldText>
									<TouchableOpacity>
										<SemiBoldText style={{ color: "red" }}>Reintentar</SemiBoldText>
									</TouchableOpacity>
								</View>
							}
							onError={() => {
								setError(true);
							}}
						>
							<Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={1} width={dim.width - 6} />
						</Document>
					</View>
				</View>

				{/* {(loading || pageLoading) && ( */}
				<Image
					style={{
						flex: 1,
						zIndex: 1,
						position: "absolute",
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						resizeMode: "contain",
						filter: "blur(10px)",
					}}
					key={"draw-" + page + Math.random()}
					resizeMode="contain"
					source={{ uri: `https://www.ingebook.com/ib/pimg/IB_Libros_App/${dir}/thumb/${page}.jpg` }}
				/>
				{/* // )} */}
				{!loading && overlay !== null && viewNotes && !pageLoading && (
					<Image
						style={{
							flex: 1,
							zIndex: 3,
							position: "absolute",
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							resizeMode: "contain",
						}}
						key={"draw-" + page + Math.random()}
						resizeMode="contain"
						source={{ uri: overlay?.includes("data:image/png;base64,") ? overlay : "data:image/png;base64," + overlay }}
					/>
				)}
				<>
					{invitado !== "1" && showBookmarkButton && (
						<TouchableOpacity onPress={bookmarkCallback} style={{ position: "absolute", zIndex: 3, top: -5, right: 10, height: 50, width: 50, alignItems: "center" }}>
							<Ionicons name="bookmark" size={30} color={bookmarked ? "red" : "gainsboro"} />
						</TouchableOpacity>
					)}
					{invitado !== "1" && showPrivateNotesButton && (
						<TouchableOpacity
							onPress={noteCallback}
							style={{ position: "absolute", zIndex: 3, top: 10, left: -20, height: 30, width: 50, justifyContent: "center", alignItems: "center" }}
						>
							<FontAwesome name="tag" size={35} color={"#A4EC57"} style={{ transform: [{ rotate: "135deg" }] }} />
						</TouchableOpacity>
					)}
					{showPublicNotesButton && (
						<TouchableOpacity
							onPress={privateNoteCallback}
							style={{ position: "absolute", zIndex: 3, top: 40, left: -20, height: 30, width: 50, justifyContent: "center", alignItems: "center" }}
						>
							<FontAwesome name="tag" size={35} color={"#5A97D4"} style={{ transform: [{ rotate: "135deg" }] }} />
						</TouchableOpacity>
					)}
					{selected && (
						<View style={{ position: "absolute", height: 50, zIndex: 10, flexDirection: "row", bottom: 15, width: "100%", alignItems: "center", justifyContent: "center" }}>
							{invitado !== "1" && (
								<TouchableOpacity
									onPress={() => {
										newNoteCallback(page);
									}}
									style={{
										backgroundColor: "rgba(0,0,0,.2)",
										borderRadius: 25,
										height: 50,
										width: 50,
										zIndex: 4,
										marginRight: 10,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<FontAwesome name="sticky-note" size={20} color={"white"} />
								</TouchableOpacity>
							)}
							{invitado !== "1" && (
								<TouchableOpacity
									onPress={() => {
										// navigation.navigate("PagePaint", { id: id, page: JSON.stringify(page), uri: uri });
										paintCallback(uri);
									}}
									style={{
										backgroundColor: "rgba(0,0,0,.2)",
										borderRadius: 25,
										marginRight: 10,
										height: 50,
										width: 50,
										zIndex: 4,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<FontAwesome name="pencil" size={24} color={"white"} />
								</TouchableOpacity>
							)}
							{hasVideo && (
								<TouchableOpacity
									onPress={() => {
										videoCallback();
									}}
									style={{
										backgroundColor: "rgba(0,0,0,.2)",
										borderRadius: 25,

										height: 50,
										width: 50,
										zIndex: 4,
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<FontAwesome name="video-camera" size={20} color={"white"} />
								</TouchableOpacity>
							)}
						</View>
					)}
				</>
			</Pressable>
		);
	}
);

export default PageRenderer;
