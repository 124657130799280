import { Ionicons } from "@expo/vector-icons";
import { Modal, Pressable, StyleSheet, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useEffect, useState } from "react";
import { TextInput } from "react-native-gesture-handler";
import { DefaultText, SemiBoldText } from "../components/StyledText";

import moment from "moment";
import { toast } from "react-hot-toast";

export default function CitaModal({ item, editable = false, visible, closeCallback, newNoteCallback, page, id }) {
	const insets = useSafeAreaInsets();
	const w = useWindowDimensions();
	const [loading, setLoading] = useState(false);
	const [pages, setPages] = useState("");
	const [copied, setCopied] = useState(false);
	const [form, setForm] = useState({
		title: "",
		body: "",
		public: false,
	});

	const [value, setValue] = useState("");

	const cita = ` ${item.length === 0 ? "" : item?.autores?.reduce((acc, curr) => `${acc} ${curr.nom} ${curr.ape1},`, "")}  ${item.tit} ${item.edi}`;

	// " PROGRAMACIÓN DE SERVICIOS Y PROCESOS EN PYTHON. 1. Madrid, España, Marcombo Formación, 2022. INGEBOOK. 2022-06-17 13:03:30.0"

	const copyToClipboard = async (value) => {
		setTimeout(async () => {
			window.parent.postMessage("copy" + value, "*");
			setTimeout(() => {
				closeCallback();
				toast.success("Cita copiada al portapapeles", {
					style: {
						zIndex: 10000,
					},
					duration: 5000,
				});
				// setCopied(true);
				// setTimeout(() => {
				// 	setCopied(false);
				// }, 5000);
			}, 500);
			// await window.navigator.clipboard.writeText("copy" + value).then(() => {
			// }),
		}, 500);
	};

	useEffect(() => {
		setValue("");
	}, [visible]);

	const citaMLA = `${
		item?.autores?.length === 0 ? "" : item?.autores?.reduce((acc, curr, i, arr) => `${acc}${i === 0 ? "" : ", "}${curr?.ape1 ? curr?.ape1 + "," : ""} ${curr?.nom || ""}`, "")
	}, ${item.tit}. ${item?.edic || ""}. Madrid, España, ${item.edi || ""}, ${item.fecha_publicacion || ""}. INGEBOOK.${pages ? ` pp. ${pages}, ` : ""}${moment().format(
		"YYYY-MM-DD HH:mm:ss"
	)}`;

	const citaAPA = `${
		item?.autores?.length === 0 ? "" : item?.autores?.reduce((acc, curr, i, arr) => `${acc}${i === 0 ? "" : ", "}${curr?.ape1 ? curr?.ape1 + "," : ""} ${curr?.nom || ""}`, "")
	}, (${item?.fecha_publicacion || ""}), ${item.tit}. Madrid, España: ${
		item.edi || ""
	}. Obtenido de https://www.ingebook.com/ib/NPcd/IB_BooksVis?cod_primaria=1000193&codigo_libro=${item.id}${pages ? `, pp. ${pages}` : ""}`;

	const citaChicago = `${
		item?.autores?.length === 0 ? "" : item?.autores?.reduce((acc, curr, i, arr) => `${acc}${i === 0 ? "" : ", "}${curr?.ape1 ? curr?.ape1 + "," : ""} ${curr?.nom || ""}`, "")
	}, ${item?.fecha_publicacion || ""}. ${item.tit}. Ebook. ${item.edic || ""}. Madrid. España: ${
		item.edi || ""
	}. https://www.ingebook.com/ib/NPcd/IB_BooksVis?cod_primaria=1000193&codigo_libro=${item.id}${pages ? `, pp. ${pages}` : ""}`;

	const citaHarvard = `${
		item?.autores?.length === 0 ? "" : item?.autores?.reduce((acc, curr, i, arr) => `${acc}${i === 0 ? "" : ", "}${curr?.ape1 ? curr?.ape1 + "," : ""} ${curr?.nom || ""}`, "")
	}, (${item?.fecha_publicacion || ""}). ${item.tit}. ${item.edic || ""}. [ebook] Madrid. España: ${
		item.edi || ""
	}, Disponible en: https://www.ingebook.com/ib/NPcd/IB_BooksVis?cod_primaria=1000193&codigo_libro=${item.id}${pages ? `, pp. ${pages}` : ""} [Accedido el ${moment().format(
		"YYYY-MM-DD HH:mm:ss"
	)}].`;

	// MLA: Tizard , Ian R., VACUNAS VETERINARIAS. 1. Madrid, España, Acribia, 2022. INGEBOOK. 2022-08-02 22:25:50.0
	// APA: Tizard , Ian R., (2022), VACUNAS VETERINARIAS. Madrid, España: Acribia. Obtenido de https://www.ingebook.com/ib/NPcd/IB_BooksVis?cod_primaria=1000193&codigo_libro=
	// Chicago: Tizard , Ian R., 2022. VACUNAS VETERINARIAS. Ebook. 1. Madrid, España: Acribia. https://www.ingebook.com/ib/NPcd/IB_BooksVis?cod_primaria=1000193&codigo_libro=
	// Harvard: Tizard , Ian R., (2022). VACUNAS VETERINARIAS. 1. [ebook] Madrid, España: Acribia, Disponible en: https://www.ingebook.com/ib/NPcd/IB_BooksVis?cod_primaria=1000193&codigo_libro= [Accedido el 2022-08-02 22:25:50.0].
	return (
		<Modal onDismiss={() => setForm({ title: "", body: "", public: false })} visible={visible} animationType="slide" transparent={true}>
			<Pressable onPress={closeCallback} style={{ backgroundColor: "rgba(0,0,0,.5)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} />
			<View
				style={{
					flex: 1,
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<View
					style={{
						backgroundColor: "white",
						borderRadius: 15,
						marginHorizontal: 10,
						maxHeight: 500,
					}}
				>
					<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "black", borderTopEndRadius: 15, borderTopStartRadius: 15 }}>
						<View style={{ alignItems: "center", paddingVertical: 20 }}>
							<SemiBoldText style={{ color: "white", fontSize: 20 }}>Crear Cita</SemiBoldText>
							<DefaultText style={{ color: "white" }}>Una ayuda en tus bibliografías</DefaultText>
						</View>
						<TouchableOpacity onPress={closeCallback} style={{ position: "absolute", right: 0, top: 0, width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
							<Ionicons name="close" size={30} color="white" />
						</TouchableOpacity>
					</View>
					<View style={{ paddingVertical: 15, paddingHorizontal: 15, marginBottom: copied ? 0 : 25, alignItems: "center" }}>
						<DefaultText>Escriba el número de página (ej: 9) o intervalo (ej: 9-10) y pulsa el botón general</DefaultText>
						<TextInput
							multiline={false}
							placeholder="Páginas..."
							value={pages}
							onChangeText={(ev) => setPages(ev)}
							style={{
								fontSize: 14,
								marginTop: 15,
								borderWidth: 1,
								minWidth: 100,
								borderColor: "gainsboro",
								paddingHorizontal: 10,
								paddingVertical: 5,
								marginBottom: 10,
								borderRadius: 6,
								fontFamily: "Montserrat",
							}}
						/>
						<DefaultText style={{ fontSize: 12, color: "gray" }}>*Si deseas citar el libro completo dejar en blanco</DefaultText>
					</View>
					{copied ? (
						<View style={{ marginVertical: 25, padding: 10, borderRadius: 5, backgroundColor: "#f2f2f2", marginHorizontal: 20 }}>
							<SemiBoldText style={{ textAlign: "center", color: "green", fontSize: 12 }}>Cita copiada al portapapeles</SemiBoldText>
						</View>
					) : null}
					<View style={{ flex: 1, paddingHorizontal: 15, position: "relative", paddingBottom: insets.bottom ? insets.bottom : 15 }}>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<SemiBoldText style={{ fontSize: 14, color: "black", marginRight: 10 }}>MLA:</SemiBoldText>
							<TextInput
								multiline={false}
								placeholder=""
								editable={false}
								value={citaMLA}
								// value={form.title}
								// onChangeText={(ev) => setForm({ ...form, title: ev })}
								style={{ fontSize: 14, flex: 1, borderWidth: 1, borderColor: "gainsboro", paddingHorizontal: 10, paddingVertical: 10, fontFamily: "Montserrat-SemiBold" }}
							/>
							<TouchableOpacity onPress={() => copyToClipboard(citaMLA)} style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
								<Ionicons name="clipboard-outline" size={18} color="black" />
							</TouchableOpacity>
						</View>

						<View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
							<SemiBoldText style={{ fontSize: 14, color: "black", marginRight: 10 }}>APA:</SemiBoldText>
							<TextInput
								multiline={false}
								placeholder=""
								editable={false}
								value={citaAPA}
								// value={form.title}
								// onChangeText={(ev) => setForm({ ...form, title: ev })}
								style={{ fontSize: 14, flex: 1, borderWidth: 1, borderColor: "gainsboro", paddingHorizontal: 10, paddingVertical: 10, fontFamily: "Montserrat-SemiBold" }}
							/>
							<TouchableOpacity onPress={() => copyToClipboard(citaAPA)} style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
								<Ionicons name="clipboard-outline" size={18} color="black" />
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
							<SemiBoldText style={{ fontSize: 14, color: "black", marginRight: 10 }}>CHICAGO:</SemiBoldText>
							<TextInput
								multiline={false}
								placeholder=""
								editable={false}
								value={citaChicago}
								// value={form.title}
								// onChangeText={(ev) => setForm({ ...form, title: ev })}
								style={{ fontSize: 14, flex: 1, borderWidth: 1, borderColor: "gainsboro", paddingHorizontal: 10, paddingVertical: 10, fontFamily: "Montserrat-SemiBold" }}
							/>
							<TouchableOpacity onPress={() => copyToClipboard(citaChicago)} style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
								<Ionicons name="clipboard-outline" size={18} color="black" />
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}>
							<SemiBoldText style={{ fontSize: 14, color: "black", marginRight: 10 }}>HAVARD:</SemiBoldText>
							<TextInput
								multiline={false}
								placeholder=""
								editable={false}
								value={citaHarvard}
								// value={form.title}
								// onChangeText={(ev) => setForm({ ...form, title: ev })}
								style={{ fontSize: 14, flex: 1, borderWidth: 1, borderColor: "gainsboro", paddingHorizontal: 10, paddingVertical: 10, fontFamily: "Montserrat-SemiBold" }}
							/>
							<TouchableOpacity onPress={() => copyToClipboard(citaHarvard)} style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
								<Ionicons name="clipboard-outline" size={18} color="black" />
							</TouchableOpacity>
						</View>

						<TouchableOpacity
							onPress={() => setValue(cita)}
							style={{ marginTop: 15, backgroundColor: "#005ba9", alignItems: "center", justifyContent: "center", height: 40, borderRadius: 20 }}
						>
							<SemiBoldText style={{ color: "white" }}>Generar</SemiBoldText>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "white",
		flex: 1,
	},
	headerContainer: {
		width: "100%",
		height: 40,
		maxHeight: 40,
		minHeight: 40,
		borderBottomWidth: 2,
		borderBottomColor: "#e6e6e6",
	},
	toolButton: {
		height: 45,
		borderBottomWidth: 1,
		borderBottomColor: "gainsboro",
		flexDirection: "row",
		alignItems: "center",
	},
	optionsMenu: {
		position: "absolute",
		right: 5,
		zIndex: 200,
		width: 250,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	buttonsRow: {
		flexDirection: "row",
	},
	buttonsTextContainer: {
		paddingHorizontal: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonsText: {
		fontSize: 14,
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
	},
	menuButton: {
		width: 45,
		height: 35,
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: 5,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	separator: {
		marginVertical: 30,
		height: 1,
		width: "80%",
	},
	description: {
		marginVertical: 5,
		fontSize: 14,
		opacity: 0.6,
		borderBottomWidth: 1,
		borderBottomColor: "black",
	},
});
