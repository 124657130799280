import { AntDesign, Ionicons } from '@expo/vector-icons';
import axios from "axios";
import React, { useState } from 'react';
import { Pressable, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import * as Animatable from "react-native-animatable";
import Alert from "./Alert";
import { BoldText, DefaultText, SemiBoldText } from './StyledText';

const PressableAnimatable = Animatable.createAnimatableComponent(Pressable);

type FormValue = { value: string | number, error: string };
type BookRecommendationModalProps = {
    closeCallback: () => void,
    visible: boolean,
    jsessionid: string
    bookId: string,
}

const INITIAL_FORM_VALUES = {
    grade: { value: "", error: "" },
    subject: { value: "", error: "" },
    school: { value: "", error: "" },
    rating: { value: 0, error: "" },
}

function BookRecommendationModal({ closeCallback, visible, jsessionid, bookId }: BookRecommendationModalProps) {

    // errors ????  
    const [form, setForm] = useState<{ grade: FormValue, subject: FormValue, school: FormValue, rating: FormValue }>(INITIAL_FORM_VALUES)

    const [submitting, setSubmitting] = useState(false);


    async function submit() {
        setSubmitting(true);
        const errors = Object.values(form)
        if (errors.some(v => v.error !== "")) {
            setSubmitting(false);
            return Alert.alert(
                "Error",
                `Por favor revise los campos marcados en rojo.`,
                [{ text: "Ok" }],
                { cancelable: false }
            );
        }

        const { grade, subject, school, rating } = form;

        // const params = new URLSearchParams({
        //     codigo_libro: bookId,
        //     grado: grade.value as string,
        //     valoracion: rating.value.toString(),
        //     centro: school.value as string,
        //     asignatura: subject.value as string,
        // });
        //
        // ?valoracion=${rating.value}&codigo_libro=${bookId}&grado=${grade.value}&asignatura=${subject.value}&centro=${school.value}
        const uri = `https://api.ingebook.com/ib/NPcd/IB_APP_GstRecomendados;jsessionid=${jsessionid}?codigo_libro=${bookId}&grado=${grade.value.toString()}&valoracion=${rating.value.toString()}&centro=${school.value}&asignatura=${subject.value}`
        try {
            await axios.post(uri, null)
                .then(res => {
                    console.log({ res })
                    Alert.alert(
                        "Éxito",
                        `Se ha enviado su recomendación.`,
                        [
                            {
                                text: "Ok",
                                onPress: () => {
                                    setForm(INITIAL_FORM_VALUES);
                                    setSubmitting(false);
                                    closeCallback()
                                }
                            }
                        ],
                        { cancelable: false })
                })
        } catch (e) {
            setSubmitting(false);
            console.log({ e })
        }
    }

    if (!visible) return null;
    return (
        <View style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, zIndex: 50 }}>
            <PressableAnimatable
                duration={200}
                animation={"fadeIn"}
                onPress={closeCallback}
                style={{ backgroundColor: "rgba(0,0,0,.5)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
            />
            <View style={styles.modalContainer}>
                <View style={styles.header}>
                    <SemiBoldText style={{ color: "white", fontSize: 18 }}>Recomienda el libro</SemiBoldText>
                    <DefaultText style={{ color: "#fff", fontSize: 12 }}>Ayuda a otros usuarios</DefaultText>
                    <TouchableOpacity onPress={closeCallback} style={styles.closeBtn}>
                        <Ionicons name="close" size={30} color="white" />
                    </TouchableOpacity>
                </View>
                <View style={styles.body}>
                    <FormInput
                        submitting={submitting}
                        label='Grado o Carrera'
                        step={1}
                        sublabel='¿Para qué grado recomiendas este libro?'
                        value={form.grade.value as string}
                        error={form.grade.error}
                        onChangeText={value => setForm({ ...form, grade: { ...form.grade, value } })}
                    />
                    <FormInput
                        submitting={submitting}
                        label='Asignatura'
                        step={2}
                        sublabel='Ayudará en la asignatura de:'
                        value={form.subject.value as string}
                        error={form.subject.error}
                        onChangeText={value => setForm({ ...form, subject: { ...form.grade, value } })}
                    />
                    <FormInput
                        submitting={submitting}
                        label='Universidad o Centro'
                        step={3}
                        sublabel='A los alumnos de la Universidad o Centro:'
                        value={form.school.value as string}
                        error={form.school.error}
                        onChangeText={value => setForm({ ...form, school: { ...form.school, value } })}
                    />

                    <BoldText style={{ color: "#000", fontSize: 16, marginBottom: 5 }}>¿Te ha sido útil para tus estudios?</BoldText>
                    <View style={styles.ratingsContainer}>
                        {
                            Array.from(Array(5), (_, i) => ({ value: i + 1 }))
                                .map(({ value }, index) => {
                                    return (
                                        <TouchableOpacity disabled={submitting} key={`${index}-${value}`} style={{ ...submitting && ({ opacity: 0.4 }) }} onPress={() => setForm({ ...form, rating: { ...form.rating, value } })}>
                                            <AntDesign name={value <= form.rating.value ? "star" : "staro"} size={24} color={value <= form.rating.value ? "#007AFF" : "black"} />
                                        </TouchableOpacity>
                                    )
                                })
                        }
                    </View>
                </View>
                <TouchableOpacity
                    disabled={submitting}
                    onPress={() => submit()}
                    style={[styles.submitBtn, { ...submitting && ({ opacity: 0.4 }) }]}>
                    <SemiBoldText style={{ color: "#fff", fontSize: 16 }}>ENVIAR</SemiBoldText>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default BookRecommendationModal;

const styles = StyleSheet.create({
    modalContainer: {
        backgroundColor: "#fff",
        flex: 1,
        marginVertical: 50,
        borderRadius: 15,
        overflow: "hidden",
        width: "100%",
        maxWidth: 500,
        alignSelf: "center",
    },
    closeBtn: {
        position: "absolute",
        right: 0,
        top: 0,
        width: 50,
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },
    header: {
        height: 80,
        paddingVertical: 10,
        backgroundColor: '#000',
        alignItems: "center",
        justifyContent: "space-around",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        position: 'relative'
    },
    body: {
        flex: 1,
        padding: 20,
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    ratingsContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-around",
    },
    submitBtn: {
        backgroundColor: "#007AFF",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        marginHorizontal: 10,
        marginBottom: 20,
        width: "90%",
        alignSelf: "center",
        height: 50,
    }
})

function FormInput({ value, label, sublabel, error, step, onChangeText, submitting }: { submitting: boolean, value: string, step: number, label: string, error: string, sublabel: string, onChangeText: (value: string) => void }) {

    const [focused, setFocused] = useState(false);

    return (
        <>
            <View style={{ flexDirection: 'row', width: "50%", alignItems: "center" }}>
                <View style={{ backgroundColor: "#d30000", height: 35, width: 35, borderRadius: 17.5, alignItems: 'center', justifyContent: 'center' }}>
                    <SemiBoldText style={{ color: "#fff", textAlign: "center" }}>{step}</SemiBoldText>
                </View>
                <SemiBoldText style={formInputStyles.sublabel}>{label}</SemiBoldText>
            </View>
            <View>
                <BoldText style={formInputStyles.label}>{sublabel}</BoldText>
            </View>
            <TextInput
                editable={!submitting}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChangeText={onChangeText}
                style={[formInputStyles.input, focused ? formInputStyles.focused : {}, submitting && ({ opacity: 0.4 })]}
            />
            {error && <DefaultText style={{ color: "red", fontSize: 12, marginBottom: 5 }}>{error}</DefaultText>}
        </>
    )
}

const formInputStyles = StyleSheet.create({
    label: {
        color: "#000",
        fontSize: 16,
        marginBottom: 5
    },
    sublabel: {
        color: "#d30000",
        fontSize: 16,
        marginLeft: "auto",
        marginRight: 'auto',
        marginBottom: 5
    },
    input: {
        backgroundColor: "#fff",
        width: "80%",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        height: 50,
        marginBottom: 10,
        borderWidth: 1,
        borderColor: "#000",
    },
    focused: {
        borderWidth: 2,
        borderColor: "#007AFF"
    }
})
