import { Ionicons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";

import { Image, Modal, Pressable, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";

const WelcomeModal = () => {
	const [show, setShow] = useState<boolean>(false);
	const [doNotShow, setDoNotShow] = useState<boolean>(false);
	const [step, setStep] = useState<number>(0);

	const dimensions = useWindowDimensions();
	const isPhone = dimensions.width < 820;

	useEffect(() => {
		GetShowStatusFromAsyncStorage();
	}, []);

	const GetShowStatusFromAsyncStorage = async () => {
		const value = await AsyncStorage.getItem("showWelcomeModal");
		if (value !== null) {
			setShow(false);
			setDoNotShow(true);
		} else {
			setShow(true);
		}
	};

	const SetShowStatusToAsyncStorage = async (value: string) => {
		await AsyncStorage.setItem("showWelcomeModal", value);
	};

	const close = () => {
		if (doNotShow) {
			SetShowStatusToAsyncStorage("false");
		}
		setShow(false);
	};

	return (
		<>
			<TouchableOpacity onPress={() => setShow(true)} style={{ width: 50, height: 50, zIndex: 1, position: "absolute", bottom: 10, right: 10 }}>
				<Image style={{ width: 50, height: 50 }} source={require("../assets/images/bell.png")} />
			</TouchableOpacity>
			{show ? (
				<Modal transparent animationType="fade" >
					<View  style={{ flex: 1, alignItems: "center", justifyContent: "center", zIndex: 2 }}>
						<Pressable style={{ zIndex:1, position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,.75)" }} onPress={close} />
						<View style={{ zIndex:2, flexDirection: "row", justifyContent: isPhone ? "center" : undefined, alignItems: isPhone ? "center" : "flex-start", marginLeft: isPhone ? 0 : 200 }}>
							<View style={{ backgroundColor: "white", maxWidth: 700, width: isPhone ? "95%" : "100%", borderRadius: 10, alignItems: "center" }}>
								<TouchableOpacity
									style={{ position: "absolute", top: 0, right: 0, zIndex: 10, width: 40, height: 40, alignItems: "center", justifyContent: "center" }}
									onPress={close}
								>
									<Ionicons name="close" color="gray" size={24} />
								</TouchableOpacity>

								<View style={{ alignItems: "center", paddingTop: 30, paddingHorizontal: 20, maxWidth: 450, marginBottom: 25 }}>
									<Text style={{ color: "gray" }}>Nos hemos renovado</Text>
									<View style={{ flexDirection: "row", marginBottom: 10, alignItems: "center" }}>
										<Image style={{ width: 50, height: 50 }} source={require("../assets/icons/noti.png")} />
										<Text style={{ fontSize: isPhone ? 24 : 30, fontWeight: "bold" }}>Estrenamos nuevo visor</Text>
									</View>
									<Text style={{ textAlign: "center", color: "gray" }}>Más rápido y versátil, con nueva experiencia, diseño y funcionalidades.</Text>
								</View>

								<View style={{ marginVertical: 25, height: isPhone ? "auto" : 450, paddingHorizontal: 20, flexDirection: isPhone ? "column" : "row" }}>
									<View style={{ flex: 1, marginRight: 15 }}>
										<TouchableOpacity
											style={[
												...(step === 0 ? [styles.shadow] : [{}]),
												{
													padding: 15,
													backgroundColor: "white",
													flexDirection: "row",
													width: "100%",
													borderRadius: 5,
												},
											]}
											onPress={() => setStep(0)}
										>
											<Image style={{ width: 35, height: 35 }} source={require("../assets/icons/check.png")} />
											<View style={{ marginLeft: 15, flex: 1 }}>
												<Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "bold" }}>Múltiples vistas y modo de lectura.</Text>
												{step === 0 ? <Text style={{ color: "gray" }}>Podrás seleccionar la vista de página fluida (en horizontal o vertical) y a una o dos páginas.</Text> : null}
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={[
												...(step === 1 ? [styles.shadow] : [{}]),
												{
													padding: 15,
													backgroundColor: "white",
													flexDirection: "row",
													width: "100%",
													borderRadius: 5,
												},
											]}
											onPress={() => setStep(1)}
										>
											<Image style={{ width: 35, height: 35 }} source={require("../assets/icons/check.png")} />
											<View style={{ marginLeft: 15, flex: 1 }}>
												<Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "bold" }}>Nuevo modo de edición de página.</Text>
												{step === 1 ? (
													<Text style={{ color: "gray" }}>Subraya, crea notas... añade todo lo que necesites para enriquecer tu estudio. ¡No olvides guardarlo!</Text>
												) : null}
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={[
												...(step === 2 ? [styles.shadow] : [{}]),
												{
													padding: 15,
													backgroundColor: "white",
													flexDirection: "row",
													width: "100%",
													borderRadius: 5,
												},
											]}
											onPress={() => setStep(2)}
										>
											<Image style={{ width: 35, height: 35 }} source={require("../assets/icons/check.png")} />
											<View style={{ marginLeft: 15, flex: 1 }}>
												<Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "bold" }}>Accesos directos en la propia página.</Text>
												{step === 2 ? (
													<Text style={{ color: "gray" }}>Encontrarás accesos directos para crear una nota, editar y visualizar la videoaula directamente desde la página.</Text>
												) : null}
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={[
												...(step === 3 ? [styles.shadow] : [{}]),
												{
													padding: 15,
													backgroundColor: "white",
													flexDirection: "row",
													width: "100%",
													borderRadius: 5,
												},
											]}
											onPress={() => setStep(3)}
										>
											<Image style={{ width: 35, height: 35 }} source={require("../assets/icons/check.png")} />
											<View style={{ marginLeft: 15, flex: 1 }}>
												<Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "bold" }}>Crea notas enriquecidas</Text>
												{step === 3 ? (
													<Text style={{ color: "gray" }}>
														Añade contenido multimedia a tus notas: desde una imagen hasta un video de youtube. ¡Compártela con otros usuarios si lo deseas!
													</Text>
												) : null}
											</View>
										</TouchableOpacity>
										<TouchableOpacity
											style={[
												...(step === 4 ? [styles.shadow] : [{}]),
												{
													padding: 15,
													backgroundColor: "white",
													flexDirection: "row",
													width: "100%",
													borderRadius: 5,
												},
											]}
											onPress={() => setStep(4)}
										>
											<Image style={{ width: 35, height: 35 }} source={require("../assets/icons/check.png")} />
											<View style={{ marginLeft: 15, flex: 1 }}>
												<Text style={{ marginBottom: 10, fontSize: 16, fontWeight: "bold" }}>Recomienda un libro</Text>
												{step === 4 ? <Text style={{ color: "gray" }}>¿Te ha resultado útil el libro? Compártelo con la comunidad para ayudar a otros estudiantes.</Text> : null}
											</View>
										</TouchableOpacity>
									</View>
									<View style={{ flex: 1, width: isPhone ? "100%" : 400, height: isPhone ? 300 : 400 }}>
										<Image style={{ flex: 1, resizeMode: "contain" }} source={require(`../assets/images/${step + 1}.png`)} />
									</View>
								</View>

								<View
									style={{
										paddingHorizontal: 20,
										flexDirection: isPhone ? "column" : "row",
										minHeight: isPhone ? "auto" : 50,
										width: "100%",
										alignItems: "center",
										justifyContent: "space-between",
										borderTopWidth: 1,
										borderTopColor: "gainsboro",
										paddingVertical: isPhone ? 15 : 0,
									}}
								>
									<TouchableOpacity
										onPress={() => window.parent.postMessage("https://www.ingebook.com/ib/NPortada?CodPortada=1000041;_blank", "*")}
										style={{ height: isPhone ? 40 : "100%", marginBottom: isPhone ? 15 : 0, alignItems: "center", flexDirection: "row" }}
									>
										<Text style={{ marginRight: 5 }}>Más ayuda ingebook</Text>
										<Ionicons name="open-outline" color="gray" size={16} />
									</TouchableOpacity>
									<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
										<TouchableOpacity onPress={() => setDoNotShow(!doNotShow)} style={{ flexDirection: "row", height: "100%", marginRight: 15, alignItems: "center" }}>
											<View style={{ height: 20, width: 20, alignItems: "center", justifyContent: "center", borderRadius: 3, borderWidth: 2, marginRight: 10 }}>
												{doNotShow ? <View style={{ backgroundColor: "red", borderRadius: 3, width: 11, height: 11 }} /> : null}
											</View>
											<Text>No volver a mostrar</Text>
										</TouchableOpacity>
										<TouchableOpacity
											onPress={() => (step === 4 ? close() : setStep(step + 1))}
											style={{ borderRadius: 5, backgroundColor: "#4280dd", paddingVertical: 10, paddingHorizontal: 20 }}
										>
											<Text style={{ color: "white", fontWeight: "bold" }}>{step === 4 ? "Finalizar" : "Siguiente"}</Text>
										</TouchableOpacity>
									</View>
								</View>
							</View>

							{!isPhone ? (
								<View style={{ height: 200, width: 200, zIndex: 10 }}>
									<Image style={{ flex: 1, resizeMode: "contain" }} source={require("../assets/images/qr.png")} />
								</View>
							) : null}
						</View>
					</View>
				</Modal>
			) : null}
		</>
	);
};

const styles = StyleSheet.create({
	shadow: {
		zIndex: 10,
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 15,
		elevation: 10,
		backgroundColor: "white",
		borderRadius: 15,
	},
});

export default WelcomeModal;
