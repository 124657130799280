import React, { useCallback, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Animated, Image as NativeImage, Platform, ScrollView, StyleSheet, TouchableOpacity, View, useWindowDimensions } from "react-native";

import { Entypo, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import * as shaper from "d3-shape";
import * as FileSystem from "expo-file-system";
import * as ImagePicker from "expo-image-picker";
import { PanGestureHandler, PinchGestureHandler, RotationGestureHandler, State, TapGestureHandler } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Svg, { Circle, Ellipse, ForeignObject, G, Line, Path, Polygon, Rect, Image as SImage } from "react-native-svg";
import uuid from "react-native-uuid";
import { Document, Page, pdfjs } from "react-pdf";
import simplify from "simplify-js";
import Alert from "../components/Alert";
import { BoldText, DefaultText } from "../components/StyledText";

import { windowHeight, windowWidth } from "../config/config";

const options = {
	cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

const colors = [
	"black",
	"white",
	"#F6AE2D",
	"#8367C7",
	"#E84855",
	"#C2F8CB",
	"#51D6FF",
	"pink",
	"gray",
	"green",
	"blue",
	"yellow",
	"brown",
	"red",
	"cyan",
	"magenta",
	"lime",
	"indigo",
	"navy",
	"teal",
	"olive",
	"maroon",
	"silver",
	"gold",
	"aqua",
	"fuchsia",
	"limegreen",
	"lightblue",
	"lightcoral",
	"lightgreen",
	"lightgrey",
	"lightpink",
	"lightsalmon",
	"lightseagreen",
	"lightskyblue",
	"lightslategray",
	"lightsteelblue",
	"lightyellow",
	"mediumaquamarine",
	"mediumblue",
	"mediumorchid",
	"mediumpurple",
	"mediumseagreen",
	"mediumslateblue",
	"mediumspringgreen",
	"mediumturquoise",
	"mediumvioletred",
	"midnightblue",
	"navyblue",
	"olivedrab",
	"orangered",
	"orchid",
	"palegoldenrod",
	"palegreen",
	"paleturquoise",
	"palevioletred",
	"papayawhip",
	"peachpuff",
	"peru",
	"plum",
	"powderblue",
	"rosybrown",
	"royalblue",
	"saddlebrown",
	"salmon",
	"sandybrown",
	"seagreen",
	"seashell",
	"sienna",
	"skyblue",
	"slateblue",
	"slategray",
	"snow",
	"springgreen",
	"steelblue",
	"tan",
	"thistle",
	"tomato",
	"turquoise",
	"violet",
	"wheat",
	"yellowgreen",
];

export default function Painter({ page, uri, id, jsessionid, closeCallback, changePageCallback, refetchAnotationsCallback }) {
	// usePreventScreenCapture();
	const insets = useSafeAreaInsets();
	const shapesRef = useRef([]);
	const shapeTypeRef = useRef("Pointer");

	const w = useWindowDimensions();
	const [jsession, setJsession] = useState("");

	const svgRef = useRef();
	const rotationRef = useRef();
	const pinchRef = useRef();
	const panRef = useRef();
	const tapRef = useRef();
	const doubleTapRef = useRef();
	const positionRef = useRef(0);

	const pageRef = useRef();

	const [saved, setSaved] = useState(true);
	const [draws, setDraws] = useState([]);
	const [drawSelected, setDrawSelected] = useState(null);

	const [shapes, setShapes] = useState([]);
	const [shapeSelected, setShapeSelected] = useState(null);
	const [toolSelected, selectTool] = useState(null);
	const [shapeType, setShapeType] = useState("Pointer");
	const [openShape, setOpenShape] = useState(false);
	const [colorModal, setColorModal] = useState(false);
	const [textAlign, setTextAlign] = useState("left");

	const [paint, setPaint] = useState(false);
	const [color, setColor] = useState("#B0C808");
	const [strokeColor, setStrokeColor] = useState("orange");
	const [size, setSize] = useState(6);
	const [strokeWidth, setStrokeWidth] = useState(1);
	const [filled, setFilled] = useState(true);
	const [opacity, setOpacity] = useState(1);

	const [markerStart, setMarkerStart] = useState("none");
	const [markerEnd, setMarkerEnd] = useState("none");
	const [strokeDasharray, setStrokeDasharray] = useState(null);

	const [modal, setModal] = useState(false);
	const [canvasDimensions, setCanvasDimensions] = useState({
		width: w.width,
		height: w.height,
	});

	const [pointSelected, setPointSelected] = useState({ id: null, x: 0, y: 0 });
	const [lastPoint, setLastPoint] = useState({ x: 0, y: 0 });
	const [lastScale, setLastScale] = useState(0);
	const [lastRotation, setLastRotation] = useState(0);
	const [rot, setRot] = useState(false);

	const [resizing, setResizing] = useState<string>();
	const [viewLayers, setViewLayers] = useState(false);
	const [ratio, setRatio] = useState(0);
	const [viewMode, setViewMode] = useState("fullPage"); // fullPage | extended

	const [saving, setSaving] = useState(false);

	useEffect(() => {
		fetchPage();
	}, [page]);

	useEffect(() => {
		fetchBook(id);
	}, [id]);

	// useEffect(() => {
	// 	NativeImage.getSize(uri, (width, height) => {
	// 		setRatio(height / width);
	// 	});
	// }, [uri]);

	useEffect(() => {
		setShapeSelected(null);
	}, [shapeType]);

	useEffect(() => {
		setCanvasDimensions({
			width: viewMode === "fullPage" ? (w.height - 50) / (ratio || 1) : w.width,
			height: viewMode === "fullPage" ? w.height - 50 : w.width * ratio,
		});
	}, [w.width, ratio, viewMode]);

	useEffect(() => {
		setShapes([]);
		shapesRef.current = [];
	}, [positionRef.current]);

	useEffect(() => {
		if (shapeSelected !== null) {
			setColor(shapes[shapeSelected]?.color);
			setStrokeColor(shapes[shapeSelected]?.stroke);
			setOpacity(shapes[shapeSelected]?.opacity);
			setStrokeWidth(shapes[shapeSelected]?.strokeWidth);
			setStrokeDasharray(shapes[shapeSelected]?.strokeDasharray);
			setSize(shapes[shapeSelected]?.size || 6);
			setFilled(shapes[shapeSelected]?.filled || false);
			setTextAlign(shapes[shapeSelected]?.textAlign || "left");
		}
	}, [shapeSelected]);

	const fetchBook = async (id: number) => {
		setJsession(jsession);
	};

	const svgString2Image = (svgString, format, callback) => {
		// set default for format parameter
		format = format ? format : "png";
		// SVG data URL from SVG string
		// var svgData = btoa(unescape(encodeURIComponent(svgString)));
		var svgData = svgString;
		// create canvas in memory(not in DOM)
		var canvas = document.createElement("canvas");
		// get canvas context for drawing on canvas
		var context = canvas.getContext("2d");
		// set canvas size
		canvas.width = canvasDimensions.width;
		canvas.height = canvasDimensions.height;
		// create image in memory(not in DOM)
		var image = new Image();
		// later when image loads run this
		image.onload = function () {
			// async (happens later)
			// clear canvas
			context.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
			// draw image with SVG data to canvas
			context.drawImage(image, 0, 0, canvasDimensions.width, canvasDimensions.height);
			// snapshot canvas as png
			var pngData = canvas.toDataURL("image/" + format);
			// pass png data URL to callback
			callback(pngData);
		}; // end async
		// start loading SVG data into in memory image
		image.src = svgData;
	};

	const saveImage = async () => {
		try {
			const dataUri = await saveDraw();

			const content = JSON.stringify({ data: shapesRef.current, dataUri: dataUri });

			var blob = new Blob([content], { type: "application/json" });

			const uri = `https://api.ingebook.com/ib/NPcd/IB_APP_CargaArchivo;jsessionid=${jsessionid}?libro=${id}&pagina=${page}&key=${jsessionid}&cod_primaria=1000193`;

			const data = new FormData();

			data.append("archivo", blob);

			var request = new XMLHttpRequest();
			request.open("POST", uri);
			request.send(data);
			request.onreadystatechange = function () {
				if (request.readyState === 4) {
					var response = JSON.parse(request.responseText);
					if (response.status === "0") {
						// console.log("successful");
						setSaved(true);
						Alert.alert("Se ha guardado las anotaciones correctamente", "", [{ text: "Ok" }]);
						refetchAnotationsCallback();
					} else {
						Alert.alert("Ha ocurrido un error al guardar las anotaciones", "", [{ text: "Ok" }]);
					}
					setSaving(false);
				}
			};
		} catch (err) {
			Alert.alert("Ha ocurrido un error al guardar las anotaciones");
		}
	};

	const fetchPage = async () => {
		try {
			setSaved(true);
			setShapes([]);
			setShapeSelected(null);
			const p = await fetch(`https://api.ingebook.com/ib/IB_Browser;jsessionid=${jsessionid}?libro=${id}&pagina=${page}&extras=2`, {
				method: "POST",
			})
				.then((res) => res.json())
				.catch((err) => null);

			if (!p.status && p.data) {
				setShapes(p.data);
				shapesRef.current = p.data;
			} else {
				setShapes([]);
				shapesRef.current = [];
			}
		} catch (err) {
			Alert.alert("Ha ocurrido un error al cargar las anotaciones");
		}
	};

	const saveDraw = async () => {
		return new Promise((resolve, reject) => {
			setShapeSelected(null);

			setTimeout(() => {
				if (Platform.OS === "web") {
					const t = document.getElementById("svgboard");
					let xmlSource = new XMLSerializer().serializeToString(t);

					xmlSource = `<?xml version="1.0" standalone="no"?>\r\n${xmlSource}`;

					const svg64 = encodeURIComponent(xmlSource);
					const b64Start = "data:image/svg+xml;charset=utf-8,";
					const dataUri = b64Start + svg64;
					// console.log(dataUri);
					svgString2Image(dataUri, "png", (pngData) => {
						resolve(pngData);
					});
				} else {
					svgRef.current.toDataURL(async (b) => {
						const fileName = FileSystem.cacheDirectory + `test.json`;

						const file = await FileSystem.writeAsStringAsync(fileName, b)
							.then((res) => res)
							.catch((err) => console.log(err));

						resolve(b);
					});
				}
			}, 100);
		});
	};

	const undo = async () => {
		const prev = shapesRef.current;
		prev.splice(-1, 1);
		setShapes([...prev]);
		shapesRef.current = [...prev];
		setSaved(false);
	};

	const insertText = () => {
		if (shapes[shapeSelected]?.type === "Text") {
			Alert.prompt(
				"Editar el texto: " + shapes[shapeSelected].text,
				"",
				(text) => {
					if (text.length > 0) {
						changeAttribute("text", text);
					}
				},
				"plain-text",
				shapes[shapeSelected].text
			);
		} else {
			Alert.prompt("Introduzca el texto", "", (text) => {
				if (text.length > 0) {
					newText(text);
				}
			});
		}
	};

	const newText = (text) => {
		const shape = {
			id: uuid.v4(),
			x: 0,
			y: 0,
			xper: 45,
			yper: 45,
			opacity: 1,
			scale: 1,
			scaleX: 1,
			scaleY: 1,
			rotation: 0,
			width: 20,
			size: size,
			height: size / 2,
			type: "Text",
			textAlign: textAlign,
			points: "",
			diagonal: 0,
			color: color,
			filled: filled,
			markerStart: markerStart,
			markerEnd: markerEnd,
			stroke: strokeColor,
			strokeWidth: strokeWidth,
			strokeDasharray: strokeDasharray,
			fontSize: size,
			text: text || "Escribe Aqui...",
		};
		const n = [...shapes, shape];
		setShapes(n);
		shapesRef.current = n;
		setShapeSelected(n.length - 1);
		setSaved(false);
	};

	const startPainting = (ev) => {
		setPaint(true);
		// 1 Calculate the offset X / Y in case the canvas doesnt fill the screen
		const offsetX = (canvasDimensions.width - canvasDimensions.width) / 2;
		const offsetY = (canvasDimensions.height - canvasDimensions.height) / 2;
		// Calculate the absolute points
		const x = ev.x - offsetX;
		const y = ev.y - offsetY;
		// Relative points in percentages of the point you touched
		const xper = (x / canvasDimensions.width) * 100;
		const yper = (y / canvasDimensions.height) * 100;

		setModal(false);
		setViewLayers(false);

		if (toolSelected) {
			insertObject(x, y, xper, yper, 10);
		} else if (shapeType) {
			if (shapeType === "Pointer" || shapeType === "Eraser") {
				// console.log("OPS ITS A POINTER OR ERASER");
				setLastPoint({ x, y, xper, yper });
			} else if (shapeType === "Polygon") {
				if (openShape) {
					const arr = shapes;
					const l = arr[arr.length - 1].points.length;
					arr[arr.length - 1].points = [...arr[arr.length - 1].points, { x: xper, y: yper }];
					setShapes([...arr]);
					shapesRef.current = [...arr];
					setPointSelected({ id: l, x: xper, y: yper });
					setSaved(false);
				} else {
					if (shapeSelected === null) {
						const shape = {
							id: uuid.v4(),
							color: color,
							x,
							y,
							xper,
							yper,
							originX: x,
							originY: y,
							opacity: opacity,
							size: size,
							scale: 1,
							scaleX: 1,
							scaleY: 1,
							rotation: 0,
							markerStart: markerStart,
							markerEnd: markerEnd,
							filled: filled,
							stroke: strokeColor,
							strokeWidth: strokeWidth,
							strokeDasharray: strokeDasharray,
							type: shapeType,
							points: [{ x: xper, y: yper }],
						};
						setOpenShape(true);
						setPointSelected({ id: 0, x: xper, y: yper });
						setShapeSelected(shapes.length);
						setShapes([...shapes, shape]);
						shapesRef.current = [...shapes, shape];
						setSaved(false);
					}
				}
			} else if (shapeSelected === null && shapeType !== null) {
				const shape = {
					id: uuid.v4(),
					color: color,
					x,
					y,
					xper,
					yper,
					xper2: xper,
					yper2: yper,
					opacity: opacity,
					width: 1,
					height: 0,
					size: size,
					scale: 1,
					scaleX: 1,
					scaleY: 1,
					originX: x,
					originY: y,
					rotation: 0,
					filled: filled,
					markerStart: markerStart,
					markerEnd: markerEnd,
					stroke: strokeColor,
					strokeWidth: strokeWidth,
					strokeDasharray: strokeDasharray,
					type: shapeType,
					strokeLinecap: "round",
					points: [{ x: 0, y: 0 }],
					...(shapeType === "Text" && { text: "Escribe aquí..." }),
				};

				setShapes([...shapes, shape]);
				shapesRef.current = [...shapes, shape];
				setSaved(false);
			}
		}
	};

	const painting = (ev) => {
		if (shapeType === "Pointer" && shapeSelected === null) {
		} else {
			const offsetX = (canvasDimensions.width - canvasDimensions.width) / 2;
			const offsetY = (canvasDimensions.height - canvasDimensions.height) / 2;
			const x = ev.x - offsetX;
			const y = ev.y - offsetY;

			const xper = (ev.x / canvasDimensions.width) * 100;
			const yper = (ev.y / canvasDimensions.height) * 100;

			const arr = shapes;
			const sel = arr[shapeSelected];

			if (resizing) {
				const scaleX = false;
				const vx = (lastPoint.xper || 0) === 0 ? 0 : xper - (lastPoint.xper || 0);
				const vy = (lastPoint.yper || 0) === 0 ? 0 : yper - (lastPoint.yper || 0);
				if (resizing === "topLeft") {
					sel.xper = scaleX ? sel.xper : sel.xper + vx / sel.scale;
					sel.yper = scaleX ? sel.yper + vy / sel.scale : sel.yper + vy / sel.scale;
					sel.width = scaleX ? sel.width + vx / sel.scale : sel.width - vx / sel.scale;
					sel.height = scaleX ? sel.height - vy / sel.scale : sel.height - vy / sel.scale;
				} else if (resizing === "topRight") {
					sel.yper = scaleX ? sel.yper + vy : sel.yper + vy;
					sel.xper = scaleX ? sel.xper + vx : sel.xper;
					sel.width = scaleX ? sel.width - vx : sel.width + vx;
					sel.height = sel.height - vy;
				} else if (resizing === "bottomLeft") {
					sel.yper = arr[shapeSelected].yper;
					sel.xper = scaleX ? sel.xper : sel.xper + vx;
					sel.width = scaleX ? sel.width + vx : sel.width - vx;
					sel.height = sel.height + vy;
				} else if (resizing === "bottomRight") {
					sel.xper = scaleX ? sel.xper + vx : sel.xper;
					sel.width = scaleX ? sel.width - vx : sel.width + vx;
					sel.height = sel.height + vy;
				}
				setLastPoint({ x, y, xper, yper });
			} else if (shapeSelected !== null) {
				if (rot) {
					var angle = Math.atan2(arr[shapeSelected].yper + arr[shapeSelected].height / 2 - yper, arr[shapeSelected].xper + arr[shapeSelected].width / 2 - xper);
					arr[shapeSelected].rotation = (angle * 180) / Math.PI;
				} else {
					if (arr[shapeSelected].type === "Polygon") {
						if (pointSelected.id !== null) {
							arr[shapeSelected].points[pointSelected.id] = { x, y };
							const movingPoints = arr[shapeSelected].points.map((point, i) => {
								if (i === pointSelected.id) {
									return { x: xper, y: yper };
								} else {
									return point;
								}
							});

							arr[shapeSelected].points = movingPoints;
							setLastPoint({ x, y, xper, yper });
						} else {
							const movingPoints = arr[shapeSelected].points.map((point, i) => {
								const vx = (lastPoint.xper || 0) === 0 ? 0 : xper - (lastPoint.xper || 0);
								const vy = (lastPoint.yper || 0) === 0 ? 0 : yper - (lastPoint.yper || 0);
								return { x: point.x + vx, y: point.y + vy };
							});

							arr[shapeSelected].points = movingPoints;
							setLastPoint({ x, y, xper, yper });
						}
					} else if (arr[shapeSelected].type === "Line" || arr[shapeSelected].type === "Highlight") {
						if (pointSelected.id === 0) {
							const vxper = (lastPoint.xper || 0) === 0 ? 0 : xper - (lastPoint.xper || 0);
							const vyper = (lastPoint.yper || 0) === 0 ? 0 : yper - (lastPoint.yper || 0);
							arr[shapeSelected].xper = arr[shapeSelected].xper + vxper;
							arr[shapeSelected].yper = arr[shapeSelected].yper + vyper;
							setLastPoint({ x, y, xper, yper });
						} else if (pointSelected.id === 1) {
							const vx = (lastPoint.xper || 0) === 0 ? 0 : xper - (lastPoint.xper || 0);
							const vy = (lastPoint.yper || 0) === 0 ? 0 : yper - (lastPoint.yper || 0);
							arr[shapeSelected].xper2 = arr[shapeSelected].xper2 + vx;
							arr[shapeSelected].yper2 = arr[shapeSelected].yper2 + vy;
							setLastPoint({ x, y, xper, yper });
						} else {
							const vx = (lastPoint.xper || 0) === 0 ? 0 : xper - (lastPoint.xper || 0);
							const vy = (lastPoint.yper || 0) === 0 ? 0 : yper - (lastPoint.yper || 0);
							arr[shapeSelected].xper = arr[shapeSelected].xper + vx;
							arr[shapeSelected].yper = arr[shapeSelected].yper + vy;
							arr[shapeSelected].xper2 = arr[shapeSelected].xper2 + vx;
							arr[shapeSelected].yper2 = arr[shapeSelected].yper2 + vy;
							setLastPoint({ x, y, xper, yper });
						}
					} else {
						const vx = lastPoint.x === 0 ? 0 : x - lastPoint.x;
						const vy = lastPoint.y === 0 ? 0 : y - lastPoint.y;
						const vxper = (lastPoint.xper || 0) === 0 ? 0 : xper - (lastPoint.xper || 0);
						const vyper = (lastPoint.yper || 0) === 0 ? 0 : yper - (lastPoint.yper || 0);
						arr[shapeSelected].x = arr[shapeSelected].x + vx;
						arr[shapeSelected].y = arr[shapeSelected].y + vy;
						arr[shapeSelected].xper = !isNaN(arr[shapeSelected].xper + vxper) ? arr[shapeSelected].xper + vxper : 0;
						arr[shapeSelected].yper = !isNaN(arr[shapeSelected].yper + vyper) ? arr[shapeSelected].yper + vyper : 0;
						setLastPoint({ x, y, xper, yper });
					}
				}
			} else if (shapeType !== null && shapeType !== "Eraser") {
				if (shapeType === "Draw") {
					arr[arr.length - 1].points = [...arr[arr.length - 1].points, { x: xper - arr[arr.length - 1].xper, y: yper - arr[arr.length - 1].yper }];
				} else if (shapeType === "Circle" || shapeType === "Focus" || shapeType === "FocusInverted") {
					arr[arr.length - 1].width = xper - arr[arr.length - 1].xper;
					arr[arr.length - 1].height = yper - arr[arr.length - 1].yper;
				} else if (shapeType === "Line" || shapeType === "Highlight") {
					// console.log("IS LINE");
					arr[arr.length - 1].xper2 = xper;
					// arr[arr.length - 1].yper2 = yper;
					// arr[arr.length - 1].width = xper - arr[arr.length - 1].xper;
					// arr[arr.length - 1].height = yper - arr[arr.length - 1].yper;
				} else {
					// arr[arr.length - 1].width = x - arr[arr.length - 1].x;
					// arr[arr.length - 1].height = y - arr[arr.length - 1].y;
					arr[arr.length - 1].width = xper - arr[arr.length - 1].xper;
					arr[arr.length - 1].height = yper - arr[arr.length - 1].yper;
				}
			}
			setShapes([...arr]);
			shapesRef.current = [...arr];
			setSaved(false);
		}
	};

	const endPainting = () => {
		if (shapeType === "Draw") {
			const offsetX = (canvasDimensions.width - canvasDimensions.width) / 2;
			const offsetY = (canvasDimensions.height - canvasDimensions.height) / 2;

			const arr = shapes;
			let shape = arr[shapes.length - 1];

			// const pp = shape.points.map(el => [el.x, el.y])
			const pp = simplify(shape.points, 0.0001, true);
			// const pp = shape.points;
			// const pp = shape.points

			const orgX = arr[shapes.length - 1].xper;
			const orgY = arr[shapes.length - 1].yper;
			const maxX = Math.max(...shape.points.reduce((acc, cur) => [...acc, cur.x], []));
			const maxY = Math.max(...shape.points.reduce((acc, cur) => [...acc, cur.y], []));
			const minX = Math.min(...shape.points.reduce((acc, cur) => [...acc, cur.x], []));
			const minY = Math.min(...shape.points.reduce((acc, cur) => [...acc, cur.y], []));

			const width = maxX - minX;
			const height = maxY - minY;

			arr[shapes.length - 1].points = pp.map((el) => ({ x: el.x - minX, y: el.y - minY }));
			arr[shapes.length - 1].xper = orgX + minX;
			arr[shapes.length - 1].yper = orgY + minY;
			arr[shapes.length - 1].height = Math.abs(height);
			arr[shapes.length - 1].width = Math.abs(width);

			const n = [...arr];
			setShapes(n);
			shapesRef.current = n;
			setSaved(false);
		}
		setPaint(false);
	};

	const endThePan = () => {
		if (Platform.OS !== "web") {
			svgRef.current.toDataURL(async (b) => {
				const orr = draws;
				orr[drawSelected] = { ...orr[drawSelected], shapes: shapes, uri: b };
				setDraws([...orr]);
			});
		}
	};

	const onresizing = (ev) => {
		setLastPoint({ x: 0, y: 0, xper: 0, yper: 0 });
		const vs = lastScale === 0 ? 0 : ev.scale - lastScale;
		if (shapeSelected !== null) {
			const arr = shapes;
			const vs = lastScale === 0 ? 0 : ev.scale - lastScale;
			// console.log("VARIATION SCALE", ev)
			arr[shapeSelected].scale = arr[shapeSelected].scale + vs;
			if (ev.numberOfPointers === 2) {
				const vx = lastPoint.x === 0 ? 0 : ev.focalX - lastPoint.x;
				const vy = lastPoint.y === 0 ? 0 : ev.focalY - lastPoint.y;
				arr[shapeSelected].x = arr[shapeSelected].x + vx;
				arr[shapeSelected].y = arr[shapeSelected].y + vy;
			}
			setLastScale(ev.scale);
			setShapes([...arr]);
			shapesRef.current = [...arr];
			setSaved(false);
		} else {
			setLastScale(ev.scale);
		}
	};

	const rotating = (ev) => {
		if (shapeSelected !== null) {
			const arr = shapes;
			const vr = lastRotation === 0 ? 0 : ev.rotation - lastRotation;
			// console.log("VARIATION ROTATION", vr, lastRotation, ev.rotation)
			arr[shapeSelected].rotation = arr[shapeSelected].rotation + vr * 100;
			// console.log(arr[shapeSelected].rotation)
			setLastRotation(ev.rotation);
			setShapes([...arr]);
			setSaved(false);
		}
	};

	const changeAttribute = (attribute, ev) => {
		if (shapeSelected !== null) {
			const arr = [...shapes];
			arr[shapeSelected][attribute] = ev;
			setShapes([...arr]);
			shapesRef.current = [...arr];
			setSaved(false);
		}
		// endThePan();
	};

	const addCustomImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			// allowsEditing: true,
		});

		if (!result.canceled) {
			NativeImage.getSize(result.uri, (width, height) => {
				const imageRatio = width / height;
				const canvasRatio = canvasDimensions.width / canvasDimensions.height;

				const shape = {
					id: uuid.v4(),
					color: color,
					markerStart: markerStart,
					markerEnd: markerEnd,
					stroke: strokeColor,
					strokeWidth: strokeWidth,
					strokeDasharray: strokeDasharray,
					x: 100,
					y: 100,
					xper: 10,
					yper: 10,
					x2: 200,
					y2: 200,
					size: size,
					opacity: 1,
					scale: 1,
					scaleX: 1,
					scaleY: 1,
					rotation: 0,
					width: 20,
					height: (20 * canvasRatio) / imageRatio,
					strokeWidth: 2,
					type: "CustomImage",
					points: "",
					diagonal: 0,
					uri: result.uri,
					text: "",
				};

				const n = [...shapes, shape];
				setShapes(n);
				shapesRef.current = n;
				shapeChange("Pointer");
				setSaved(false);
			});
		}
	};

	const shapeChange = (type) => {
		setShapeType(type);
		shapeTypeRef.current = type;
		setShapeSelected(null);
	};

	const clickCallback = useCallback((i, ev) => {
		if (shapeTypeRef.current === "Pointer") {
			if (shapeSelected === i) {
				setShapeSelected(null);
			} else {
				setShapeSelected(i);
			}
		} else if (shapeTypeRef.current === "Eraser") {
			setShapeSelected(null);
			const prev = shapesRef.current;
			prev.splice(i, 1);
			setShapes([...prev]);
			shapesRef.current = [...prev];
			setSaved(false);
		}
	},
		[shapeType, shapeSelected]
	);

	const longPressCallback = useCallback(
		(i) => {
			if (shapes[i]?.type === "Text") {
				Alert.prompt(
					"Introduzca el texto",
					"",
					(text) => {
						if (text.length > 0) {
							changeAttribute("text", text);
						}
					},
					"plain-text",
					shapes[i].text
				);
			} else if (shapes[i]?.type !== "CustomImage") {
				setColorModal(true);
				setShapeSelected(i);
			}
		},
		[shapeType, shapeSelected]
	);

	const handleLoadSuccess = async (pdfObject) => {
		pdfObject.getPage(1).then((page) => {
			const { width, height } = page.getViewport({ scale: 1 });
			// pageSizeCallback({ width: width, height: height, ratio: height / width });
			setRatio(height / width);
		});
		// setTimeout(
		// 	() => {
		// 		getDataUri();
		// 	},
		// 	page === 1 ? 1000 : 200
		// );
	};

	const hitTest = (ev) => {

		if (!shapeSelected) return;

		const shape = shapes[shapeSelected];

		if (!shape) return;

		const absWidth = shape?.width * (canvasDimensions.width / 100);
		const absHeight = shape?.height * (canvasDimensions.height / 100);
		const absX = shape?.xper * (canvasDimensions.width / 100);
		const absY = shape?.yper * (canvasDimensions.height / 100);

		const hitPoint = { x: ev.x, y: ev.y };

		const isInside = (hitPoint.x > absX && hitPoint.x < absX + absWidth && hitPoint.y > absY && hitPoint.y < absY + absHeight);

		if(!isInside){

			setShapeSelected(null);
		}


	}

	return (
		<View style={{ backgroundColor: "white", position: "absolute", flex: 1, zIndex: 100000, top: 0, left: 0, bottom: 0, right: 0 }}>
			<View style={{ paddingTop: insets.top, flexDirection: "row", borderBottomWidth: 2, borderBottomColor: "#f4f4f4" }}>
				<div title="Volver">
					<TouchableOpacity
						onPress={() => {
							if (saved) {
								closeCallback(false);
							} else {
								let goBack = confirm("¿Estas seguro que deseas volver?");
								if (goBack) {
									closeCallback(false);
								}
							}
							// saved
							// 	?
							// 	: Alert.alert("¿Está seguro que desea salir del editor sin guardar?", "", [
							// 			{
							// 				text: "Cancelar",
							// 				style: "cancel",
							// 			},
							// 			{
							// 				text: "Salir",
							// 				onPress: () => {
							// 					closeCallback(false);
							// 				},
							// 			},
							// 	  ]);
						}}
						style={{
							width: 40,
							height: 40,
							alignItems: "center",
							justifyContent: "center",
							paddingBottom: 6,
						}}
					>
						<MaterialCommunityIcons name="backburger" size={28} color={"black"} />
					</TouchableOpacity>
				</div>
				<View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end" }}>
					<div title="Ampliar">
						<TouchableOpacity
							onPress={() => setViewMode(viewMode === "fullPage" ? "extended" : "fullPage")}
							style={{
								width: 40,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<MaterialCommunityIcons name={viewMode === "fullPage" ? "magnify-plus-outline" : "magnify-minus-outline"} size={24} color={"black"} />
						</TouchableOpacity>
					</div>
					<div title="Borrar todo">
						<TouchableOpacity
							onPress={() => {
								const p = confirm("¿Está seguro que desea eliminar el documento?");
								if (p) {
									setShapes([]);
									shapesRef.current = [];
									setSaved(false);
								}
							}}
							style={{
								width: 40,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<MaterialCommunityIcons name={"trash-can-outline"} size={24} color={"black"} />
						</TouchableOpacity>
					</div>
					<div title="Borrador">
						<TouchableOpacity
							onPress={() => {
								shapeChange("Eraser");
							}}
							style={{
								width: 40,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<MaterialCommunityIcons name={"eraser"} size={24} color={shapeType === "Eraser" ? "red" : "black"} />
						</TouchableOpacity>
					</div>
					<div title="Eliminar última anotación">
						<TouchableOpacity
							onPress={() => {
								undo();
								// setPaingMenu((p) => !p);
							}}
							style={{
								width: 40,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<MaterialCommunityIcons name="undo-variant" size={20} color="black" />
						</TouchableOpacity>
					</div>
					<div title="Guardar">
						<TouchableOpacity
							disabled={saving}
							onPress={() => {
								setSaving(true);
								saveImage();
								// navigation.goBack();
								// setPaingMenu((p) => !p);
							}}
							style={{
								width: 40,
								height: 40,
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{saving ? <ActivityIndicator color="black" /> : <FontAwesome name={"save"} size={20} color="black" />}
						</TouchableOpacity>
					</div>
				</View>
			</View>
			<View style={[styles.container]}>
				<View
					style={{
						flexDirection: "row",
						alignItems: "flex-start",
						justifyContent: "space-between",
						// height: modal ? 90 : 45,
						borderRadius: modal ? 10 : 25,
						backgroundColor: "rgba(0,0,0,0.8)",
						// paddingHorizontal: 10,
						position: "absolute",
						top: 10,
						width: "95%",
						zIndex: 10,
						paddingBottom: modal ? 15 : 0,
						alignSelf: "center",
						maxWidth: 500,
					}}
				>
					<View style={{ width: "100%", flexDirection: "row" }}>
						<ScrollView horizontal style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
							<View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent", paddingLeft: 10 }}>
								<div title="Seleccionar">
									<TouchableOpacity
										onPress={() => {
											shapeChange("Pointer");
										}}
										style={{
											width: 45,
											height: 45,
											alignItems: "center",
											justifyContent: "center",
											paddingBottom: 6,
										}}
									>
										<MaterialCommunityIcons name="gesture-tap" size={28} color={shapeType === "Pointer" ? "red" : "white"} />
									</TouchableOpacity>
								</div>
								<div title="Subrayado">
									<TouchableOpacity
										style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											setStrokeWidth(2);
											setOpacity(1);
											shapeChange("Line");
										}}
									>
										<FontAwesome name="underline" color={shapeType === "Line" ? "red" : "white"} size={24} />
									</TouchableOpacity>
								</div>
								<div title="Marcador">
									<TouchableOpacity
										style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											setStrokeWidth(10);
											setOpacity(0.3);
											shapeChange("Highlight");
										}}
									>
										<FontAwesome5 name="highlighter" color={shapeType === "Highlight" ? "red" : "white"} size={24} />
									</TouchableOpacity>
								</div>
								<div title="Dibujo Libre">
									<TouchableOpacity
										style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											setStrokeWidth(2);
											setOpacity(1);
											shapeChange("Draw");
										}}
									>
										<Entypo name="pencil" color={shapeType === "Draw" ? "red" : "white"} size={24} />
									</TouchableOpacity>
								</div>
								<div title="Forma">
									<TouchableOpacity
										style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
										onPress={() => {
											setStrokeWidth(2);
											setOpacity(1);
											shapeChange("Square");
										}}
									>
										<Ionicons
											name={shapeType === "Circle" ? "ellipse-outline" : "square-outline"}
											color={shapeType === "Square" || shapeType === "Circle" ? "red" : "white"}
											size={24}
										/>
									</TouchableOpacity>
								</div>
								<div title="Texto">
									<TouchableOpacity style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }} onPress={() => insertText()}>
										<Ionicons name="text" color="white" size={24} />
										{shapeType === "Text" && <View style={{ position: "absolute", bottom: -5, width: 10, height: 10, borderRadius: 5, backgroundColor: "red" }} />}
									</TouchableOpacity>
								</div>
								<div title="Imagen">
									<TouchableOpacity style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }} onPress={() => addCustomImage()}>
										<MaterialCommunityIcons name="image" color="white" size={24} />
										{shapeType === "CustomImage" && <View style={{ position: "absolute", bottom: -5, width: 10, height: 10, borderRadius: 5, backgroundColor: "red" }} />}
									</TouchableOpacity>
								</div>
							</View>
						</ScrollView>
						<View style={{ height: 45, width: 45, backgroundColor: "transparent", justifyContent: "center", alignItems: "center" }}>
							<div title="Opciones">
								<TouchableOpacity
									style={{
										backgroundColor: strokeColor,
										borderWidth: 2,
										borderColor: "white",
										borderRadius: 20,
										width: 35,
										height: 35,
										alignItems: "center",
										justifyContent: "center",
									}}
									onPress={() => setColorModal(!colorModal)}
								>
									{colorModal && <Ionicons name="close" size={24} color="white" />}
								</TouchableOpacity>
							</div>
						</View>
					</View>
				</View>
				{colorModal && (
					<View
						style={[
							styles.shadow,
							{
								borderRadius: 10,
								backgroundColor: "white",
								position: "absolute",
								top: 65,
								width: "95%",
								maxWidth: 500,
								alignSelf: "center",
								zIndex: 10,
							},
						]}
					>
						<ScrollView
							horizontal
							style={{ flex: 1 }}
							showsHorizontalScrollIndicator={false}
							contentContainerStyle={{ paddingHorizontal: 10, paddingVertical: 5, alignItems: "center" }}
						>
							{colors.map((color, index) => (
								<TouchableOpacity
									key={index}
									style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
									onPress={() => {
										if (shapeSelected !== null) {
											changeAttribute("stroke", color);
										}
										setStrokeColor(color);
										setColorModal(false);
									}}
								>
									<View
										style={{
											backgroundColor: color,
											width: 30,
											height: 30,
											borderRadius: 15,
											borderWidth: strokeColor === color ? 2 : 1,
											borderColor: strokeColor === color ? "red" : "gainsboro",
										}}
									/>
								</TouchableOpacity>
							))}
						</ScrollView>
						<View style={{ paddingHorizontal: 20, marginTop: 10, backgroundColor: "transparent" }}>
							{(shapeTypeRef.current === "Circle" || shapes[shapeSelected]?.type === "Circle" || shapes[shapeSelected]?.type === "Square" || shapeTypeRef.current === "Square") && (
								<View style={{ backgroundColor: "transparent" }}>
									<DefaultText style={{ color: "white", fontSize: 12 }}>Tipo de Forma</DefaultText>

									<View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
										<TouchableOpacity
											style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
											onPress={() => {
												setStrokeWidth(2);
												setOpacity(1);
												shapeChange("Square");
											}}
										>
											<Ionicons name="square-outline" color={shapeType === "Square" ? "red" : "black"} size={34} />
										</TouchableOpacity>
										<TouchableOpacity
											style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}
											onPress={() => {
												setStrokeWidth(2);
												setOpacity(1);
												shapeChange("Circle");
											}}
										>
											<MaterialCommunityIcons name="circle-outline" color={shapeType === "Circle" ? "red" : "black"} size={34} />
										</TouchableOpacity>
									</View>
								</View>
							)}
							{(shapeTypeRef.current === "Circle" ||
								shapes[shapeSelected]?.type === "Circle" ||
								shapes[shapeSelected]?.type === "Square" ||
								shapeTypeRef.current === "Square" ||
								shapes[shapeSelected]?.type === "Text") && (
									<View style={{ marginBottom: 10, backgroundColor: "transparent" }}>
										<DefaultText style={{ color: "black", fontSize: 12, marginBottom: 20 }}>Relleno de Forma</DefaultText>

										<View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
											<TouchableOpacity
												style={{
													paddingHorizontal: 15,
													backgroundColor: filled ? "red" : "white",
													marginRight: 10,
													paddingVertical: 5,
													borderRadius: 5,
													borderWidth: filled ? 0 : 1,
													alignItems: "center",
													justifyContent: "center",
												}}
												onPress={() => {
													changeAttribute("filled", true);
													setFilled(true);
												}}
											>
												<DefaultText style={{ color: filled ? "white" : "black", fontSize: 12 }}>Con Relleno</DefaultText>
											</TouchableOpacity>
											<TouchableOpacity
												style={{
													paddingHorizontal: 15,
													backgroundColor: !filled ? "red" : "white",
													paddingVertical: 5,
													borderRadius: 5,
													borderWidth: !filled ? 0 : 1,
													alignItems: "center",
													justifyContent: "center",
												}}
												onPress={() => {
													changeAttribute("filled", false);
													setFilled(false);
												}}
											>
												<DefaultText style={{ color: !filled ? "white" : "black", fontSize: 12 }}>Sin Relleno</DefaultText>
											</TouchableOpacity>
										</View>
									</View>
								)}
							{shapes[shapeSelected]?.type === "Text" && (
								<View style={{ marginBottom: 10, backgroundColor: "transparent" }}>
									<DefaultText style={{ color: "black", fontSize: 12, marginBottom: 20, marginTop: 10 }}>Alineación del Texto</DefaultText>

									<View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
										<TouchableOpacity
											style={{
												paddingHorizontal: 15,
												backgroundColor: textAlign === "left" ? "red" : "white",
												marginRight: 10,
												paddingVertical: 5,
												borderRadius: 5,
												borderWidth: textAlign === "left" ? 0 : 1,
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => {
												changeAttribute("textAlign", "left");
												setTextAlign("left");
											}}
										>
											<FontAwesome name="align-left" color={textAlign === "left" ? "white" : "gray"} />
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												paddingHorizontal: 15,
												backgroundColor: textAlign === "center" ? "red" : "white",
												marginRight: 10,
												paddingVertical: 5,
												borderRadius: 5,
												borderWidth: textAlign === "center" ? 0 : 1,
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => {
												changeAttribute("textAlign", "center");
												setTextAlign("center");
											}}
										>
											<FontAwesome name="align-center" color={textAlign === "center" ? "white" : "gray"} />
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												paddingHorizontal: 15,
												backgroundColor: textAlign === "right" ? "red" : "white",
												paddingVertical: 5,
												borderRadius: 5,
												borderWidth: textAlign === "right" ? 0 : 1,
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={() => {
												changeAttribute("textAlign", "right");
												setTextAlign("right");
											}}
										>
											<FontAwesome name="align-right" color={textAlign === "right" ? "white" : "gray"} />
										</TouchableOpacity>
									</View>
								</View>
							)}
							{shapes[shapeSelected]?.type !== "Text" && shapes[shapeSelected]?.type !== "CustomImage" && (
								<>
									<DefaultText style={{ fontSize: 12 }}>Grosor del Trazo</DefaultText>

									<Slider
										style={{ width: 300, height: 50 }}
										onSlidingComplete={(ev) => {
											if (shapeSelected !== null) {
												changeAttribute("strokeWidth", ev);
											}
											setStrokeWidth(ev);
										}}
										value={strokeWidth}
										minimumValue={1}
										maximumValue={40}
										minimumTrackTintColor="red"
										maximumTrackTintColor="gainsboro"
									/>
								</>
							)}
						</View>
						<View style={{ paddingHorizontal: 20, backgroundColor: "transparent" }}>
							<DefaultText style={{ fontSize: 12, marginTop: 10 }}>Opacidad</DefaultText>

							<Slider
								style={{ width: 300, height: 50 }}
								onSlidingComplete={(ev) => {
									if (shapeSelected !== null) {
										changeAttribute("opacity", ev);
									}
									setOpacity(ev);
								}}
								value={opacity}
								minimumValue={0}
								maximumValue={1}
								minimumTrackTintColor="red"
								maximumTrackTintColor="gainsboro"
							/>
						</View>
						{shapes[shapeSelected]?.type === "Text" && (
							<View style={{ paddingHorizontal: 20, backgroundColor: "transparent" }}>
								<DefaultText style={{ fontSize: 12 }}>Tamaño de Letra</DefaultText>

								<Slider
									style={{ width: 300, height: 50 }}
									onSlidingComplete={(ev) => {
										if (shapeSelected !== null) {
											changeAttribute("size", ev);
										}
										setSize(ev);
									}}
									value={size}
									minimumValue={6}
									maximumValue={60}
									minimumTrackTintColor="red"
									maximumTrackTintColor="gainsboro"
								/>
							</View>
						)}
					</View>
				)}
				<ScrollView
					centerContent={true}
					// scrollEnabled={shapeType === "Pointer" || shapeType === "Eraser"}
					// ref={scrollRef}
					minimumZoomScale={0.8}
					maximumZoomScale={8}
					onResponderGrant={() => {
						if (paint) {
							undo();
							setPaint(false);
						}
					}}
					contentContainerStyle={{
						flexGrow: 1,
						alignItems: "center",
						justifyContent: "center",
						// paddingTop: 60,
						// backgroundColor: "#f3f3f3",
					}}
					style={{
						flex: 1,
						width: "100%",
						height: "100%",
						backgroundImage: "radial-gradient(black 1px, transparent 0)",
						backgroundSize: "40px 40px",
						backgroundColor: "#f3f3f3",
					}}
				>
					<View style={{ ...canvasDimensions, backgroundColor: "#f3f3f3" }}>
						<TapGestureHandler
							ref={tapRef}
							minPointers={1}
							numberOfTaps={1}
							maxPointers={1}
							waitFor={doubleTapRef}
							enabled={shapeType !== "Pointer" || (shapeType === "Pointer" && shapeSelected !== null)}
							onHandlerStateChange={({ nativeEvent }) => {
								nativeEvent.state === 2 && shapeType !== "Pointer" && shapeType !== "Eraser" && startPainting(nativeEvent);
								// nativeEvent.state === State.END && shapeType === "Draw" && endPainting();
								// nativeEvent.state === State.END && shapeType === "Draw" && console.log("FIRST TAP GESTURE");
								if (nativeEvent.state === State.END && shapeSelected !== null) {
									hitTest(nativeEvent)
								}
							}}
						>
							<Animated.View style={{ height: "100%", width: "100%" }}>
								<TapGestureHandler
									ref={doubleTapRef}
									minPointers={1}
									numberOfTaps={1}
									maxPointers={1}
									numberOfTaps={2}
									enabled={shapeType !== "Pointer" || (shapeType === "Pointer" && shapeSelected !== null)}
								>
									<Animated.View style={{ flex: 1 }}>
										<PanGestureHandler
											ref={panRef}
											simultaneousHandlers={tapRef}
											style={{
												flexGrow: 1,
												zIndex: 10,
												alignItems: "center",
												justifyContent: "center",
												backgroundColor: "black",
											}}
											minDist={1}
											minPointers={1}
											maxPointers={1}
											enabled={shapeType !== "Pointer" || (shapeType === "Pointer" && shapeSelected !== null)}
											onGestureEvent={({ nativeEvent }) => {
												nativeEvent.state === State.ACTIVE && painting(nativeEvent);
											}}
											onHandlerStateChange={({ nativeEvent }) => {
												if (nativeEvent.state === State.END) {
													// setSaved(false);
													setLastPoint({ x: 0, y: 0, xper: 0, yper: 0 });
													setPointSelected({ id: null, x: 0, y: 0 });
													setRot(false);
													setResizing(false);
													endThePan();
												}
												nativeEvent.state === State.END && endPainting();
											}}
										>
											<Animated.View style={{ flex: 1 }}>
												<RotationGestureHandler
													ref={rotationRef}
													simultaneousHandlers={pinchRef}
													onGestureEvent={({ nativeEvent }) => {
														nativeEvent.state === State.ACTIVE && rotating(nativeEvent);
													}}
													enabled={false}
													// enabled={shapeType !== "Pointer" || (shapeType === "Pointer" && shapeSelected !== null)}
													onHandlerStateChange={({ nativeEvent }) => {
														if (nativeEvent.state === State.END) {
															setLastRotation(0);
															setLastPoint({ x: 0, y: 0, xper: 0, yper: 0 });
														}
													}}
												>
													<Animated.View style={{ flex: 1 }}>
														<PinchGestureHandler
															ref={pinchRef}
															simultaneousHandlers={rotationRef}
															onGestureEvent={({ nativeEvent }) => {
																nativeEvent.state === State.ACTIVE && onresizing(nativeEvent);
															}}
															// enabled={shapeType !== "Pointer" || (shapeType === "Pointer" && shapeSelected !== null)}
															enabled={false}
															onHandlerStateChange={({ nativeEvent }) => {
																if (nativeEvent.state === State.END) {
																	setLastScale(0);
																	setLastPoint({ x: 0, y: 0, xper: 0, yper: 0 });
																}
															}}
														>
															<Animated.View style={{ flex: 1, width: "100%", height: "100%" }}>
																<Svg
																	id="svgboard"
																	ref={svgRef}
																	viewBox={`0 0 ${canvasDimensions.width} ${canvasDimensions.height}`}
																	style={{
																		zIndex: 10,
																		position: "absolute",
																		backgroundColor: "transparent",
																	}}
																	width={canvasDimensions.width}
																	height={canvasDimensions.height}
																	preserveAspectRatio=""
																>
																	{shapes.length > 0
																		? shapes.map((shape, i) => {
																			return (
																				<MemorizedShape
																					key={i}
																					i={i}
																					shape={shape}
																					x={shape.x}
																					y={shape.y}
																					xper={shape.xper}
																					yper={shape.yper}
																					xper2={shape.xper2}
																					yper2={shape.yper2}
																					height={shape.height}
																					width={shape.width}
																					stroke={shape.stroke}
																					strokeWidth={shape.strokeWidth}
																					color={shape.color}
																					rotation={shape.rotation}
																					opacity={shape.opacity}
																					scale={shape.scale}
																					scaleX={shape.scaleX}
																					points={shape.points}
																					tool={shape.tool}
																					text={shape.text}
																					textAlign={shape.textAlign}
																					size={shape.size}
																					filled={shape.filled}
																					markerStart={shape.markerStart}
																					markerEnd={shape.markerEnd}
																					strokeDasharray={shape.strokeDasharray}
																					shapeSelected={shapeSelected}
																					toolSelected={toolSelected}
																					canvasDimensions={canvasDimensions}
																					pointSelected={pointSelected}
																					pressCallback={(ev) => {
																						// ev.stopPropagation()
																						!paint && clickCallback(i, ev)
																					}}
																					rotateCallback={() => setRot(true)}
																					resizeCallback={(ev) => setResizing(ev)}
																					selectPointCallback={(ev) => setPointSelected(ev)}
																					longPressCallback={() => longPressCallback(i)}
																				/>
																			);
																		})
																		: null}
																</Svg>

																{/* {ratio !== 0 ? ( */}
																{/* <NativeImage ref={pageRef} source={{ uri }} style={{ flex: 1 }} /> */}
																<View>
																	<Document
																		renderAnnotationLayer={false}
																		options={options}
																		ref={pageRef}
																		file={`https://api.ingebook.com/ib/IB_Browser;jsessionid=${jsessionid}?libro=${id}&pagina=${page}&extras=0`}
																		onLoadSuccess={handleLoadSuccess}
																		loading={
																			<View style={{ flex: 1, width: canvasDimensions.width, alignItems: "center", justifyContent: "center" }}>
																				<ActivityIndicator size="large" color="red" />
																			</View>
																		}
																	>
																		<Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={1} width={canvasDimensions.width} />
																	</Document>
																</View>
																{/* ) : (
																 	<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
																 		<ActivityIndicator />
																 	</View>
																 )} */}
															</Animated.View>
														</PinchGestureHandler>
													</Animated.View>
												</RotationGestureHandler>
											</Animated.View>
										</PanGestureHandler>
									</Animated.View>
								</TapGestureHandler>
							</Animated.View>
						</TapGestureHandler>
					</View>
				</ScrollView>

				<View
					style={{
						position: "absolute",
						bottom: insets.bottom ? insets.bottom : 20,
						backgroundColor: "rgba(0,0,0,.8)",
						flexDirection: "row",
						borderRadius: 25,
						paddingHorizontal: 15,
						height: 50,
						alignItems: "center",
						justifyContent: "center",
						width: 140,
						left: w.width / 2 - 70,
					}}
				>
					<TouchableOpacity
						onPress={() => {
							if (saved) {
								changePageCallback(page - 1);
							} else {
								let goBack = confirm("¿Está seguro que desea cambiar de página sin guardar?");
								if (goBack) {
									// closeCallback(false);
									changePageCallback(page - 1);
								}
							}
						}}
						style={{
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<MaterialCommunityIcons name="page-previous-outline" size={28} color={"white"} />
					</TouchableOpacity>
					<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
						<BoldText style={{ color: "white" }}>{page}</BoldText>
					</View>
					<TouchableOpacity
						onPress={() => {
							if (saved) {
								changePageCallback(page + 1);
							} else {
								let goBack = confirm("¿Está seguro que desea cambiar de página sin guardar?");
								if (goBack) {
									// closeCallback(false);
									changePageCallback(page + 1);
								}
							}
						}}
						style={{
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<MaterialCommunityIcons name="page-next-outline" size={28} color={"white"} />
					</TouchableOpacity>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: "100%",
		backgroundColor: "white",
		alignItems: "center",
	},
	modalView: {
		backgroundColor: "white",
		padding: 10,
		borderRadius: 4,
		minHeight: 150,
		width: "90%",
	},
	contentContainer: {
		paddingHorizontal: 10,
		marginBottom: 20,
	},
	toolButton: {
		height: 45,
		borderBottomWidth: 1,
		borderBottomColor: "gainsboro",
		flexDirection: "row",
		alignItems: "center",
	},
	rightBar: {
		zIndex: 22,
		width: 50,
		padding: 10,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
		height: "100%",
	},
	paintManu: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 200,
		width: 200,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	button: {
		width: 40,
		height: 40,
		alignItems: "center",
		justifyContent: "center",
	},
	menu_img: {
		width: 20,
		height: 20,
		marginTop: 35,
		justifyContent: "center",
		alignItems: "center",
	},
	book_img: {
		width: 165,
		height: 225,
		zIndex: 10,
		resizeMode: "cover",
	},
	bookback_img: {
		position: "absolute",
		width: 230,
		top: 40,
		height: 270,
		zIndex: 0,
	},
	bookCard: {
		width: windowWidth / 2.2,
		paddingHorizontal: 10,
	},
	wrapper: {
		height: windowHeight / 3,
		padding: 10,
	},
	slide: {
		height: 300,
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	subtitle: {
		marginVertical: 5,
		fontSize: 16,
		fontWeight: "bold",
		opacity: 0.6,
	},
	description: {
		marginVertical: 5,
		fontSize: 14,
		opacity: 0.6,
		borderBottomWidth: 1,
		borderBottomColor: "black",
	},
	category: {
		marginTop: 10,
		fontSize: 16,
		fontWeight: "bold",
		opacity: 0.4,
	},
	author: {
		fontSize: 16,
		marginTop: 10,
	},
	bookCover: {
		height: windowWidth / 1.8,
		width: "100%",
		marginBottom: 7,
		backgroundColor: "#f2f2f2",
	},
	bookTitle: {
		fontSize: 14,
	},
	separator: {
		marginVertical: 30,
		height: 1,
		width: "80%",
	},
	headerSection: {
		flexDirection: "row",
		paddingHorizontal: 10,
		paddingVertical: 5,
		justifyContent: "space-between",
		alignItems: "center",
	},
	sectionHeaderButton: {
		height: 40,
		paddingHorizontal: 10,
		alignItems: "center",
		justifyContent: "center",
	},
	headerContainer: {
		width: "95%",
		marginBottom: 20,
		marginTop: 10,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 0,
		marginLeft: "2.5%",
		borderBottomWidth: 2,
		borderBottomColor: "#e6e6e6",
	},
	buttonsRow: {
		flexDirection: "row",
	},
	buttonsTextContainer: {
		paddingHorizontal: 10,
	},
	buttonsText: {
		fontSize: 16,
	},
	genericBackground: {
		padding: 10,
		backgroundColor: "#fafafa",
		borderRadius: 5,
	},

	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
		borderRadius: 15,
	},
});

const ShapeItem = ({
	shape,
	x,
	y,
	xper,
	yper,
	xper2,
	yper2,
	width,
	height,
	points,
	color,
	stroke,
	strokeWidth,
	strokeDasharray,
	rotation,
	opacity,
	scale,
	scaleX,
	markerStart,
	markerEnd,
	shapeSelected,
	i,
	text,
	size,
	tool,
	filled,
	pointSelected,
	pressCallback,
	rotateCallback,
	resizeCallback,
	selectPointCallback,
	toolSelected,
	canvasDimensions,
	longPressCallback,
	textAlign,
}) => {
	const w = useWindowDimensions();
	if (
		shape.type === "AnimatedImage" ||
		shape.type === "Draw" ||
		shape.type === "Square" ||
		shape.type === "Circle" ||
		shape.type === "CustomImage" ||
		shape.type === "Text" ||
		shape.type === "Object"
	) {
		let pupu, lineGenerator, firstPoint, distance;
		const ref = useRef();

		const absoluteHeight = Math.abs(height * canvasDimensions.height) / 100;
		const absoluteWidth = Math.abs(width * canvasDimensions.width) / 100;
		const absoluteX = (xper * canvasDimensions.width) / 100;
		const absoluteY = (yper * canvasDimensions.height) / 100;

		if (shape.type === "Draw") {
			lineGenerator = shaper.line().curve(shaper.curveCardinal.tension(0.5));
			const pp = shape.points.map((el) => [(el.x * canvasDimensions.width) / 100, (el.y * canvasDimensions.height) / 100]);
			firstPoint = [pp[0][0] + absoluteX, pp[0][1] + absoluteY];
			pupu = lineGenerator(pp);
			if (Platform.OS === "web") {
				const d = document.getElementById(shape.id);
				distance = d ? d.getTotalLength() / (canvasDimensions.width / 130) : 0;
			} else {
				distance = ref.current ? ref.current?.getTotalLength() / (canvasDimensions.width / 130) : 0;
			}
		}
		return [
			<G
				key={i}
				x={absoluteX}
				y={absoluteY}
				originX={absoluteWidth / 2}
				originY={absoluteHeight / 2}
				width={Math.abs(absoluteWidth)}
				height={Math.abs(absoluteHeight)}
				scale={scale}
				rotation={rotation}
				style={{ cursor: "Pointer" }}
				// onClick={(toolSelected === null && shapeSelected !== i) ? pressCallback : null}
				// onPress={toolSelected === null ? pressCallback : null}
				onMouseDown={(toolSelected === null && shapeSelected !== i) ? pressCallback : null}
			// onLongPress={longPressCallback}
			>
				<G x={0} y={0} originX={absoluteWidth / 2} originY={absoluteHeight / 2} width="100%" height="100%" opacity={opacity} scaleX={scaleX}>
					{shape.type === "Square" ? (
						<Rect
							width={absoluteWidth}
							height={absoluteHeight}
							x={0}
							y={0}
							fill={filled ? stroke : "transparent"}
							opacity={opacity}
							stroke={stroke}
							strokeWidth={strokeWidth}
							strokeDasharray={strokeDasharray}
						/>
					) : shape.type === "Circle" ? (
						<Ellipse
							cx={absoluteWidth / 2}
							cy={absoluteHeight / 2}
							rx={absoluteWidth / 2}
							ry={absoluteHeight / 2}
							fill={filled ? stroke : "transparent"}
							stroke={stroke}
							strokeWidth={strokeWidth}
							strokeDasharray={strokeDasharray}
						/>
					) : shape.type === "CustomImage" ? (
						<SImage x={0} y={0} width={absoluteWidth} height={absoluteHeight} preserveAspectRatio="xMidYMid slice" xlinkHref={shape.uri} />
					) : shape.type === "Draw" ? (
						<Path
							key={i}
							ref={ref}
							id={shape.id}
							d={pupu}
							fill="none"
							strokeWidth={strokeWidth}
							stroke={stroke}
							strokeDasharray={strokeDasharray}
							strokeLinecap={shape.strokeLinecap}
							markerStart={markerStart === "arrow" ? `url(#arrowback${i})` : markerStart === "dot" ? `url(#dotback${i})` : markerStart === "square" ? `url(#squareback${i})` : ""}
							markerEnd={markerEnd === "arrow" ? `url(#arrow${i})` : markerEnd === "dot" ? `url(#dot${i})` : markerEnd === "square" ? `url(#square${i})` : ""}
						/>
					) : shape.type === "Text" ? (
						// Platform.OS === "web" ? (
						<ForeignObject fill={filled ? stroke : "transparent"} x={0} y={0} height={absoluteHeight} width={absoluteWidth}>
							<View style={{ backgroundColor: filled ? stroke : "transparent", padding: 5, width: absoluteWidth, height: absoluteHeight }}>
								<DefaultText style={{ textAlign: textAlign, fontSize: `${size / 10}em`, color: filled ? (stroke === "white" ? "black" : "white") : stroke }}>{text}</DefaultText>
							</View>
						</ForeignObject>
					) : // ) : (
						// <G fill={filled ? stroke : "transparent"} width={"100%"} height={"100%"} x={0} y={0}>
						// 	{filled && <Rect fill={stroke} strokeWidth={1} x={0} y={0} width={absoluteWidth} height={absoluteHeight} />}
						// 	<SvgText>
						// 		<TSpan
						// 			y={(size || 0) + 3}
						// 			inlineSize={Math.abs(absoluteWidth) || 0}
						// 			fill={filled ? "black" : stroke}
						// 			fontSize={((size * 1080) / w.width) * 2}
						// 			textAnchor={textAlign === "left" ? "start" : textAlign === "center" ? "middle" : "end"}
						// 		>
						// 			{text}
						// 		</TSpan>
						// 	</SvgText>
						// </G>
						// )
						null}
				</G>

				{/* ACCESORIES AND EDITING ELEMENTS */}

				<Rect
					onClick={(ev) => {
						if (shapeSelected === i) {
							ev.stopPropagation()
						}
					}}
					fill="transparent"
					stroke={shapeSelected === i && shape.type !== "Line" ? "black" : "transparent"}
					strokeWidth={1 / shape.scale}
					width={absoluteWidth + 4}
					height={absoluteHeight + 4}
					x={-2}
					y={-2}
				/>

				{shapeSelected === i && Platform.OS === "web" && (shape.type === "Draw" || shape.type === "Circle") && (
					<Rect fill="transparent" stroke={"white"} width={absoluteWidth} height={absoluteHeight} x={0} y={0} />
				)}

				{shapeSelected === i &&
					(shape.type === "Circle" || shape.type === "CustomImage" || shape.type === "Text" || shape.type === "Square") && [
						<Rect
							key={"topLeft"}
							width={15 / shape.scale}
							height={15 / shape.scale}
							stroke="black"
							strokeWidth="1"
							x={-7 / shape.scale}
							y={-7 / shape.scale}
							// onPressIn={() => resizeCallback("topLeft")}
							onMouseDown={(ev) => {
								ev.stopPropagation()
								resizeCallback("topLeft")
							}}
							style={{ cursor: "nwse-resize", hitSlop: { top: 5, left: 5, bottom: 5, right: 5 } }}
						// hitSlop={{ top: 5, left: 5, bottom: 5, right: 5 }}
						/>,
						<Rect
							key={"bottomLeft"}
							width={15 / shape.scale}
							height={15 / shape.scale}
							stroke="black"
							strokeWidth="1"
							x={-7 / shape.scale}
							y={absoluteHeight - 7 / shape.scale}
							// onPressIn={() => resizeCallback("bottomLeft")}
							onMouseDown={(ev) => {
								ev.stopPropagation()
								resizeCallback("bottomLeft")
							}}
							style={{ cursor: "nesw-resize", hitSlop: { top: 5, left: 5, bottom: 5, right: 5 } }}
						// hitSlop={{ top: 5, left: 5, bottom: 5, right: 5 }}
						/>,
						<Rect
							key={"bottomRight"}
							width={15 / shape.scale}
							height={15 / shape.scale}
							stroke="black"
							strokeWidth="1"
							x={absoluteWidth - 7 / shape.scale}
							y={absoluteHeight - 7 / shape.scale}
							// onPressIn={() => resizeCallback("bottomRight")}
							onMouseDown={(ev) => {
								ev.stopPropagation()
								resizeCallback("bottomRight")
							}}
							style={{ cursor: "nwse-resize", hitSlop: { top: 5, left: 5, bottom: 5, right: 5 } }}
						// hitSlop={{ top: 5, left: 5, bottom: 5, right: 5 }}
						/>,
						<Rect
							key={"topRight"}
							width={15 / shape.scale}
							height={15 / shape.scale}
							stroke="black"
							strokeWidth="1"
							x={absoluteWidth - 7 / shape.scale}
							y={-7 / shape.scale}
							// onPressIn={() => resizeCallback("topRight")}
							onMouseDown={(ev) => {
								ev.stopPropagation()
								resizeCallback("topRight")
							}}
							style={{ cursor: "nesw-resize", hitSlop: { top: 5, left: 5, bottom: 5, right: 5 } }}
						// hitSlop={{ top: 5, left: 5, bottom: 5, right: 5 }}
						/>,
					]}
			</G>,
		];
	} else if (shape.type === "Line" || shape.type === "Highlight") {
		let distance;
		const ref = useRef();

		const absoluteX = (xper * canvasDimensions.width) / 100;
		const absoluteY = (yper * canvasDimensions.height) / 100;
		const absoluteX2 = (xper2 * canvasDimensions.width) / 100;
		const absoluteY2 = (yper2 * canvasDimensions.height) / 100;
		const absoluteWidth = absoluteX < absoluteX2 ? absoluteX - absoluteX2 : absoluteX2 - absoluteX;
		const absoluteHeight = absoluteY < absoluteY2 ? absoluteY - absoluteY2 : absoluteY2 - absoluteY;
		// const firstPoint = [absoluteX, absoluteY];
		if (Platform.OS === "web") {
			const d = document.getElementById(shape.id);
			// console.log(d);
			distance = d ? d.getTotalLength() / (canvasDimensions.width / 130) : 0;
		} else {
			distance = ref.current ? ref.current?.getTotalLength() / (canvasDimensions.width / 130) : 0;
		}
		// distance = ref.current ? ref.current?.getTotalLength() / (canvasDimensions.width / 130) : 0;
		return [
			<Line
				key={i}
				id={shape.id}
				ref={ref}
				x1={absoluteX}
				y1={absoluteY}
				x2={absoluteX2}
				y2={absoluteY2}
				opacity={opacity}
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeDasharray={strokeDasharray}
				markerStart={markerStart === "arrow" ? `url(#arrowback${i})` : markerStart === "dot" ? `url(#dotback${i})` : markerStart === "square" ? `url(#squareback${i})` : ""}
				markerEnd={markerEnd === "arrow" ? `url(#arrow${i})` : markerEnd === "dot" ? `url(#dot${i})` : markerEnd === "square" ? `url(#square${i})` : ""}
			/>,

			<Rect
				key={"interBox"}
				// onPress={toolSelected === null ? pressCallback : null}
				onClick={toolSelected === null ? pressCallback : null}
				fill="transparent"
				style={{ cursor: "pointer" }}
				stroke={"transparent"}
				strokeWidth={1 / shape.scale}
				x={absoluteX < absoluteX2 ? absoluteX - 7.5 : absoluteX2 - 7.5}
				y={absoluteY < absoluteY2 ? absoluteY - 7.5 : absoluteY2 - 7.5}
				width={Math.abs(absoluteWidth) + 15}
				height={Math.abs(absoluteHeight) + 15}
			/>,
			shapeSelected === i && absoluteX && (
				<Ellipse
					cx={absoluteX}
					cy={absoluteY}
					key={"point1"}
					rx={15}
					ry={15}
					strokeWidth="2"
					fill="transparent"
					stroke={pointSelected.id === 0 ? "orange" : "black"}
					style={{ cursor: "pointer" }}
					// onPressIn={() => {
					// 	selectPointCallback({ id: 0, x: absoluteX, y: absoluteY });
					// }}
					onMouseDown={() => {
						selectPointCallback({ id: 0, x: absoluteX, y: absoluteY });
					}}
				/>
			),
			shapeSelected === i && absoluteX2 && (
				<Ellipse
					cx={absoluteX2}
					cy={absoluteY2}
					key={"point2"}
					rx={15}
					ry={15}
					strokeWidth="2"
					fill="transparent"
					style={{ cursor: "pointer" }}
					// onPressIn={() => {
					// 	selectPointCallback({ id: 1, x: absoluteX2, y: absoluteY2 });
					// }}
					onMouseDown={() => {
						selectPointCallback({ id: 1, x: absoluteX2, y: absoluteY2 });
					}}
					stroke={pointSelected.id === 1 ? "orange" : "black"}
				/>
			),
		];
	} else if (shape.type === "Polygon") {
		const absolutePoints = points.map((point) => `${((point.x * canvasDimensions.width) / 100) * scaleX}, ${(point.y * canvasDimensions.height) / 100}`).join(" ");
		return (
			<G key={i} rotation={rotation} scale={scale} style={{ cursor: "Pointer" }}>
				<Polygon
					points={absolutePoints}
					fill={color}
					stroke={stroke}
					strokeWidth={strokeWidth}
					strokeDasharray={strokeDasharray}
					opacity={opacity}
					fillRule="evenodd"
					// onPress={toolSelected === null ? pressCallback : null}
					onMouseDown={toolSelected === null ? pressCallback : null}
				/>
				{shapeSelected === i &&
					points.map((point, i) => {
						const absoluteX = (point.x * canvasDimensions.width) / 100;
						const absoluteY = (point.y * canvasDimensions.height) / 100;
						return (
							<Circle
								key={i}
								cx={absoluteX}
								cy={absoluteY}
								r={20}
								onMouseDown={() => {
									selectPointCallback({ id: i, x: point.x, y: point.y });
								}}
								fill="transparent"
								stroke={pointSelected.id === i ? "orange" : "white"}
								strokeWidth="1"
							/>
						);
					})}
			</G>
		);
	}
};

const arePropsEqual = (prevProps, nextProps) => {
	const change =
		prevProps.shapeSelected === nextProps.shapeSelected &&
		prevProps.shapeType === nextProps.shapeType &&
		prevProps.x === nextProps.x &&
		prevProps.y === nextProps.y &&
		prevProps.xper === nextProps.xper &&
		prevProps.yper === nextProps.yper &&
		prevProps.xper2 === nextProps.xper2 &&
		prevProps.yper2 === nextProps.yper2 &&
		prevProps.width === nextProps.width &&
		prevProps.height === nextProps.height &&
		prevProps.opacity === nextProps.opacity &&
		prevProps.rotation === nextProps.rotation &&
		prevProps.scale === nextProps.scale &&
		prevProps.scaleX === nextProps.scaleX &&
		prevProps.color === nextProps.color &&
		prevProps.filled === nextProps.filled &&
		prevProps.stroke === nextProps.stroke &&
		prevProps.strokeWidth === nextProps.strokeWidth &&
		prevProps.strokeDasharray === nextProps.strokeDasharray &&
		prevProps.markerStart === nextProps.markerStart &&
		prevProps.pointSelected === nextProps.pointSelected &&
		prevProps.tool === nextProps.tool &&
		prevProps.toolSelected === nextProps.toolSelected &&
		prevProps.points === nextProps.points &&
		prevProps.text === nextProps.text &&
		prevProps.textAlign === nextProps.textAlign &&
		prevProps.size === nextProps.size &&
		prevProps.canvasDimensions === nextProps.canvasDimensions &&
		prevProps.markerEnd === nextProps.markerEnd;

	return change;
};

const MemorizedShape = React.memo(ShapeItem, arePropsEqual);
