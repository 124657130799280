import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { ActivityIndicator, FlatList, Image, ImageBackground, Modal, ScrollView, StyleSheet, TouchableOpacity, View, useWindowDimensions } from "react-native";

import Alert from "../components/Alert";

import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useEffect, useState } from "react";
import { TextInput } from "react-native-gesture-handler";
import { Portal } from "react-native-portalize";
import RenderHTML from "react-native-render-html";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { BoldText, DefaultText, SemiBoldText } from "../components/StyledText";
import SearchBar from "./SearchBar";

export default function BookReaderTools({
	id,
	notas,
	tab,
	page,
	bookmarks,
	newNoteCallback,
	openVideoCallback,
	data,
	pageRatio,
	setMainMenu,
	pageCallback,
	jsession,
	refetchNotesCallback,
	goToPage,
	setBookmarks,
	itemLayout,
	setNoteDetails,
	setNoteModal,
	orders,
	invitado,
}) {
	const w = useWindowDimensions();
	const insets = useSafeAreaInsets();
	const [search, setSearch] = useState("");
	const [replacedWord, setReplacedWord] = useState("");
	const [searchType, setSearchType] = useState(1);
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState(false);
	const [filterNotes, setFilterNotes] = useState("");
	const [thumbnailsFilter, setThumbnailsFilter] = useState(0);
	const [thumbnailsSearchFilter, setThumbnailsSearchFilter] = useState(null);
	const [sort, setSort] = useState("0");
	const [filterFav, setFilterFav] = useState(false);

	const searchInBook = async () => {
		setSearching(true);
		// const params = new URLSearchParams({
		// 	libro: id,
		// 	exacta: "1",
		// 	cod_primaria: "1000193",
		// 	texto: escape(search.trim()),
		// }).toString();

		// const url = `https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones?cod_primaria=1000193&accion=2&codigo_libro=${id}&app=1&pagina=${page}&nota=${escape(
		// 		form.title.trim()
		// 	)}&descripcion_nota=${escape(descHTML)}&nota_publica=${shareNote ? "1" : "0"}`;

		const url = `https://api.ingebook.com:443/ib/NPcd/IB_APP_Sch_Browser;jsessionid=${jsession}?libro=${id}&exacta=1&cod_primaria=1000193&texto=${escape(search.trim())}`;
		// const url = new URL(uri);
		// url.search = params;
		const p = await axios.get(url, {
			headers: {
				pragma: "no-cache",
				"cache-control": "no-cache",
			},
		});
		const results = p?.data?.Resultado ? p?.data?.Resultado[1]?.schbook?.res || [] : [];
		setReplacedWord(search);
		setSearchResults(results);
		setSearching(false);
	};

	const downloadFile = async (item) => {
		const url =
			item.descarga.split("IB_Escritorio_Visualizar_Exe")[0] + `IB_Escritorio_Visualizar_Exe;jsessionid=${jsession}` + item.descarga.split("IB_Escritorio_Visualizar_Exe")[1];
		axios
			.get(url.replace("cod_primaria=", "cod_primaria=1000193"), {
				responseType: "blob",
			})
			.then((res) => {
				fileDownload(res.data, item.desc);
			});
	};

	const addBookmark = async (page: string) => {
		const isFav = bookmarks.map((el) => el.pag).includes(typeof page === "string" ? page : JSON.stringify(page));

		isFav ? setBookmarks([...bookmarks.filter((el) => JSON.stringify(el.pag) !== JSON.stringify(page))]) : setBookmarks([...bookmarks, { pag: JSON.stringify(page) }]);

		const o = await axios
			.get(`https://api.ingebook.com/ib/NPcd/IB_APP_Marcadores;jsessionid=${jsession}?cod_primaria=1000193&accion=${isFav ? "3" : "2"}&codigo_libro=${id}&app=1&pagina=${page}`, {
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
				},
			})
			.then((res) => res.data);
	};

	function clamp(value, min, max) {
		return Math.min(Math.max(value, min), max);
	}

	return (
		<>
			<View style={{ height: w.height - 55 }}>
				{tab === 0 ? (
					<ScrollView style={{ flexGrow: 1 }}>
						<View style={{ flexDirection: "row", padding: 10 }}>
							<View style={{ flex: 1, marginRight: 10 }}>
								<SearchBar
									pressButton={() => {
										setSearch("");
										setSearchType(0);
										setSearchResults(false);
									}}
									withFilters={false}
									search={search}
									setSearch={(ev: string) => {
										setSearch(ev);
									}}
									onSubmitEditing={searchInBook}
								/>
							</View>
							<TouchableOpacity
								disabled={search === ""}
								style={{
									opacity: search === "" ? 0.5 : 1,
									backgroundColor: "red",
									height: 40,
									borderRadius: 5,
									alignItems: "center",
									justifyContent: "center",
									paddingHorizontal: 20,
								}}
								onPress={searchInBook}
							>
								<BoldText style={{ color: "white" }}>{searching ? "Buscando..." : "Buscar"}</BoldText>
							</TouchableOpacity>
						</View>
						<View style={{ padding: 10, paddingTop: 0 }}>
							{searching ? (
								<View style={{ flex: 1, minHeight: w.height / 1.5, alignItems: "center", justifyContent: "center" }}>
									<ActivityIndicator color="red" />
								</View>
							) : searchResults === false ? (
								<View style={{ backgroundColor: "#f2f2f2", width: "100%", paddingVertical: 30, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
									<DefaultText style={{ fontSize: 14 }}>Escribe tu búsqueda</DefaultText>
								</View>
							) : searchResults?.length === 0 ? (
								<View style={{ backgroundColor: "#f2f2f2", width: "100%", paddingVertical: 30, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
									<DefaultText style={{ fontSize: 14 }}>No hay resultados para tu búsqueda</DefaultText>
								</View>
							) : (
								searchResults?.map((result, i) => (
									<TouchableOpacity
										key={i}
										onPress={() => {
											pageCallback(result.pag);
										}}
										style={[styles.shadow, { flexDirection: "row", borderRadius: 10, padding: 10, paddingRight: 20, marginBottom: 10, backgroundColor: "#fff" }]}
									>
										<Image
											style={{ width: 70, resizeMode: "contain", height: "100%" }}
											source={{
												uri: `https://www.ingebook.com/ib/pimg/IB_Libros_App/${data.dir}/thumb/${result.pag}.jpg`,
											}}
										/>
										<View style={{ flex: 1, paddingLeft: 10 }}>
											<HighlightedResult text={result.parr} search={replacedWord} />
										</View>
										<View style={{ backgroundColor: "#f3f3f3", position: "absolute", top: 0, right: 0, borderRadius: 5, marginLeft: 10, paddingHorizontal: 10 }}>
											<DefaultText style={[styles.description, { fontSize: 10 }]}>{result.pag}</DefaultText>
										</View>
									</TouchableOpacity>
								))
							)}
						</View>
					</ScrollView>
				) : tab === 1 ? (
					<ScrollView contentContainerStyle={{ padding: 10, width: "100%" }}>
						{data.indice.map((item, index) => (
							<View key={index}>
								<TouchableOpacity
									onPress={() => {
										setMainMenu(false);
										pageCallback(parseInt(item.paginanivel1));
										// viewerRef.current.scrollToIndex({ index: parseInt(item.paginanivel1), animated: false });
									}}
									style={{ marginTop: -1, flexDirection: "row", alignItems: "center", paddingHorizontal: 5, borderRadius: 5, borderBottomColor: "gray", paddingVertical: 5 }}
								>
									<View style={{ flex: 1 }}>
										<DefaultText style={styles.description}>{item.descnivel1}</DefaultText>
									</View>
									<View style={{ backgroundColor: "#f3f3f3", borderRadius: 5, marginLeft: 10, paddingHorizontal: 10 }}>
										<DefaultText style={[styles.description, { fontSize: 10 }]}>{item.paginanivel1}</DefaultText>
									</View>
								</TouchableOpacity>
								<View style={{ paddingLeft: 15 }}>
									{item?.nivel2?.map((item2, index2) => (
										<View style={{ backgroundColor: "#f8f8f8", width: "100%" }} key={index2}>
											<TouchableOpacity
												onPress={() => {
													setMainMenu(false);
													pageCallback(parseInt(item2.paginanivel2));
													// viewerRef.current.scrollToIndex({ index: parseInt(item.paginanivel1), animated: false });
												}}
												style={{ borderBottomColor: "gray", flexDirection: "row", paddingLeft: 10, paddingVertical: 5, width: "100%", paddingRight: 10, alignItems: "center" }}
											>
												<View style={{ flex: 1 }}>
													<DefaultText style={styles.description}>{item2.descnivel2}</DefaultText>
												</View>
												<View style={{ backgroundColor: "white", borderRadius: 5, marginLeft: 10, paddingHorizontal: 10 }}>
													<DefaultText style={[styles.description, { fontSize: 8 }]}>{item2.paginanivel2}</DefaultText>
												</View>
											</TouchableOpacity>
											<View style={{ paddingLeft: 15 }}>
												{item2?.nivel3?.map((item3, index3) => (
													<View style={{ width: "100%", backgroundColor: "#f8f8f8" }} key={index3}>
														<TouchableOpacity
															onPress={() => {
																setMainMenu(false);
																pageCallback(parseInt(item3.paginanivel3));
																// viewerRef.current.scrollToIndex({ index: parseInt(item.paginanivel1), animated: false });
															}}
															style={{ marginLeft: 10, borderBottomColor: "gray", flexDirection: "row", paddingVertical: 5, width: "100%", paddingRight: 10, alignItems: "center" }}
														>
															<View style={{ flex: 1 }}>
																<DefaultText style={styles.description}>{item3.descnivel3}</DefaultText>
															</View>
															<View style={{ backgroundColor: "white", borderRadius: 5, marginHorizontal: 10, paddingHorizontal: 10 }}>
																<DefaultText style={[styles.description, { fontSize: 8 }]}>{item3.paginanivel3}</DefaultText>
															</View>
														</TouchableOpacity>
													</View>
												))}
											</View>
										</View>
									))}
								</View>
							</View>
						))}
					</ScrollView>
				) : tab === 2 ? (
					<FlatList
						contentContainerStyle={{
							paddingBottom: insets.bottom + 20,
							maxWidth: 650,
							alignSelf: "center",
							width: "100%",
						}}
						data={[...Array(parseInt(data.pag)).keys()]
							.filter((_, index) => (thumbnailsSearchFilter === null ? true : index + 1 === parseInt(thumbnailsSearchFilter)))
							.filter((_, index) =>
								thumbnailsFilter === 0
									? true
									: thumbnailsFilter === 1
										? notas.find((el) => parseInt(el.pag) === index + 1 && el.nota_propia === "1")
										: thumbnailsFilter === 2
											? notas.find((el) => parseInt(el.pag) === index + 1 && el.publica === "1")
											: thumbnailsFilter === 3
												? data?.videos?.reduce((acc, cur) => [...acc, parseInt(cur.pagina_desde), parseInt(cur.pagina_hasta)], []).find((el) => el === index + 1)
												: // notas.find((el) => parseInt(el.pag) === index + 1 && el.video === "1")
												thumbnailsFilter === 4
													? bookmarks.find((el) => parseInt(el.pag) === index + 1)
													: false
							)}
						numColumns={3}
						keyExtractor={(item) => item.toString()}
						stickyHeaderIndices={[0]}
						getItemLayout={(_, index) => ({
							length: (clamp(w.width, 100, 650) / 3) * pageRatio,
							offset: index * ((clamp(w.width, 100, 650) / 3) * pageRatio),
							index,
						})}
						ListHeaderComponent={() => (
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									backgroundColor: "#f3f3f3",
									padding: 5,
									marginHorizontal: 10,
									marginVertical: 5,
									height: 35,
									borderRadius: 7,
								}}
							>
								<TouchableOpacity
									onPress={() => {
										setThumbnailsSearchFilter(null);
										setThumbnailsFilter(0);
									}}
									style={{
										alignItems: "center",
										justifyContent: "center",
										paddingHorizontal: 25,
										borderRadius: 4,
										backgroundColor: thumbnailsFilter === 0 && thumbnailsSearchFilter === null ? "white" : "transparent",
										height: "100%",
									}}
								>
									<DefaultText style={{ fontSize: 12 }}>Ver Todo</DefaultText>
								</TouchableOpacity>
								<div title="Notas Privadas">
									<TouchableOpacity
										onPress={() => {
											setThumbnailsSearchFilter(null);
											setThumbnailsFilter(1);
										}}
										style={{
											backgroundColor: thumbnailsFilter === 1 ? "white" : "transparent",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 10,
											borderRadius: 4,
											flex: 1,
											height: "100%",
										}}
									>
										{/* <Ionicons name="document-outline" size={20} color="black" /> */}
										<Image source={require("../assets/icons/notaprivada.png")} style={{ width: 20, height: 20 }} />
									</TouchableOpacity>
								</div>
								<div title="Notas Compartidas">
									<TouchableOpacity
										onPress={() => {
											setThumbnailsSearchFilter(null);
											setThumbnailsFilter(2);
										}}
										style={{
											backgroundColor: thumbnailsFilter === 2 ? "white" : "transparent",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 10,
											borderRadius: 4,
											flex: 1,
											height: "100%",
										}}
									>
										{/* <Ionicons name="share-social" size={20} color="black" /> */}
										<Image source={require("../assets/icons/notacompartida.png")} style={{ width: 20, height: 20 }} />
									</TouchableOpacity>
								</div>
								<div title="Videoaulas">
									<TouchableOpacity
										onPress={() => {
											setThumbnailsSearchFilter(null);
											setThumbnailsFilter(3);
										}}
										style={{
											backgroundColor: thumbnailsFilter === 3 ? "white" : "transparent",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 10,
											borderRadius: 4,
											flex: 1,
											height: "100%",
										}}
									>
										{/* <Ionicons name="videocam-outline" size={20} color="black" /> */}
										<Image source={require("../assets/icons/video.png")} style={{ width: 20, height: 20 }} />
									</TouchableOpacity>
								</div>
								<div title="Marcadores">
									<TouchableOpacity
										onPress={() => {
											setThumbnailsSearchFilter(null);
											setThumbnailsFilter(4);
										}}
										style={{
											backgroundColor: thumbnailsFilter === 4 ? "white" : "transparent",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 10,
											borderRadius: 4,
											flex: 1,
											height: "100%",
										}}
									>
										<Ionicons name="md-bookmark-outline" size={20} color="black" />
									</TouchableOpacity>
								</div>
								<div title="Búsqueda">
									<TouchableOpacity
										onPress={() => {
											setThumbnailsFilter(0);
											Alert.prompt("Número de Página", "", (p) => setThumbnailsSearchFilter(p), "plain-text", "", "number-pad");
										}}
										style={{
											backgroundColor: thumbnailsSearchFilter !== null ? "white" : "transparent",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 10,
											borderRadius: 4,
											flex: 1,
											height: "100%",
										}}
									>
										<Ionicons name="search" size={20} color="black" />
									</TouchableOpacity>
								</div>
							</View>
						)}
						ListEmptyComponent={() => (
							<View
								style={{
									backgroundColor: "#f2f2f2",
									width: "95%",
									margin: "2.5%",
									paddingVertical: 30,
									borderRadius: 10,
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<DefaultText style={{ fontSize: 14 }}>{"No hay Miniaturas"}</DefaultText>
							</View>
						)}
						renderItem={({ item }) => (
							<PageMiniature
								removeCallback={() => {
									addBookmark(item.pag);
								}}
								tit={data.tit}
								dir={data.dir}
								showBookmark={bookmarks.find((el) => parseInt(el.pag) === item + 1)}
								showPrivateNotes={notas.find((el) => parseInt(el.pag) === item + 1 && el.nota_propia === "1")}
								showPublicNotes={notas.find((el) => parseInt(el.pag) === item + 1 && el.publica === "1")}
								showVideo={data?.videos?.some((el) => parseInt(el.pagina_desde) <= item + 1 && parseInt(el.pagina_hasta) >= item + 1)}
								item={item}
								isbn={data.isbn}
								pageCallback={() => {
									goToPage(parseInt(item) + 1, itemLayout);
								}}
								width={380 / 3}
								pageRatio={pageRatio || 1}
							/>
						)}
					/>
				) : tab === 3 ? (
					<ScrollView contentContainerStyle={{ padding: 10 }}>
						{invitado === "1" ? (
							<View style={{ borderRadius: 7, alignItems: "center", justifyContent: "center", padding: 20, backgroundColor: "#f3f3f3" }}>
								<DefaultText style={{ fontSize: 14 }}>Estas accediendo como usuario invitado, para crear y visualizar notas es necesario iniciar sesión en Ingebook</DefaultText>
								<View style={{ width: "100%", marginTop: 20, alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
									<TouchableOpacity
										onPress={() => {
											window.parent.postMessage(`https://www.ingebook.com/ib/NPcd/IB_Browser_Pre?codigo_libro=${id}`, "*");
										}}
										style={{
											backgroundColor: "gainsboro",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 20,
											paddingVertical: 10,
											borderRadius: 6,
											flex: 1,
											height: "100%",
											marginRight: 15,
										}}
									>
										<SemiBoldText style={{ fontSize: 14, color: "black" }}>Acceder</SemiBoldText>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											window.parent.postMessage("https://www.ingebook.com/ib/NPcd/IB_Registro", "*");
										}}
										style={{
											backgroundColor: "red",
											alignItems: "center",
											justifyContent: "center",
											paddingHorizontal: 20,
											paddingVertical: 10,
											borderRadius: 6,
											flex: 1,
											height: "100%",
										}}
									>
										<SemiBoldText style={{ fontSize: 14, color: "white" }}>Registrarme</SemiBoldText>
									</TouchableOpacity>
								</View>
							</View>
						) : (
							<>
								<View style={{ alignItems: "flex-end", marginBottom: 10 }}>
									<View style={{ flex: 1, width: "100%" }}>
										<View style={{ flexDirection: "row", alignItems: "center", width: "100%", marginBottom: 5 }}>
											<View style={{ flex: 1, paddingLeft: 15 }}>
												<SemiBoldText>Buscar en: </SemiBoldText>
											</View>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													backgroundColor: "#f3f3f3",
													padding: 5,
													marginHorizontal: 10,
													marginVertical: 5,
													height: 35,
													borderRadius: 7,
													alignSelf: "center",
												}}
											>
												<TouchableOpacity
													onPress={() => setSearchType(0)}
													style={{
														alignItems: "center",
														justifyContent: "center",
														paddingHorizontal: 25,
														borderRadius: 4,
														backgroundColor: searchType === 0 ? "white" : "transparent",
														height: "100%",
													}}
												>
													<DefaultText style={{ fontSize: 12 }}>Todo</DefaultText>
												</TouchableOpacity>
												<TouchableOpacity
													onPress={() => setSearchType(1)}
													style={{
														alignItems: "center",
														justifyContent: "center",
														paddingHorizontal: 25,
														borderRadius: 4,
														backgroundColor: searchType === 1 ? "white" : "transparent",
														height: "100%",
													}}
												>
													<DefaultText style={{ fontSize: 12 }}>Página</DefaultText>
												</TouchableOpacity>
											</View>
										</View>

										<View style={{ flex: 1, marginRight: 10, width: "100%" }}>
											<SearchBar
												pressButton={() => {
													setFilterNotes("");
												}}
												withFilters={false}
												search={filterNotes}
												setSearch={setFilterNotes}
											/>
										</View>
									</View>
								</View>
								{notas
									.filter((el) => (searchType === 0 ? true : parseInt(page) === parseInt(el.pag)))
									.filter(
										(el) =>
											includesString(el.autor, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
											includesString(el.nota, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
											includesString(el.contenido, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
									)
									.filter((el) => el.nota_propia === "1").length === 0 ? (
									<View style={{ backgroundColor: "#f2f2f2", width: "100%", paddingVertical: 30, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
										<DefaultText style={{ fontSize: 14 }}>No hay Notas</DefaultText>
									</View>
								) : (
									notas
										.filter((el) => (searchType === 0 ? true : parseInt(page) === parseInt(el.pag)))
										.filter(
											(el) =>
												includesString(el.autor, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
												includesString(el.nota, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
												includesString(el.contenido, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
										)
										.filter((el) => el.nota_propia === "1")
										.map((item, index) => (
											<Note
												key={index}
												data={data}
												item={item}
												index={index}
												jsessionid={jsession}
												pageRatio={pageRatio}
												refetchNotesCallback={refetchNotesCallback}
												goToPage={() => goToPage(parseInt(item.pag), itemLayout)}
												clickNoteCallback={() => {
													setNoteDetails(item);
													setNoteModal(true);
												}}
											/>
										))
								)}
							</>
						)}
					</ScrollView>
				) : tab === 4 ? (
					<ScrollView contentContainerStyle={{ padding: 10 }}>
						<View style={{ alignItems: "flex-end", marginBottom: 10 }}>
							<View style={{ flex: 1, width: "100%" }}>
								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", marginBottom: 5 }}>
									<View style={{ flex: 1, paddingLeft: 15 }}>
										<SemiBoldText>Buscar en: </SemiBoldText>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											backgroundColor: "#f3f3f3",
											padding: 5,
											marginHorizontal: 10,
											marginVertical: 5,
											height: 35,
											borderRadius: 7,
											alignSelf: "center",
										}}
									>
										<TouchableOpacity
											onPress={() => setSearchType(0)}
											style={{
												alignItems: "center",
												justifyContent: "center",
												paddingHorizontal: 25,
												borderRadius: 4,
												backgroundColor: searchType === 0 ? "white" : "transparent",
												height: "100%",
											}}
										>
											<DefaultText style={{ fontSize: 12 }}>Todo</DefaultText>
										</TouchableOpacity>
										<TouchableOpacity
											onPress={() => setSearchType(1)}
											style={{
												alignItems: "center",
												justifyContent: "center",
												paddingHorizontal: 25,
												borderRadius: 4,
												backgroundColor: searchType === 1 ? "white" : "transparent",
												height: "100%",
											}}
										>
											<DefaultText style={{ fontSize: 12 }}>Página</DefaultText>
										</TouchableOpacity>
									</View>
								</View>

								<View style={{ flex: 1, marginRight: 10, width: "100%" }}>
									<SearchBar
										pressButton={() => {
											setFilterNotes("");
										}}
										withFilters={false}
										search={filterNotes}
										setSearch={setFilterNotes}
									/>
								</View>

								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", marginTop: 5 }}>
									<View style={{ flex: 1, paddingLeft: 15 }}>
										<SemiBoldText>
											(
											{notas
												.filter((el) => (searchType === 0 ? true : parseInt(page) === parseInt(el.pag)))
												.filter(
													(el) =>
														includesString(el.autor, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
														includesString(el.nota, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
														includesString(el.contenido, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
												)
												.filter((el) => el.publica === "1")?.length || 0}
											) Resultados
										</SemiBoldText>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											backgroundColor: "#f3f3f3",
											padding: 5,
											marginHorizontal: 10,
											marginVertical: 5,
											height: 35,
											borderRadius: 7,
											alignSelf: "center",
											flex: 1,
										}}
									>
										<select placeholder="" style={{ padding: "10px 10px" }} onChange={(ev) => setSort(ev.target.value)}>
											{orders?.map((o, i) => {
												return (
													<option style={{ fontSize: 14 }} key={i} value={o.ordenar}>
														{o.texto_ordenar}
													</option>
												);
											})}
										</select>
										<Ionicons name="caret-down-outline" size={10} color="gray" style={{ marginRight: 5, position: "absolute", right: 5, pointerEvents: "none" }} />
									</View>
									<TouchableOpacity
										onPress={() => setFilterFav(!filterFav)}
										style={{
											alignItems: "center",
											justifyContent: "center",
											marginRight: 10,
											height: "100%",
										}}
									>
										<Ionicons name={filterFav ? "md-star" : "md-star-outline"} size={25} color={filterFav ? "orange" : "gray"} />
									</TouchableOpacity>
								</View>
							</View>
						</View>
						{/* {console.log(sort)} */}
						{notas
							.filter((el) => (filterFav ? el.usuario_favorito === "1" : true))
							.filter((el) => (searchType === 0 ? true : parseInt(page) === parseInt(el.pag)))
							.filter(
								(el) =>
									includesString(el.autor, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
									includesString(el.nota, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
									includesString(el.contenido, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
							)
							.filter((el) => el.publica === "1")?.length === 0 ? (
							<View style={{ backgroundColor: "#f2f2f2", width: "100%", paddingVertical: 30, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
								<DefaultText style={{ fontSize: 14 }}>No hay Notas</DefaultText>
							</View>
						) : (
							notas
								.filter((el) => (filterFav ? el.usuario_favorito === "1" : true))
								.filter((el) => (searchType === 0 ? true : parseInt(page) === parseInt(el.pag)))
								.filter(
									(el) =>
										includesString(el.autor, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
										includesString(el.nota, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
										includesString(el.contenido, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
								)
								.filter((el) => el.publica === "1")
								.sort((a, b) => {
									if (sort === "0") {
										const p = moment(b.fecha_nota, "DD/MM/YYYY HH:mm").toDate() - moment(a.fecha_nota, "DD/MM/YYYY HH:mm").toDate();
										return p;
									} else if (sort === "1") {
										const p = moment(a.fecha_nota, "DD/MM/YYYY HH:mm").toDate() - moment(b.fecha_nota, "DD/MM/YYYY HH:mm").toDate();
										return p;
									} else if (sort === "2") {
										return parseInt(b.megusta) - parseInt(a.megusta);
									} else if (sort === "3") {
										return parseInt(a.megusta) - parseInt(b.megusta);
									} else if (sort === "4") {
										return a.nota.localeCompare(b.nota);
									} else if (sort === "5") {
										return b.nota.localeCompare(a.nota);
									} else if (sort === "6") {
										return a.autor.localeCompare(b.autor);
									} else if (sort === "7") {
										return b.autor.localeCompare(a.autor);
									} else if (sort === "8") {
										return parseInt(a.pag) - parseInt(b.pag);
									} else if (sort === "9") {
										return parseInt(b.pag) - parseInt(a.pag);
									}
								})
								.map((item, index) => (
									<Note
										key={index}
										data={data}
										item={item}
										index={index}
										jsessionid={jsession}
										pageRatio={pageRatio}
										refetchNotesCallback={refetchNotesCallback}
										goToPage={() => goToPage(parseInt(item.pag), itemLayout)}
										clickNoteCallback={() => {
											setNoteDetails(item);
											setNoteModal(true);
										}}
									/>
								))
						)}
					</ScrollView>
				) : tab === 5 ? (
					<ScrollView contentContainerStyle={{ padding: 10 }}>
						<View style={{ alignItems: "flex-end", marginBottom: 10 }}>
							<View style={{ flex: 1, width: "100%" }}>
								<View style={{ flexDirection: "row", alignItems: "center", width: "100%", marginBottom: 5 }}>
									<View style={{ flex: 1, paddingLeft: 15 }}>
										<SemiBoldText>Buscar en: </SemiBoldText>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											backgroundColor: "#f3f3f3",
											padding: 5,
											marginHorizontal: 10,
											marginVertical: 5,
											height: 35,
											borderRadius: 7,
											alignSelf: "center",
										}}
									>
										<TouchableOpacity
											onPress={() => setSearchType(0)}
											style={{
												alignItems: "center",
												justifyContent: "center",
												paddingHorizontal: 25,
												borderRadius: 4,
												backgroundColor: searchType === 0 ? "white" : "transparent",
												height: "100%",
											}}
										>
											<DefaultText style={{ fontSize: 12 }}>Todo</DefaultText>
										</TouchableOpacity>
										<TouchableOpacity
											onPress={() => setSearchType(1)}
											style={{
												alignItems: "center",
												justifyContent: "center",
												paddingHorizontal: 25,
												borderRadius: 4,
												backgroundColor: searchType === 1 ? "white" : "transparent",
												height: "100%",
											}}
										>
											<DefaultText style={{ fontSize: 12 }}>Página</DefaultText>
										</TouchableOpacity>
									</View>
								</View>

								<View style={{ flex: 1, marginRight: 10, width: "100%" }}>
									<SearchBar
										pressButton={() => {
											setFilterNotes("");
										}}
										withFilters={false}
										search={filterNotes}
										setSearch={setFilterNotes}
									/>
								</View>
							</View>
						</View>
						{data.videos
							.filter((el) => (searchType === 0 ? true : parseInt(page) >= parseInt(el.pagina_desde) && parseInt(page) <= parseInt(el.pagina_hasta)))
							.filter((el) => includesString(el?.desc, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))).length === 0 ? (
							<View style={{ backgroundColor: "#f2f2f2", width: "100%", paddingVertical: 30, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
								<DefaultText style={{ fontSize: 14 }}>No hay Videos</DefaultText>
							</View>
						) : (
							data?.videos
								.filter((el) => (searchType === 0 ? true : parseInt(page) >= parseInt(el.pagina_desde) && parseInt(page) <= parseInt(el.pagina_hasta)))
								.filter((el) => includesString(el?.desc, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
								.map((item, index) => (
									<TouchableOpacity
										onPress={() => {
											openVideoCallback(item);
										}}
										key={index}
										style={[styles.shadow, { flexDirection: "row", marginBottom: 10, paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "white", borderRadius: 5 }]}
									>
										<ImageBackground
											source={{ uri: item.miniatura }}
											imageStyle={{ borderRadius: 7 }}
											style={{ height: 80, borderRadius: 7, alignItems: "center", justifyContent: "center", backgroundColor: "gainsboro", aspectRatio: 1 }}
										>
											{!item.miniatura && <Ionicons name="videocam" size={30} />}
											<TouchableOpacity
												onPress={() => {
													pageCallback(item.pagina_desde);
												}}
												style={{ position: "absolute", paddingHorizontal: 10, paddingVertical: 5, zIndex: 5, top: 5, left: 5, backgroundColor: "rgba(0,0,0,.7)", borderRadius: 5 }}
											>
												<DefaultText style={{ color: "white", fontSize: 12 }}>
													{item.pagina_desde} - {item.pagina_hasta}
												</DefaultText>
											</TouchableOpacity>
										</ImageBackground>
										<View style={{ flex: 1, paddingLeft: 10 }}>
											<View style={{ flex: 1, justifyContent: "center" }}>
												<SemiBoldText numberOfLines={3} style={{ fontSize: 16 }}>
													{item.desc?.slice(0, -4)}
												</SemiBoldText>
											</View>
											{/* <RenderHTML source={{ html: item?.contenido || "<p></p>" }} contentWidth={w.width - 40} /> */}
										</View>
									</TouchableOpacity>
								))
						)}
					</ScrollView>
				) : tab === 6 ? (
					<ScrollView contentContainerStyle={{ padding: 10 }}>
						<View style={{ alignItems: "flex-end", marginBottom: 10 }}>
							<View style={{ flex: 1, width: "100%" }}>
								<View style={{ flex: 1, marginRight: 10, width: "100%" }}>
									<SearchBar
										pressButton={() => {
											setFilterNotes("");
										}}
										withFilters={false}
										search={filterNotes}
										setSearch={setFilterNotes}
									/>
								</View>
							</View>
						</View>

						{data?.contenidodes?.filter((el) => includesString(el?.desc, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))).length === 0 ? (
							<View style={{ backgroundColor: "#f2f2f2", width: "100%", paddingVertical: 30, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
								<DefaultText style={{ fontSize: 14 }}>No hay elementos descargables</DefaultText>
							</View>
						) : (
							data?.contenidodes
								.filter((el) => includesString(el?.desc, filterNotes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
								.map((item, index) => (
									<TouchableOpacity
										onPress={() => downloadFile(item)}
										key={index}
										style={[styles.shadow, { flexDirection: "row", marginBottom: 10, paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "white", borderRadius: 5 }]}
									>
										<View style={{ height: 50, borderRadius: 7, alignItems: "center", justifyContent: "center", backgroundColor: "gainsboro", aspectRatio: 1 }}>
											<Ionicons name="document" size={20} />
										</View>
										<View style={{ flex: 1, paddingLeft: 10 }}>
											<View style={{ flex: 1, justifyContent: "center" }}>
												<SemiBoldText numberOfLines={3} style={{ fontSize: 16 }}>
													{item?.desc}
												</SemiBoldText>
											</View>
										</View>
									</TouchableOpacity>
								))
						)}
					</ScrollView>
				) : null}
			</View>

			{/* <NoteModal
				visible={noteModal}
				id={id}
				page={page}
				item={noteDetails}
				jsessionid={jsession}
				refetchCallback={newNoteCallback}
				closeCallback={() => setNoteModal(false)}
				editable={noteDetails?.nota_propia === "1"}
			/> */}
		</>
	);
}

const includesString = (string, search) => {
	return string
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLocaleLowerCase()
		.includes(search.toLocaleLowerCase());
};

const PageMiniature = ({ tit, isbn, item, dir, showBookmark, showPrivateNotes, showPublicNotes, showVideo, pageCallback, width, pageRatio, removeElements, removeCallback }) => {
	return (
		<View style={{ padding: 5, height: width * pageRatio, width: width }}>
			<View style={[styles.shadow, { borderRadius: 10, flex: 1 }]}>
				<TouchableOpacity
					onPress={() => {
						pageCallback(parseInt(item));
					}}
					style={{
						flex: 1,
						width: "100%",
						aspectRatio: 1 / pageRatio,
						borderRadius: 5,
						overflow: "hidden",
					}}
				>
					{showBookmark && (
						<View style={{ position: "absolute", zIndex: 3, top: 0, right: 5, alignItems: "center" }}>
							<Ionicons name="bookmark" size={20} color={"red"} />
						</View>
					)}
					{showPrivateNotes && (
						<View style={{ position: "absolute", zIndex: 3, top: 30, left: 0, justifyContent: "center", alignItems: "center" }}>
							<FontAwesome name="tag" size={25} color={"#A4EC57"} style={{ transform: [{ rotate: "135deg" }] }} />
						</View>
					)}
					{showPublicNotes && (
						<View style={{ position: "absolute", zIndex: 3, top: 45, left: 0, justifyContent: "center", alignItems: "center" }}>
							<FontAwesome name="tag" size={25} color={"#5A97D4"} style={{ transform: [{ rotate: "135deg" }] }} />
						</View>
					)}
					{showVideo && (
						<View style={{ position: "absolute", zIndex: 3, bottom: 5, right: 10, justifyContent: "center", alignItems: "center" }}>
							<FontAwesome name="video-camera" size={20} color={"gray"} />
						</View>
					)}
					<View
						style={{
							position: "absolute",
							zIndex: 3,
							backgroundColor: "black",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: 5,
							paddingVertical: 5,
							paddingHorizontal: 10,
							top: 5,
							left: 5,
							opacity: 0.8,
						}}
					>
						<BoldText style={{ color: "white", fontSize: 10 }}>{item + 1}</BoldText>
					</View>
					<Image
						style={{ flex: 1, backgroundColor: "gainsboro" }}
						source={{
							uri: `https://www.ingebook.com/ib/pimg/IB_Libros_App/${dir}/thumb/${item + 1}.jpg`,
						}}
					/>
				</TouchableOpacity>
				{removeElements && (
					<View style={{ flexDirection: "row", borderTopWidth: 1, borderTopColor: "gainsboro", paddingHorizontal: 10, paddingVertical: 5, alignItems: "center" }}>
						<View style={{ flex: 1 }}>
							<DefaultText style={styles.description}>Pag: {item}</DefaultText>
						</View>
						<TouchableOpacity onPress={() => removeCallback(item)}>
							<FontAwesome name="trash" size={20} color={"red"} />
						</TouchableOpacity>
					</View>
				)}
			</View>
		</View>
	);
};

const Note = ({ item, index, jsessionid, clickNoteCallback, pageRatio, data, refetchNotesCallback, goToPage }) => {
	const [like, setLike] = useState(item.usuario_megusta === "1");
	const [fav, setFav] = useState(item.usuario_favorito === "1");
	const [publica, setPublic] = useState(item.publica === "1");
	const [reportModal, setReportModal] = useState(false);
	const [reportType, setReportType] = useState("1");
	const [reportText, setReportText] = useState("");

	const reportTypes = [
		{
			id: "1",
			name: "Contenido Sexual",
		},
		{
			id: "2",
			name: "Contenido Violento",
		},
		{
			id: "3",
			name: "Inapropiado",
		},
		{
			id: "4",
			name: "Spam",
		},
		{
			id: "5",
			name: "Infracción de Derechos",
		},
		{
			id: "6",
			name: "Otros",
		},
	];

	useEffect(() => {
		setPublic(item.publica === "1");
		setReportModal(false);
		setReportText("");
		setReportType("1");
	}, [item]);

	const deleteNote = (id) => {
		axios
			.post(`https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=6&cod_anotacion=${id}&codigo_libro=${data.id}&app=1`)
			.then((res) => {
				Alert.alert("La nota se ha eliminado correctamente", "", [{ text: "OK" }]);
				refetchNotesCallback();
			})
			.catch((err) => {
				console.log(err);
				Alert.alert("Ha ocurrido un error eliminando la nota", "", [{ text: "OK" }]);
			});
	};

	const makeNotePublic = (id) => {
		setPublic(!publica);
		axios
			.post(
				`https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=5&cod_anotacion=${id}&app=1&nota_publica=${item.publica === "0" ? "1" : "0"
				}&codigo_libro=${data.id}`
			)
			.then((res) => {
				if (res.data.status !== "0") {
					setPublic(!publica);
				}
				Alert.alert("La nota se ha editado correctamente", "", [{ text: "OK" }]);
				refetchNotesCallback();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const likeNote = (id, value) => {
		axios
			.post(
				`https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=3&cod_anotacion=${id}&me_gusta=${value}&codigo_libro=${data.id}&app=1`
			)
			.then((res) => {
				refetchNotesCallback();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const makeFavorite = (id, value) => {
		axios
			.post(
				`https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=7&cod_anotacion=${id}&favorito=${value}&codigo_libro=${data.id}&app=1`
			)
			.then((res) => {
				refetchNotesCallback();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const reportNote = () => {
		axios
			.post(
				`https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=4&cod_anotacion=${item.id
				}&cod_motivo=${reportType}&motivo=${encodeURI(reportText)}&codigo_libro=${data.id}&app=1`
			)
			.then((res) => {
				setReportModal(false);
				Alert.alert("La nota se ha reportado correctamente", "", [{ text: "Ok" }]);
			})
			.catch((err) => {
				console.log(err);
				Alert.alert("Ocurrió un error reportando la nota", "", [{ text: "Ok" }]);
			});
	};

	return (
		<>
			<TouchableOpacity
				onPress={() => {
					clickNoteCallback(item);
				}}
				key={index}
				style={[styles.shadow, { flexDirection: "row", marginBottom: 10, paddingHorizontal: 10, paddingVertical: 10, borderRadius: 5 }]}
			>
				<View style={{ height: 150, aspectRatio: 1 / pageRatio }}>
					<TouchableOpacity
						onPress={() => goToPage(parseInt(item.pag))}
						style={{ position: "absolute", paddingHorizontal: 10, paddingVertical: 5, zIndex: 5, top: 5, left: 5, backgroundColor: "rgba(0,0,0,.7)", borderRadius: 5 }}
					>
						<DefaultText style={{ color: "white", fontSize: 12 }}>{item.pag}</DefaultText>
					</TouchableOpacity>
					<Image
						style={{ flex: 1, backgroundColor: "gainsboro" }}
						source={{
							uri: `https://www.ingebook.com/ib/pimg/IB_Libros_App/${data.dir}/thumb/${parseInt(item.pag)}.jpg`,
						}}
					/>
				</View>
				<View style={{ flex: 1, paddingLeft: 10 }}>
					<View style={{ height: 35, alignItems: "center", flexDirection: "row" }}>
						<View style={{ flex: 1 }}>
							<DefaultText style={{ fontSize: 12 }}>{item.fecha_nota}</DefaultText>
						</View>
						<View style={{ flexDirection: "row" }}>
							<TouchableOpacity
								onPress={() => (item.nota_propia === "0" ? setReportModal(true) : clickNoteCallback(item))}
								style={{ width: 35, height: 35, alignItems: "center", justifyContent: "center" }}
							>
								<FontAwesome name={item.nota_propia === "0" ? "flag-o" : "pencil"} size={20} style={{ marginLeft: 10 }} />
							</TouchableOpacity>
						</View>
					</View>

					<View style={{ flex: 1, justifyContent: "center" }}>
						<SemiBoldText numberOfLines={3} style={{ fontSize: 16 }}>
							{item.nota}
						</SemiBoldText>
					</View>
					{/* {console.log(item?.usuario_favorito)} */}
					{item.nota_propia === "1" ? (
						<View style={{ height: 35, alignItems: "center", flexDirection: "row" }}>
							<View style={{ flex: 1 }}>
								<TouchableOpacity
									onPress={() => {
										Alert.alert(item?.publica === "1" ? "¿Está seguro de no querer compartir esta nota?" : "¿Está seguro de querer compartir esta nota?", "", [
											{
												text: "Cancelar",
												style: "cancel",
											},

											{
												text: "Continuar",
												style: "default",
												onPress: () => makeNotePublic(item.cod_anotacion),
											},
										]);
									}}
									style={{ flexDirection: "row", alignItems: "center" }}
								>
									<View
										style={{
											alignItems: "center",
											justifyContent: "center",
											width: 20,
											height: 20,
											marginRight: 5,
											borderRadius: 4,
											borderWidth: 2,
											borderColor: "gainsboro",
										}}
									>
										{publica && <FontAwesome name="check" size={14} color="red" />}
									</View>
									<DefaultText>Compartir Nota</DefaultText>
								</TouchableOpacity>
							</View>
							<View style={{ flexDirection: "row" }}>
								<TouchableOpacity
									onPress={() =>
										Alert.alert("Esta seguro que desa Eliminar esta nota", "", [
											{
												text: "Cancelar",
												style: "cancel",
											},

											{
												text: "Eliminar",
												style: "destructive",
												onPress: () => deleteNote(item.cod_anotacion),
											},
										])
									}
									style={{ width: 35, height: 35, alignItems: "center", justifyContent: "center" }}
								>
									<FontAwesome name="trash" color="red" size={20} style={{ marginLeft: 10 }} />
								</TouchableOpacity>
							</View>
						</View>
					) : (
						<View style={{ height: 35, alignItems: "center", flexDirection: "row" }}>
							<View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
								<Ionicons name="person-circle" size={25} style={{ marginRight: 5 }} />
								<View style={{ flex: 1 }}>
									<DefaultText numberOfLines={1} style={{ fontSize: 12 }}>
										{item.autor}
									</DefaultText>
								</View>
							</View>
							{/* {console.log(item)} */}
							<View style={{ flexDirection: "row" }}>
								<TouchableOpacity
									onPress={() => {
										setLike(!like);
										likeNote(item?.cod_anotacion, item?.usuario_megusta === "1" ? "0" : "1");
									}}
									style={{ flexDirection: "row", height: 35, alignItems: "center", justifyContent: "center", marginLeft: 10 }}
								>
									<DefaultText>{item.megusta}</DefaultText>
									<FontAwesome name={item?.usuario_megusta === "1" ? "thumbs-up" : "thumbs-o-up"} color="gray" size={20} style={{ marginLeft: 7 }} />
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										setFav(!fav);
										makeFavorite(item?.cod_anotacion, item?.usuario_favorito === "1" ? "0" : "1");
									}}
									style={{ flexDirection: "row", height: 35, alignItems: "center", justifyContent: "center", marginLeft: 10 }}
								>
									<DefaultText>{item.favoritos}</DefaultText>
									<FontAwesome name={item?.usuario_favorito === "1" ? "star" : "star-o"} color="orange" size={20} style={{ marginLeft: 5 }} />
								</TouchableOpacity>
							</View>
						</View>
					)}
				</View>
			</TouchableOpacity>
			<Portal>
				<Modal animationType="fade" transparent visible={reportModal}>
					<View
						style={{ position: "absolute", zIndex: 10, top: 0, left: 0, right: 0, bottom: 0, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,.8)" }}
					>
						<View style={{ padding: 10, maxWidth: 600, borderRadius: 5, backgroundColor: "white", width: "90%" }}>
							<View style={{ flexDirection: "row", alignItems: "center" }}>
								<View style={{ flex: 1 }}>
									<SemiBoldText>Reportar Nota</SemiBoldText>
								</View>
							</View>

							<View style={{ paddingVertical: 15 }}>
								<View style={{ marginBottom: 15 }}>
									{reportTypes.map((el, i) => {
										return (
											<TouchableOpacity
												style={{ backgroundColor: el.id === reportType ? "#f3f3f3" : "white", borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}
												key={i}
												onPress={() => setReportType(el.id)}
											>
												<DefaultText>{el.name}</DefaultText>
											</TouchableOpacity>
										);
									})}
								</View>
								<TextInput
									value={reportText}
									onChangeText={(ev) => setReportText(ev)}
									textAlignVertical="top"
									multiline
									style={{ height: 130, borderWidth: 1, paddingHorizontal: 10, paddingVertical: 10, borderRadius: 5, borderColor: "gainsboro" }}
									placeholder="Escribe Aquī..."
								/>
							</View>

							<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
								<TouchableOpacity onPress={() => setReportModal(false)} style={{ backgroundColor: "gainsboro", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 5 }}>
									<SemiBoldText>Cancelar</SemiBoldText>
								</TouchableOpacity>
								<TouchableOpacity onPress={() => reportNote()} style={{ backgroundColor: "red", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 5 }}>
									<SemiBoldText style={{ color: "white" }}>Enviar</SemiBoldText>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</Modal>
			</Portal>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: "white",
		flex: 1,
	},
	headerContainer: {
		width: "100%",
		height: 40,
		maxHeight: 40,
		minHeight: 40,
		borderBottomWidth: 2,
		borderBottomColor: "#e6e6e6",
	},
	toolButton: {
		height: 45,
		borderBottomWidth: 1,
		borderBottomColor: "gainsboro",
		flexDirection: "row",
		alignItems: "center",
	},
	optionsMenu: {
		position: "absolute",
		right: 5,
		zIndex: 200,
		width: 250,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	buttonsRow: {
		flexDirection: "row",
	},
	buttonsTextContainer: {
		paddingHorizontal: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonsText: {
		fontSize: 14,
	},
	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
	},
	menuButton: {
		width: 45,
		height: 35,
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: 5,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	separator: {
		marginVertical: 30,
		height: 1,
		width: "80%",
	},
	description: {
		marginVertical: 5,
		fontSize: 14,
		opacity: 0.6,
		borderBottomWidth: 0,
		borderBottomColor: "black",
		textDecorationColor: "transparent",
	},
});

const HighlightedResult = ({ text, search }) => {
	const position = text.toLowerCase().indexOf(search.toLowerCase());
	return (
		<RenderHTML
			source={{
				html:
					"<p>" +
					text
						.slice(position - 200 < 0 ? 0 : position - 200, position + search.length + 200)
						.toLocaleLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.replace(
							search
								.toLocaleLowerCase()
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, ""),
							`<span style="background-color: #ffc107;">${search.toLocaleLowerCase()}</span>`
						) +
					"</p>",
			}}
			defaultTextProps={{
				style: { fontFamily: "Montserrat" },
			}}
			baseStyle={{
				fontSize: 10,
			}}
		/>
	);
};
