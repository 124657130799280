import { Ionicons } from "@expo/vector-icons";
import { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Pressable, StyleSheet, TextInput, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import axios from "axios";
import Alert from "../components/Alert";
import { SemiBoldText } from "../components/StyledText";

import IframeRenderer, { iframeModel } from "@native-html/iframe-plugin";
import { Editor } from "@tinymce/tinymce-react";

import * as Animatable from "react-native-animatable";

const PressableAnimatable = Animatable.createAnimatableComponent(Pressable);

export default function NoteModal({ item, editable = false, jsessionid, visible, closeCallback, refetchCallback, page, id }) {
	const insets = useSafeAreaInsets();
	const editorRef = useRef();
	const w = useWindowDimensions();
	const [loading, setLoading] = useState(false);
	const [shareNote, setShareNote] = useState(item?.publica === "1" ? true : false);
	const [form, setForm] = useState({
		title: "",
		body: "",
		public: false,
	});

	const [descHTML, setDescHTML] = useState(item?.contenido || "");

	const renderers = {
		iframe: IframeRenderer,
	};

	const customHTMLElementModels = {
		iframe: iframeModel,
	};


	useEffect(() => {
		if (item) {
			setLoading(false);
			setForm({
				title: item.nota,
				body: item.contenido,
				public: item.publico,
			});
			setShareNote(item?.publica === "1" ? true : false);
			setDescHTML(item?.contenido);
		}
		if (!visible) {
			setForm({ title: "", body: "", public: false });
			setDescHTML("");
		}
	}, [item, visible]);

	const createNote = async () => {
		setLoading(true);
		if (form.title) {
			const title = escape(form.title.trim());
			const desc = escape(descHTML);

			const url = `https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=2&codigo_libro=${id}&app=1&pagina=${page}&nota=${title}&descripcion_nota=${desc}&nota_publica=${shareNote ? "1" : "0"
				}`;
			const o = await axios
				.post(url, {
					headers: {
						pragma: "no-cache",
						"cache-control": "no-cache",
					},
				})
				.then((res) => {
					return res.data
				});


			refetchCallback();
			Alert.alert("La nota se ha actualizado con éxito", "", [{ text: "Ok" }]);
			closeCallback();
		} else {
			Alert.alert("Debes ingresar un título para la nota", "", [{ text: "Ok" }]);
		}
		setLoading(false);
	};

	const editNote = async () => {
		setLoading(true);
		if (form.title) {
			const title = escape(form.title.trim());
			const desc = escape(descHTML);
			const url = `https://api.ingebook.com/ib/NPcd/IB_APP_Anotaciones;jsessionid=${jsessionid}?cod_primaria=1000193&accion=5&codigo_libro=${id}&cod_anotacion=${item.cod_anotacion
				}&app=1&pagina=${page}&nota=${title}&descripcion_nota=${desc}&nota_publica=${shareNote ? "1" : "0"}`;
			const o = await axios
				.post(url, {
					headers: {
						pragma: "no-cache",
						"cache-control": "no-cache",
					},
				})
				.then((res) => res.data);
			refetchCallback();
			Alert.alert("La nota se ha actualizado con éxito", "", [{ text: "Ok" }]);
			closeCallback();
		} else {
			Alert.alert("Debes ingresar un título para la nota", "", [{ text: "Ok" }]);
		}
		setLoading(false);
	};

	if (!visible) return null;
	return (
		<View style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, zIndex: 50 }}>
			<PressableAnimatable
				duration={200}
				animation={"fadeIn"}
				onPress={closeCallback}
				style={{ backgroundColor: "rgba(0,0,0,.5)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
			/>
			<View
				style={{
					backgroundColor: "#f6ee9a",
					flex: 1,
					marginVertical: 50,
					borderRadius: 15,
					// minHeight: w.height - 100,
					width: "100%",
					maxWidth: 800,
					// maxHeight: 600,
					alignSelf: "center",
				}}
			>
				<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
					<TouchableOpacity onPress={closeCallback} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
						<Ionicons name="close" size={30} color="#dec664" />
					</TouchableOpacity>
					{/* <TouchableOpacity onPress={() => (item.id ? editNote() : createNote())} style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
						<BoldText style={{ color: "brown", opacity: 0.55 }}>OK</BoldText>
					</TouchableOpacity> */}
				</View>
				<View style={{ zIndex: 50, flex: 1, paddingHorizontal: 15, position: "relative", paddingBottom: insets.bottom ? insets.bottom : 15 }}>
					{loading && (
						<View
							style={{ alignItems: "center", justifyContent: "center", zIndex: 4, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,.3)" }}
						>
							<ActivityIndicator color="white" />
						</View>
					)}
					<View style={{ flex: 1, borderRadius: 7, marginTop: 15, backgroundColor: "white", width: "100%", alignSelf: "center" }}>
						{editable ? (
							<>
								<View style={{ padding: 20 }}>
									<TextInput
										multiline
										placeholder="Nota..."
										value={form.title}
										onChangeText={(ev) => setForm({ ...form, title: ev })}
										style={{ fontSize: 22, marginBottom: 10, height: "auto", fontFamily: "Montserrat-SemiBold" }}
									/>
								</View>
								<View style={styles.richTextContainer}>
									<Editor
										ref={editorRef}
										value={descHTML}
										// onLoadContent={(ev) => ev.target.setContent(descHTML)}
										// init={{
										// 	height: "100%",
										// 	branding: false,
										// 	menubar: "format tools table tc help",
										// 	plugins: [
										// 		"advlist autolink lists link image charmap print preview anchor",
										// 		"searchreplace visualblocks code fullscreen",
										// 		"insertdatetime table paste code help wordcount",
										// 		"code",
										// 	],
										// 	toolbar:
										// 		"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
										// }}
										init={{
											resize: false,
											height: "100%",
											branding: false,
											menubar: false,
											toolbar1:
												"undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect | formatselect | forecolor backcolor | link unlink image media code | preview | table | hr removeformat | subscript superscript | charmap emoticons | fullscreen ",
											plugins: ["media", "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "visualblocks", "code", "fullscreen", "table", "code", "help", "code"],
											// toolbar:
											// 	"undo redo | blocks | " +
											// 	"bold italic forecolor | alignleft aligncenter " +
											// 	"alignright alignjustify | bullist numlist outdent indent | " +
											// 	"removeformat link code",
											content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
										}}
										onEditorChange={(ev) => setDescHTML(ev)}
										apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
									/>
								</View>
							</>
						) : (
							<View style={{ padding: 15 }}>
								<SemiBoldText style={{ fontSize: 22, marginBottom: 10 }}>{item.nota}</SemiBoldText>
								<div dangerouslySetInnerHTML={{ __html: item.contenido || "<p></p>" }} />
							</View>
						)}
					</View>
					<View style={{ marginTop: 15 }}>
						{editable && (
							<TouchableOpacity onPress={() => setShareNote(!shareNote)} style={{ alignItems: "center", flexDirection: "row" }}>
								<View
									style={{
										width: 20,
										height: 20,
										alignItems: "center",
										justifyContent: "center",
										borderWidth: 2,
										borderRadius: 3,
										borderColor: "gainsboro",
										backgroundColor: "white",
									}}
								>
									{shareNote && <Ionicons name="close" size={15} color="gray" />}
								</View>
								<SemiBoldText style={{ marginLeft: 5, color: "gray", opacity: 0.55 }}>Compartir Nota con otros usuarios</SemiBoldText>
							</TouchableOpacity>
						)}

						{editable && (
							<TouchableOpacity
								onPress={() => (item?.cod_anotacion ? editNote() : createNote())}
								style={{ marginTop: 15, backgroundColor: "#005ba9", alignItems: "center", justifyContent: "center", height: 40, borderRadius: 20 }}
							>
								<SemiBoldText style={{ color: "white" }}>{item?.cod_anotacion ? "Guardar" : "Crear Nota"}</SemiBoldText>
							</TouchableOpacity>
						)}
					</View>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "white",
		flex: 1,
	},
	headerContainer: {
		width: "100%",
		height: 40,
		maxHeight: 40,
		minHeight: 40,
		borderBottomWidth: 2,
		borderBottomColor: "#e6e6e6",
	},
	toolButton: {
		height: 45,
		borderBottomWidth: 1,
		borderBottomColor: "gainsboro",
		flexDirection: "row",
		alignItems: "center",
	},
	optionsMenu: {
		position: "absolute",
		right: 5,
		zIndex: 200,
		width: 250,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	buttonsRow: {
		flexDirection: "row",
	},

	buttonsTextContainer: {
		paddingHorizontal: 20,
		alignItems: "center",
		justifyContent: "center",
	},

	buttonsText: {
		fontSize: 14,
	},

	shadow: {
		shadowColor: "rgba(0, 0, 0, 0.3)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 3,
		elevation: 10,
		backgroundColor: "white",
	},

	menuButton: {
		width: 45,
		height: 35,
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: 5,
	},

	title: {
		fontSize: 20,
		fontWeight: "bold",
	},

	separator: {
		marginVertical: 30,
		height: 1,
		width: "80%",
	},

	description: {
		marginVertical: 5,
		fontSize: 14,
		opacity: 0.6,
		borderBottomWidth: 1,
		borderBottomColor: "black",
	},

	richTextContainer: {
		display: "flex",
		width: "100%",
		borderWidth: 0,
		minHeight: 350,
		flexGrow: 1,
		// marginBottom: 10,
	},

	richTextEditorStyle: {
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		paddingHorizontal: 10,
		borderWidth: 0,
		fontSize: 16,
	},

	richTextToolbarStyle: {
		backgroundColor: "white",
		borderWidth: 0,
	},

	errorTextStyle: {
		color: "#FF0000",
		marginBottom: 10,
	},
});
