import { Text, TextProps } from "./Themed";

export function MonoText(props: TextProps) {
	return <Text {...props} style={[props.style, { fontFamily: "Montserrat" }]} />;
}

export function DefaultText(props: TextProps) {
	return <Text {...props} style={[props.style, { fontFamily: "Montserrat" }]} />;
}

export function BoldText(props: TextProps) {
	return <Text {...props} style={[props.style, { fontFamily: "Montserrat-Bold" }]} />;
}

export function SemiBoldText(props: TextProps) {
	return <Text {...props} style={[props.style, { fontFamily: "Montserrat-SemiBold" }]} />;
}
